import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/sharedService/data.service';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TipHelperService } from 'src/app/sharedService/tiphelper.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  userData: any;
  userEmail: any;
  noResultReturned: boolean;
  categories: any = [];
  categoryId: any;
  tips: any = [];
  tipliked: boolean;
  loadMore: boolean;
  pageSize: any;
  searchinput: string;
  pageIndex: number;

  constructor(
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    private titleAndMetaService: TitleAndMetaService,
    private dataService: DataService,
    private tipHelperService: TipHelperService,
    private notificationService: NotificationService,
  ) {
    this.loadMore = true;
  }

  ngOnInit(): void {
    this.titleAndMetaService.setTitle('Categories Page');
    this.titleAndMetaService.setMetaTag();
    this.pageSize = localStorage.getItem('AppPageSize');
    console.log(this.pageSize);
    localStorage.removeItem('youtipcategorypageIndex');
    localStorage.setItem('youtipcategorypageIndex', '0');

    localStorage.removeItem('youtipcategorypagesearchIndex');
    localStorage.setItem('youtipcategorypagesearchIndex', '0');

    localStorage.removeItem('youtipcategorypageTipIndex');
    localStorage.setItem('youtipcategorypageTipIndex', '0');
    this.userData = JSON.parse(localStorage.getItem('YouITipLoggedInUser'));
    console.log(this.userData);

    if (this.userData != null) {
      this.userEmail = this.userData.LoggedInUser.UserEmail;
      console.log(this.userEmail);
    } else {
      this.userEmail = '';
    }
    this.getCategories('0');
  }

  search() {
    this.noResultReturned = false;
    console.log(this.searchinput);
    if (this.searchinput != '' && this.searchinput != undefined) {
      this.pageIndex = 0;
      localStorage.removeItem('youtipcategorypagesearchIndex');
      localStorage.setItem('youtipcategorypagesearchIndex', '0');
      this.tips = [];
      this.loadSearchTips(this.pageIndex, undefined);
    } else {
      this.tips = [];
      localStorage.removeItem('youtipcategorypageIndex');
      localStorage.setItem('youtipcategorypageIndex', '0');
      this.loadTips('0');
    }
  }

  async loadSearchTips(pageIndex, event) {
    console.log(this.categoryId);
    console.log(this.searchinput);
    console.log(this.userEmail);
    this.noResultReturned = true;
    this.tipService
      .searchCategoryTips(
        pageIndex,
        this.categoryId,
        this.searchinput,
        this.userEmail
      )
      .subscribe(async (data) => {
        this.noResultReturned = false;
        console.log(data.Tips);
        data.Tips.forEach((element) => {
          this.tips.push(element);
        });

        this.setLoadMore(data.Tips);
      });
  }

  categoryClicked(category) {
    if (category.isChecked) {
      this.tips = this.tips.filter(
        (t) => t.CategoryId != category.category.RowKey
      );
    } else {
      console.log(category.isChecked);
      this.categoryId = category.category.RowKey;
      this.loadTips('0');
    }
  }

  getCategories(pageIndex) {
    this.dataService.notifyHideFooter('');
    this.noResultReturned = true;
    this.tipService.getCategories(pageIndex).subscribe((res) => {
      console.log(res.Categories);
      this.dataService.notifyShowFooter('');
      res.Categories.forEach((element) => {
        this.categories.push({ isChecked: false, category: element });
      });

      console.log(this.categories[0]);
      this.categoryId = this.categories[0].category.RowKey;
      this.categories[0].isChecked = true;
      //this.getTips('0');
      this.loadTips('0');
      this.noResultReturned = false;
    });
  }

  getTips(pageIndex) {
    this.tipService.getTips(pageIndex, this.userEmail).subscribe((res) => {
      res.Tips.forEach((element) => {
        this.tips.push(element);
      });

      console.log(this.tips);
      console.log(this.tips);
      this.noResultReturned = false;
    });
  }

  tipByCategory(tip) {
    localStorage.setItem(
      'tipCategory',
      JSON.stringify({
        categoryName: tip.CategoryName,
        categoryId: tip.CategoryId,
      })
    );
    this.router.navigate(['/tipbycategory']);

    console.log(tip);
  }

  onScrollDown() {
    //this.spinner.show();
    console.log('scrolled');
    //this.noResultReturned = true;
    this.delay(1000).then(() => {
      console.log('ondemand');
      this.delayedServiceCall();
    });
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  delayedServiceCall() {
    var pageIndex = localStorage.getItem('youtipcategorypageIndex');
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem('youtipcategorypageIndex', pageIndex);
    console.log(pageIndex);
    this.getCategories(pageIndex);
  }

  loadNextPageTips() {
    var pageIndex = localStorage.getItem('youtipcategorypageTipIndex');
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem('youtipcategorypageTipIndex', pageIndex);
    console.log(pageIndex);
    this.loadTips(pageIndex);
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  setLoadMore(tips) {
    console.log(tips.length);
    console.log(this.pageSize);
    this.loadMore = !(tips.length < this.pageSize);
  }

  loadTips(pageIndex) {
    this.noResultReturned = true;
    this.tipService
      .getCategoryTips(pageIndex, this.categoryId, this.userEmail)
      .subscribe(async (data) => {
        console.log(data);
        this.noResultReturned = false;

        data.CategoryTips.forEach((element) => {
          this.tips.push(element);
        });

        this.setLoadMore(data.CategoryTips);
        console.log(this.tips);
      });
  }

  async likeTip(tip) {
    if (this.userData != null) {
      var rowKey = this.tipHelperService.getRowKey(tip);
      this.tipService
        .likeTip(rowKey, this.userData.LoggedInUser.UserEmail, tip.LikeCount)
        .subscribe(async (data) => {
          console.log(data.IsTipLiked);
          if (data.IsTipLiked) {
            this.notificationService.showAnimation(this.tips, tip);
            tip.LikeCount++;
            this.tipliked = true;
            tip.IsTipLiked = true;
            setTimeout(() => {
              this.tipliked = false;
            }, 2000);
          } else {
            tip.LikeCount--;
            tip.IsTipLiked = false;
          }
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  async saveTip(tip) {
    console.log(tip);
    if (this.userData != null) {
      if (this.userData != null) {
        var rowKey = this.tipHelperService.getRowKey(tip);
        this.tipService
          .saveTip(rowKey, this.userData.LoggedInUser.UserEmail)
          .subscribe(async (data) => {
            console.log(data);
            tip.IsTipSaved = data.IsTipSaved;
            if (data.IsTipSaved) {
              this.notificationService.showAnimation(this.tips, tip);
              tip.SaveCount++;
              this.tipliked = true;
              setTimeout(() => {
                this.tipliked = false;
              }, 2000);
            } else {
              tip.SaveCount--;
            }
          });
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  viewTip(tip) {
    localStorage.setItem('selectedTip', JSON.stringify(tip));
    this.router.navigate(['/viewtip']);
  }

  viewUserProfile(userEmail) {
    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        console.log('myprofile');
        this.router.navigate(['myprofile/editprofile']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        console.log('otheruserprofile');
        this.router.navigate(['otheruserprofile/otherusertips']);
      }
    }
  }

  viewMobileUserProfile(userEmail) {
    console.log('inviewmobileuser');
    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        this.router.navigate(['/mytips']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        this.router.navigate(['/mothertips']);
      }
    }
  }

  loadMoreTips() {
    this.delayedServiceCall();
  }
}
