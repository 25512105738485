import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';
import { FollowersComponent } from '../followers/followers.component';
import { FollowingComponent } from '../following/following.component';

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.scss'],
})
export class MyprofileComponent implements OnInit {
  userData: any;
  userDisplayName: string;
  userEmail: any;
  showImage: boolean;
  lastImage: any;
  myTipsCount: any;
  followerUserCount: any;
  followingUserCount: any;
  pageIndex: number;
  tips: any = [];
  noResultReturned: boolean;

  constructor(
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    private titleAndMetaService: TitleAndMetaService
  ) {}

  ngOnInit(): void {
    this.mpClicked = true;
    this.titleAndMetaService.setTitle('User Profile Page');
    this.titleAndMetaService.setMetaTag();
    this.userData = JSON.parse(localStorage.getItem('YouITipLoggedInUser'));
    console.log(this.userData);

    if (this.userData != null && this.userData != undefined) {
      this.userEmail = this.userData.LoggedInUser.UserEmail;
      console.log(this.userEmail);
      if (this.userData.LoggedInUser.ProfilePic != null) {
        this.lastImage = this.userData.LoggedInUser.ProfilePic;
        console.log(this.lastImage);
        this.showImage = true;
      } else {
        this.showImage = false;
      }

      this.tipService
        .getMyCounts(this.userData.LoggedInUser.UserEmail)
        .subscribe(async (data) => {
          this.myTipsCount = data.MyCounts.TipCount;
          this.followerUserCount = data.MyCounts.FollowerCount;
          this.followingUserCount = data.MyCounts.FollowingCount;

          //this.loadTips(this.pageIndex, undefined);
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  mpClicked: boolean;
  mtClicked: boolean;
  mrClicked: boolean;
  msClicked: boolean;
  cpClicked: boolean;
  flClicked: boolean;
  frClicked: boolean;
  ntClicked: boolean;
  foltClicked: boolean;
  mpnlClicked: boolean;

  setClass(bclick) {
    switch (bclick) {
      case 'mp':
        this.mpClicked = true;
        this.mtClicked = false;
        this.mrClicked = false;
        this.msClicked = false;
        this.cpClicked = false;
        this.flClicked = false;
        this.frClicked = false;
        this.foltClicked = false;
        this.ntClicked = false;
        this.mpnlClicked = false;
        break;
      case 'mt':
        this.mpClicked = false;
        this.mtClicked = true;
        this.mrClicked = false;
        this.msClicked = false;
        this.cpClicked = false;
        this.flClicked = false;
        this.frClicked = false;
        this.foltClicked = false;
        this.ntClicked = false;
        this.mpnlClicked = false;
        break;
      case 'mr':
        this.mpClicked = false;
        this.mtClicked = false;
        this.msClicked = false;
        this.mrClicked = true;
        this.cpClicked = false;
        this.flClicked = false;
        this.frClicked = false;
        this.foltClicked = false;
        this.ntClicked = false;
        this.mpnlClicked = false;
        break;
      case 'ms':
        this.mpClicked = false;
        this.mtClicked = false;
        this.mrClicked = false;
        this.msClicked = true;
        this.cpClicked = false;
        this.flClicked = false;
        this.frClicked = false;
        this.foltClicked = false;
        this.ntClicked = false;
        this.mpnlClicked = false;
        break;
      case 'cp':
        this.mpClicked = false;
        this.mtClicked = false;
        this.mrClicked = false;
        this.msClicked = false;
        this.cpClicked = true;
        this.flClicked = false;
        this.frClicked = false;
        this.foltClicked = false;
        this.ntClicked = false;
        this.mpnlClicked = false;
        break;
      case 'fl':
        this.mpClicked = false;
        this.mtClicked = false;
        this.mrClicked = false;
        this.msClicked = false;
        this.cpClicked = false;
        this.flClicked = true;
        this.frClicked = false;
        this.foltClicked = false;
        this.ntClicked = false;
        this.mpnlClicked = false;
        break;
      case 'fr':
        this.mpClicked = false;
        this.mtClicked = false;
        this.mrClicked = false;
        this.msClicked = false;
        this.cpClicked = false;
        this.flClicked = false;
        this.frClicked = true;
        this.foltClicked = false;
        this.ntClicked = false;
        this.mpnlClicked = false;
        break;
      case 'nt':
        this.mpClicked = false;
        this.mtClicked = false;
        this.mrClicked = false;
        this.msClicked = false;
        this.cpClicked = false;
        this.flClicked = false;
        this.frClicked = false;
        this.foltClicked = false;
        this.ntClicked = true;
        this.mpnlClicked = false;
        break;
      case 'folt':
        this.mpClicked = false;
        this.mtClicked = false;
        this.mrClicked = false;
        this.msClicked = false;
        this.cpClicked = false;
        this.flClicked = false;
        this.frClicked = false;
        this.ntClicked = false;
        this.foltClicked = true;
        this.mpnlClicked = false;
        break;
      case 'mpnl':
        this.mpClicked = false;
        this.mtClicked = false;
        this.mrClicked = false;
        this.msClicked = false;
        this.cpClicked = false;
        this.flClicked = false;
        this.frClicked = false;
        this.ntClicked = false;
        this.foltClicked = false;
        this.mpnlClicked = true;
        break;
    }
  }

  myPlan() {
    this.setClass('mpnl');
    this.router.navigate(['/myprofile/myplan']);
  }

  savedTips() {
    this.setClass('ms');
    this.router.navigate(['/myprofile/savedtips']);
  }

  changePassword() {
    this.setClass('cp');
    this.router.navigate(['/myprofile/changepassword']);
  }

  logout() {
    localStorage.removeItem('YouITipLoggedInUser');
    location.reload();
  }

  openDialog() {
    this.setClass('fl');
    // const dialogRef = this.dialog.open(FollowersComponent);
    this.router.navigate(['/myprofile/followers']);
  }

  OpenSecondDialog() {
    this.setClass('fr');
    // const dialogRef = this.dialog.open(FollowingComponent);
    this.router.navigate(['/myprofile/following']);
  }

  ntclicked() {
    this.setClass('nt');
    // const dialogRef = this.dialog.open(FollowingComponent);
    this.router.navigate(['/myprofile/notifications']);
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
