import { Component, HostListener, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/customValidators';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss'],
})
export class ChangepasswordComponent implements OnInit {
  public changePasswordForm: UntypedFormGroup;
  public activatePasswordForm: UntypedFormGroup;
  public password: string;
  email: string;
  activationCode: string;
  confirmPassword: string;
  navData: any = {};
  notFound: boolean;
  userData: any;
  activatePasswordEmail: string;
  isUserEmailExist: boolean;
  activateEmailFormEmail: any;
  isUserExist: boolean;
  isEmailTaken: boolean;
  disableButton: boolean;
  noResultReturned: boolean;
  pwdIcon = 'eye-outline';
  showPwd = false;

  constructor(
    private tipService: TipService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private titleAndMetaService: TitleAndMetaService
  ) {
    this.disableButton = false;
    this.isEmailTaken = true;
    this.isUserExist = false;
  }

  ngOnInit(): void {
    this.titleAndMetaService.setTitle('User Profile Page');
    this.titleAndMetaService.setMetaTag();
    this.userData = JSON.parse(localStorage.getItem('YouITipLoggedInUser'));
    console.log(this.userData);

    this.changePasswordForm = this.formBuilder.group({
      activationCode: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          // 2. check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          // 3. check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          // 4. check whether the entered password has a lower-case letter
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          CustomValidators.patternValidator(
            /[!,@,#,$,%,^,&,*,-,_,:,;,",',<,>,`,~,{,},/,?,|,(,)]/,
            { hasSpecialCharacters: true }
          ),
          Validators.minLength(8),
        ])
      ),
      confirmPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        this.equalto('password'),
      ]),
    });

    this.activatePasswordForm = this.formBuilder.group({
      activatePasswordEmail: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  equalto(field_name): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let input = control.value;
      let isValid = control.root.value[field_name] == input;
      if (!isValid) return { equalTo: { isValid } };
      else return null;
    };
  }

  changePassword() {
    this.noResultReturned = true;

    if (
      this.activationCode != null &&
      this.activationCode != undefined &&
      this.activationCode != '' &&
      this.password != null &&
      this.password != undefined &&
      this.password != '' &&
      this.confirmPassword != null &&
      this.confirmPassword != undefined &&
      this.confirmPassword != '' &&
      this.email != null &&
      this.email != undefined &&
      this.email != ''
    ) {
      let loginModel = {
        ActivationCode: this.activationCode,
        Password: this.password,
        ConfirmPassword: this.confirmPassword,
        UserEmail: this.email,
      };
      console.log(loginModel);
      this.tipService.resetPasswordAccount(loginModel).subscribe((data) => {
        this.noResultReturned = false;
        this.navData = data;
        this.router.navigate(['/login']);
        if (this.navData.MessageCode != 'NC') {
          this.notFound = false;
        } else {
          this.notFound = true;
        }
      });
    }
  }

  IsUserEmailTaken() {
    this.noResultReturned = true;
    console.log(this.activatePasswordEmail);
    this.isUserExist = false;
    this.tipService
      .getPasswordChange(this.activatePasswordEmail)
      .subscribe((data) => {
        this.noResultReturned = false;
        console.log(data);
        if (data.IsUserEmailTaken) {
          this.isEmailTaken = false;
          this.isUserExist = true;
          this.email = this.activatePasswordEmail;
        } else {
          this.isEmailTaken = true;
          this.disableButton = true;
        }
      });
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
