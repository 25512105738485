<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
    <h2>Membership Plans that helps you to grow parallelly</h2>
  </div> -->
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2>Let's see, Who Is Using YouITip?</h2>
  </div>
</section>
<section (contextmenu)="onRightClick($event)" class="newdesign-tip">
  <div class="scroll user-pro" class="card-handler" class="container p-0">
    <div
      *ngIf="userList?.length > 0"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScrollDown()"
      class="scroll user-pro"
      class="card-handler"
      class="container p-0"
    >
      <div class="row">
        <div class="card-handler">
          <div
            class="card-wrap"
            style="height: 200px"
            *ngFor="let user of userList"
          >
            <div class="text-wrap">
              <div class="text-inside-category">
                <div class="imagetext-wrap">
                  <div class="image-wrap"></div>
                  <!-- <h1
                    style="cursor: pointer"
                    (click)="viewUserProfile(user.customerEmail)"
                  >
                    {{ user.UserName }}
                  </h1> -->
                  <h6>{{ user.Name }}</h6>
                </div>
                <div class="new-purplebox">
                  <div class="darkbox">
                    <div class="title-icowrap">
                      <img
                        (click)="viewUserProfile(user.customerEmail)"
                        *ngIf="user.picUrl"
                        src="{{ user.picUrl }}"
                        class="rounded-circle me-3"
                        style="height: 30px; width: 30px; cursor: pointer"
                        alt="avatar"
                      />

                      <span
                        (click)="viewUserProfile(user.customerEmail)"
                        *ngIf="!user.picUrl"
                        class="material-icons"
                        style="
                          cursor: pointer;
                          font-size: 30px;
                          width: 30px;
                          color: grey;
                          height: 30px;
                        "
                      >
                        account_circle
                      </span>
                      <h6
                        class="tf blk"
                        style="cursor: pointer"
                        (click)="viewUserProfile(user.customerEmail)"
                      >
                        {{ user.UserName }}
                      </h6>
                    </div>
                    <div class="btn-row">
                      <div class="follower">
                        <span class="btn-text">{{ user.FollowerCount }}</span>
                        <span
                          style="
                            height: 17px;
                            margin-left: 10px;
                            cursor: pointer;
                          "
                          class="badge badge-primary"
                        >
                          Follower
                        </span>
                      </div>
                      <div class="following">
                        <span class="btn-text">{{ user.FollowingCount }}</span>
                        <span
                          style="
                            height: 17px;
                            margin-left: 10px;
                            cursor: pointer;
                          "
                          class="badge badge-primary"
                        >
                          Following
                        </span>
                      </div>
                      <div class="btn-tips">
                        <span class="btn-text">{{ user.TipCount }}</span>
                        <span
                          style="
                            height: 17px;
                            margin-left: 10px;
                            cursor: pointer;
                          "
                          class="badge badge-primary"
                        >
                          Tips
                        </span>
                      </div>
                    </div>
                    <div class="button-wrap">
                      <span
                        (click)="followUser(user)"
                        style="height: 17px; margin-left: 10px; cursor: pointer"
                        class="badge badge-primary"
                      >
                        {{ user.followButtonText }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="bottom-fix">
            <div class="button-wrap">
              <div class="cat-name">

              </div>
            </div>
          </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="noResultReturned" class="img-div-left">
    <img
      alt="loading spinner"
      onContextMenu="return false;"
      class="spinner-global img-fluid"
      style="width: 100px; height: 100px; display: block; margin: 0px auto"
      src="../../../../assets/img/Spinner.gif"
    />
  </div>
</section>
