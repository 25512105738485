import { Component, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/sharedService/data.service';
import { TipService } from 'src/app/sharedService/tip.service';

@Component({
  selector: 'app-mmyplan',
  templateUrl: './mmyplan.component.html',
  styleUrls: ['./mmyplan.component.scss'],
})
export class MmyplanComponent {
  myPlans: any = [];
  userData: any;
  PlanId: any;
  PlanAmount: any;
  PlanType: any;
  PurchaseStartDate: any;
  PurchaseEndDate: any;
  LoggedInUserPurchaseHistory: any;
  userEmail: any;

  notifierSubscription: Subscription =
    this.dataService.subjectNotifier.subscribe((notified) => {
      this.registerLoggedInUser();
    });

  constructor(
    private dataService: DataService,
    private tipService: TipService
  ) {
    var loggedInUser = localStorage.getItem('YouITipLoggedInUser');
    if (loggedInUser != null && loggedInUser != undefined) {
      this.userData = JSON.parse(loggedInUser);
      console.log(this.userData);
      this.userEmail = this.userData.LoggedInUser.UserEmail;
      console.log(this.userEmail);
       this.getMyPlans();
    }
  }

  registerLoggedInUser() {
    var loggedInUser = localStorage.getItem('YouITipLoggedInUser');
    if (loggedInUser != null && loggedInUser != undefined) {
      this.userData = JSON.parse(loggedInUser);
      console.log(this.userData);
      if (this.userData != null) {
        console.log(this.userData.LoggedInUserPurchaseHistory);
      }
    }
  }

  getMyPlans() {
    console.log(this.userEmail);
    this.tipService.getMyPlan(this.userEmail).subscribe((data) => {
      console.log(data);
      this.myPlans = data;
      this.PlanAmount = data.LoggedInUserPurchaseHistory[0].PlanAmount;
      this.PlanType = data.LoggedInUserPurchaseHistory[0].PlanType;
      this.PurchaseEndDate =
        data.LoggedInUserPurchaseHistory[0].PurchaseEndDate;
      console.log(this.myPlans);
    });
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
