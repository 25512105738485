<div (contextmenu)="onRightClick($event)" class="common-banner">
  <img src="../../../assets/img/aboutus-banner.png">
  <!-- <div class="common-text">
    <h2>Membership Plans that helps you to grow parallelly</h2>
  </div> -->
</div>
<section (contextmenu)="onRightClick($event)" class="common-overlap">
  <div class="common-text">
    <h2>Refund Policy</h2>
  </div>
</section>
<section>

<div (contextmenu)="onRightClick($event)" class="container-fluid">
  <div class="container">
    <div class="policy-content">
      <h3 class="blk">YouITip Refund Policy</h3>
      <h3 class="blk">Effective as of 07/07/2022</h3>

      <p>
        The YouITip subscription is non-refundable due to the immediate
        availability of downloads, resources and other benefits.<br />
        You can cancel your subscription at any time.
      </p>
    </div>
  </div>
</div>
</section>
