<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
    <h2>Membership Plans that helps you to grow parallelly</h2>
  </div> -->
</div>
<section (contextmenu)="onRightClick($event)" class="common-overlap">
  <div class="common-text">
    <h2>Tips on the Reels</h2>
  </div>
</section>
<section (contextmenu)="onRightClick($event)">
  <div *ngIf="tipDetails" class="container video-parent-scroll">
    <div class="video-wrap">
      <div class="video-div">
        <div class="video-controls">
          <div class="play-ico">
            <i
              *ngIf="isplaying"
              (click)="playVid()"
              class="fa-solid fa-play"
            ></i>
            <i
              *ngIf="!isplaying"
              (click)="pauseVideo()"
              class="fa-solid fa-pause"
            ></i>
          </div>
          <div (click)="muteUnmuteVideo()" class="sound-ico">
            <i *ngIf="!mute" class="fa-solid fa-volume-high"></i>
            <i *ngIf="mute" class="fa-solid fa-volume-mute"></i>
          </div>
        </div>
        <div (click)="videoDivClick()" class="video-play">
          <video
            id="myVideo"
            (ended)="vidEnded($event)"
            (play)="vidplay($event)"
            controlsList="nodownload"
            autoplay="true"
            poster="{{ tipDetails.VideoPosterUrl }}"
            [src]="tipDetails.Url"
            style="min-width: auto; min-height: 100%; object-fit: cover"
          >
            <source
              style="
                cursor: pointer;
                height: 150px !important;
                max-width: 100% !important;
                width: 100% !important;
              "
              *ngIf="tipDetails.Url"
              type="video/mp4"
            />
          </video>

          <div *ngIf="showplaybutton" class="play-mobile">
            <i (click)="videoClick()" class="fa-solid fa-play"></i>
          </div>
        </div>
        <div class="video-text">
          <div class="top-text">
            {{ tipDetails.Title }}
          </div>
          <div class="bottom-text">
            <div>
              <img
                (click)="goToUserProfile()"
                src="{{ tipDetails.ProfilePic }}"
                style="
                  height: 30px;
                  width: 30px;
                  border-radius: 50%;
                  margin-right: 10px;
                  cursor: pointer;
                  pointer-events: all;
                "
              />
            </div>
            <div class="u-name">
              <h4>{{ tipDetails.UserName }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="comments">
        <div class="threedot">
          <i class="fa-solid fa-ellipsis-vertical"></i>
        </div>
        <div class="icon-section">
          <ul>
            <li>
              <a
                ><span>{{ tipDetails.ViewCount }}</span
                ><i class="fa-solid fa-eye"></i
              ></a>
            </li>
            <li>
              <a (click)="likeTip(tipDetails)">
                <span>{{ tipDetails.LikeCount }}</span>
                <!-- <i class="fa-solid fa-thumbs-up"></i> -->
                <i class="fa fa-heart"></i>
              </a>
            </li>
            <li>
              <a (click)="saveTip(tipDetails)"
                ><i class="fa-solid fa-bookmark"></i
              ></a>
            </li>

            <li>
              <a href="#"
                ><i class="fa fa-commenting-o"></i> <span></span></a
              >
            </li>
          </ul>
          <div *ngIf="tipDetails.AnimateTip" class="anisecondheart reeltipanimation">
            <div class="bubbling-heart">
              <div><i class="fa fa-heart fa-5x"></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="arrow-wrap">
      <div style="cursor: pointer" (click)="slideUp()" class="top-arrow">
        <i class="fa fa-angle-up" area-hidden="true"></i>
      </div>
      <div style="cursor: pointer" (click)="slideDown()" class="bottom-arrow">
        <i class="fa fa-angle-down" area-hidden="true"></i>
      </div>
    </div>
  </div>
  <div class="load-more">
    <h5 class="Usermsg" *ngIf="noMoreRecordsAvailable">Thats it for now :)</h5>
  </div>
</section>
