<div class="col-12 p-0 addarw clearfix" (contextmenu)="onRightClick($event)">
  <button
    (click)="close()"
    type="button"
    style="cursor: pointer; float: left; margin-left: 20px; font-size: 30px"
    class="close"
    aria-label="Close"
    mat-dialog-close
  >
    <span aria-hidden="true">×</span>
  </button>

  <div
    style="
      height: 400px;
      display: flex;
      flex-flow: column wrap;
      justify-content: space-around;
    "
  >
    <div *ngIf="tipDetails" class="fixed-header">
      <h3 class="card-title blk" style="padding-right: 40px">
        {{ tipDetails.Title | titlecase }}
      </h3>
    </div>
    <div class="comment-wrap" style="height: 180px; overflow: auto">
      <mat-card class="example-card" *ngFor="let comment of comments">
        <mat-card-content>
          <div
            *ngIf="comment.ProfilePic"
            (click)="viewUserProfile(comment.UserEmail)"
            style="
              cursor: pointer;
              font-size: 14px !important;
              text-align: start;
            "
            class="with-icon"
          >
            <img
              onContextMenu="return false;"
              mat-card-avatar
              src="{{ comment.ProfilePic }}"
              alt="User Avatar"
            />
            <p style="font-size: 12px !important; text-align: start">
              {{ comment.UserName }}
            </p>
          </div>

          <div
            *ngIf="!comment.ProfilePic"
            style="cursor: pointer"
            class="with-icon"
          >
            <div
              mat-dialog-close
              (click)="viewUserProfile(comment.UserEmail)"
              class="user-details"
            >
              <span class="material-icons"> account_circle </span>
              <p style="font-size: 12px !important; text-align: start">
                {{ comment.UserName }}
              </p>
            </div>
            <!-- <div class="elipse">
              <i class="fa fa-ellipsis-v" area-hidden="true"></i>
            </div> -->
          </div>

          <p class="description">
            {{ comment.Description }}
          </p>
          <div class="date-wrap">
            <p class="time">
              {{ comment.Timestamp | dateAgo }}
            </p>
            <button
              mat-dialog-close
              (click)="edit(comment)"
              mat-button
              color="primary"
            >
              Edit
            </button>
            <button
              mat-dialog-close
              (click)="delete(comment)"
              mat-button
              color="primary"
            >
              Delete
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="botom-wrap">
      <p
        style="
          color: #b34bc9;
          font-size: 14px !important;
          padding: 7px 20px 0px;
          margin: 0px;
          font-weight: 500;
        "
      >
        Remember to keep comments respectful and to follow our Community
        Guidlines.
      </p>
      <textarea
        (input)="descChanged()"
        class="form-control"
        [(ngModel)]="description"
        name="description"
        class="fadeIn second"
        placeholder="Enter your comment"
      >
      </textarea>
      <mat-hint class="font16 textcount" align="end"
        >{{ description.length }} / 500</mat-hint
      >
      <button
        (click)="addComment()"
        style="cursor: pointer; border: none; background-color: white"
        class="material-icons"
      >
        send
      </button>
    </div>
  </div>
  <div *ngIf="noResultReturned">
    <img
      alt="loading spinner"
      onContextMenu="return false;"
      class="spinner-global img-fluid"
      style="width: 50px; height: 50px; display: block; margin: 0px auto"
      src="../../../../assets/img/Spinner.gif"
    />
  </div>
</div>
