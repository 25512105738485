<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png">
  <!-- <div class="common-text">
    <h2>Membership Plans that helps you to grow parallelly</h2>
  </div> -->
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2>Edit Profile</h2>
  </div>
</section>
<section>
<div class="Login">
    <div align="center">
      <div id="formContent">
        <div class="fadeIn first">
          <!-- <P style="color: black; font-size: 20px; font-weight: bold">Sign-In</P> -->
          <!-- <h2 class="blk">Edit Profile</h2> -->
        </div>
        <form [formGroup]="registerForm" novalidate>
          <div class="form-container">
            <div>
              <img
                onContextMenu="return false;"
                class="rounded-circle"
                *ngIf="profilePicture"
                [src]="profilePicture"
                style="border-radius: 50%; height: 100px; width: 100px"
              />
            </div>
            <div>
              <label class="hidetext">sample</label>
              <input
                #imageInput
                type="file"
                accept="image/*"
                (change)="selectFiles($event)"
                #fileInput
              />
            </div>
            <div>
              <label>Name</label>
              <input
                placeholder="Name"
                class="form-control"
                type="text"
                [(ngModel)]="name"
                formControlName="name"
                class="fadeIn second"
                required
              />
            </div>
            <div
              class="alert alert-danger"
              *ngIf="
                registerForm.get('name').hasError('required') &&
                registerForm.get('name').touched
              "
            >
              Name is required
            </div>
            <div>
              <label>Username</label>
              <input
                class="form-control"
                required
                class="fadeIn second"
                placeholder="UserName"
                (focusout)="IsUserNameTaken()"
                type="text"
                [(ngModel)]="userName"
                formControlName="userName"
              />
            </div>
            <div
              class="alert alert-danger"
              *ngIf="
                registerForm.get('userName').hasError('required') &&
                registerForm.get('userName').touched
              "
            >
              UserName is required
            </div>
            <div class="error" *ngIf="isUserNameTaken">
              This user name is taken. Please try another.
            </div>
            <div>
              <label>Email</label>
              <input
                class="form-control"
                required
                class="fadeIn second"
                placeholder="Email"
                type="email"
                [(ngModel)]="email"
                formControlName="email"
              />
            </div>
            <div
              class="alert alert-danger"
              *ngIf="
                registerForm.get('email').hasError('required') &&
                registerForm.get('email').touched
              "
            >
              Email is required
            </div>
            <div
              class="alert alert-danger"
              *ngIf="
                registerForm.get('email').hasError('email') &&
                registerForm.get('email').touched
              "
            >
              Please enter valid email
            </div>
            <div class="alert alert-danger" *ngIf="userEmailTaken">
              That email address is taken. Please try another.
            </div>
            <!-- <div class="input-wrapper">
          <ion-datetime
            max="2001-12-09"
            [(ngModel)]="dob"
            formControlName="dob"
            displayFormat="DD-MMM-YYYY"
          ></ion-datetime>
        </div> -->

            <div>
              <label>Profession</label>
              <input
                class="form-control"
                required
                class="fadeIn second"
                placeholder="Profession"
                type="text"
                [(ngModel)]="profession"
                formControlName="profession"
              />
            </div>
            <div>
              <label>Bio</label>
              <input
                class="form-control"
                required
                class="fadeIn second"
                placeholder="Bio"
                type="text"
                [(ngModel)]="bio"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <div>
              <label>Phone</label>
              <input
                class="form-control"
                required
                class="fadeIn second"
               
                type="text"
                maxlength="10"
                [(ngModel)]="phone"
                formControlName="phone"
              />
            </div>
            <div
              *ngIf="
                registerForm.controls['phone'].invalid &&
                (registerForm.controls['phone'].dirty ||
                  registerForm.controls['phone'].touched)
              "
              class="alert alert-danger"
            >
              Phone number is required.
            </div>
            <div
              *ngIf="
                !isPhoneValid &&
                (registerForm.controls['phone'].dirty ||
                  registerForm.controls['phone'].touched)
              "
              class="alert alert-danger"
            >
              Please enter valid phone number.
            </div>
          </div>
          <button
            style="width: 120px; font-size: 16px"
            type="submit"
            (click)="register()"
            class="btn btn-primary btn-lg mb-3 fadeIn fourth"
          >
            Done
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
