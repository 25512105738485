import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DataService } from 'src/app/sharedService/data.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  searchinput: string;
  userData: any;
  userEmail: any;
  totalTips: any;
  totalCategories: any;
  private searchTerms = new Subject<string>();
  constructor(
    private titleAndMetaService: TitleAndMetaService,
    private dataService: DataService,
    private tipService: TipService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('YouITipLoggedInUser'));
    this.titleAndMetaService.setTitle('Search Page');
    this.titleAndMetaService.setMetaTag();
    this.getMetadataRecords();
    this.searchTerms
      .pipe(debounceTime(300)) // Adjust the debounce time as needed
      .subscribe(() => {
        // Call your search function here
        this.performSearch();
      });
  }

  onSearchChange() {
    //this.dataService.notifyAboutSearch(this.searchinput);
    this.searchTerms.next(this.searchinput);
  }

  performSearch() {
    // Implement your search logic here, for example, making an HTTP request
    // and updating the search results in the component.
    this.dataService.notifyAboutSearch(this.searchinput);
  }

  async getMetadataRecords() {
    this.userEmail = '';
    if (this.userData != null) {
      this.userEmail = this.userData.UserEmail;
    }

    this.tipService
      .getMetadataRecords(this.userEmail)
      .subscribe(async (data) => {
        console.log(data);
        this.totalTips = data.TipsMetadata.filter(
          (m) => m.Key == 'TotalTips'
        )[0].Value;
        this.totalCategories = data.TipsMetadata.filter(
          (m) => m.Key == 'TotalCategories'
        )[0].Value;
        console.log(data.TipsMetadata);
      });
  }

  watchoneminvid() {
    this.router.navigate(['/oneminvids']);
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
  download() {}
}
