import { Component, HostListener, OnInit } from '@angular/core';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-previewprofile',
  templateUrl: './previewprofile.component.html',
  styleUrls: ['./previewprofile.component.scss']
})
export class PreviewprofileComponent implements OnInit {

  constructor(
    private titleAndMetaService: TitleAndMetaService
    ) {}

    ngOnInit(): void {
      this.titleAndMetaService.setTitle('Preview Profile Page');
      this.titleAndMetaService.setMetaTag();
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

}
