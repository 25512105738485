import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OneminvideosComponent } from '../oneminvideos/oneminvideos.component';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  title = 'owlcarouselinAngular';
  Images = [
    'assets/img/1.png',
    'assets/img/2.jpg',
    'assets/img/3.jpg',
    'assets/img/4.jpg',
    'assets/img/5.jpg',
    'assets/img/6.jpg',
    'assets/img/7.jpg',
    'assets/img/8.jpg',
    'assets/img/9.jpg',
    'assets/img/10.jpg',
  ];

  SlideOptions = {
    items: 1,
    dots: false,
    autoplay: true,
    autoplayTimeout: 4500,
    loop: true,
    autoplayHoverPause: true,
    autoHeight: true,
  };
  CarouselOptions = { items: 3, dots: true };

  constructor(public router: Router) {}

  ngOnInit(): void {}

  watchoneminvid() {
    this.router.navigate(['/oneminvids']);
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
