import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-activateaccount',
  templateUrl: './activateaccount.component.html',
  styleUrls: ['./activateaccount.component.scss'],
})
export class ActivateaccountComponent implements OnInit {
  public activateForm: UntypedFormGroup;
  public getCodeForm: UntypedFormGroup;
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public navData: any = {};
  public customer: any = {};
  activationCode: any;
  email: string;
  getCodeEmailAddress: string;
  noResultReturned: boolean;
  isUserExist: boolean;
  isEmailTaken: boolean;
  isEmailEntered: boolean;
  isactivationCodeEntered: boolean;
  disableButton: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private tipService: TipService,
    private router: Router,
    private titleAndMetaService: TitleAndMetaService,
    public snackBar: MatSnackBar,
    public zone: NgZone
  ) {}

  ngOnInit(): void {
    this.titleAndMetaService.setTitle('Activate Account Page');
    this.titleAndMetaService.setMetaTag();
    this.email = localStorage.getItem('activateAccountEmail');
    console.log(this.email);
    if (!this.email.includes('@')) {
      console.log(this.email);
      this.router.navigate(['/register']);
    }

    this.activateForm = this.formBuilder.group({
      activationCode: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });

    this.getCodeForm = this.formBuilder.group({
      getCodeEmailAddress: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  sendActivationCode() {
    console.log('IsUserEmailTaken');
    if (
      this.getCodeEmailAddress != '' &&
      this.getCodeEmailAddress != undefined
    ) {
      this.noResultReturned = true;
      console.log(this.getCodeEmailAddress);
      this.isUserExist = false;
      this.tipService
        .sendActivationCodeEmail(this.getCodeEmailAddress)
        .subscribe((data) => {
          this.noResultReturned = false;
          console.log(data);
          if (data.IsUserEmailTaken) {
            this.isEmailTaken = false;
            this.isUserExist = true;
            this.email = this.getCodeEmailAddress;
          } else {
            this.isEmailTaken = true;
            this.disableButton = true;
          }
        });
    }
  }

  async activateAccount() {
    console.log('activateAccount');
    if (
      this.email == null ||
      this.activationCode == null ||
      this.email === '' ||
      this.activationCode === ''
    ) {
      this.zone.run(() =>
        this.snackBar.open("Please enter activation code", 'X', { panelClass: ['error'] })
      );
    } else {
      this.noResultReturned = true;
      let loginModel = {
        ActivationCode: this.activationCode,
        UserEmail: this.email,
      };
      console.log(loginModel);
      this.tipService.activateAccount(loginModel).subscribe(async (data) => {
        this.noResultReturned = false;
        if (data.IsAccountActivated) {
          this.router.navigate(['/login']);
        } else {
        }
      });
    }
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  getCode() {}
}
