<div class="Login">
  <div align="center">
    <div id="formContent">
      <div class="fadeIn first">
        <!-- <P style="color: black; font-size: 20px; font-weight: bold">Sign-In</P> -->
        <h3>Report tip as an In Appropriate</h3>
      </div>
      <form [formGroup]="loginForm" novalidate>
        <div>
          <div  class="custom-control custom-radio" style="padding: 10px;" style="padding: 10px;">
            <input
              type="radio"
              value="sexual"
              class="custom-control-input"
              mdbInput
            />
            <label class="custom-control-label" for="defaultUnchecked"
              >Sexual Content</label
            >
          </div>
          <mat-divider></mat-divider>
          <div  class="custom-control custom-radio" style="padding: 10px;">
            <input
              type="radio"
              class="custom-control-input"
              value="graphic"
              mdbInput
            />
            <label class="custom-control-label" for="defaultUnchecked"
              >Graphic violence</label
            >
          </div>
          <mat-divider></mat-divider>
          <div  class="custom-control custom-radio" style="padding: 10px;">
            <input
              type="radio"
              class="custom-control-input"
              value="hateful"
              mdbInput
            />
            <label class="custom-control-label" for="defaultUnchecked"
              >Hateful or abusive content</label
            >
          </div>
          <mat-divider></mat-divider>
          <div  class="custom-control custom-radio" style="padding: 10px;">
            <input
              type="radio"
              class="custom-control-input"
              value="rating"
              mdbInput
            />
            <label class="custom-control-label" for="defaultUnchecked"
              >Improper content rating</label
            >
          </div>
          <mat-divider></mat-divider>
          <div  class="custom-control custom-radio" style="padding: 10px;">
            <input
              type="radio"
              class="custom-control-input"
              value="illegal"
              mdbInput
            />
            <label class="custom-control-label" for="defaultUnchecked"
              >Illegal prescription or other drug</label
            >
          </div>
          <mat-divider></mat-divider>
          <div  class="custom-control custom-radio" style="padding: 10px;">
            <input
              type="radio"
              class="custom-control-input"
              value="copycat"
              mdbInput
            />
            <label class="custom-control-label" for="defaultUnchecked"
              >Copycat or impersonation</label
            >
          </div>
          <mat-divider></mat-divider>
          <div  class="custom-control custom-radio" style="padding: 10px;">
            <input
              type="radio"
              class="custom-control-input"
              value="other"
              mdbInput
            />
            <label class="custom-control-label" for="defaultUnchecked"
              >Other objection</label
            >
          </div>
        </div>

        <!-- Default checked -->

        <button
          style="width: 120px; font-size: 16px"
          type="submit"
          class="btn btn-primary btn-lg mb-3 fadeIn fourth"
        >
          Submit
        </button>

        <!-- <div id="formFooter">
          <button
            style="width: 120px; font-size: 16px"
            routerLink="/registration"
            type="button"
            class="btn btn-primary btn-lg"
          >
            Register</button
          ><br />
          <button
            style="margin-top: 5px; /* width: 152px; */ font-size: 16px"
            routerLink="/forget-password"
            type="button"
            class="btn btn-primary btn-lg"
          >
            Forgot Password ?
          </button>
        </div> -->
      </form>
    </div>
  </div>
</div>
