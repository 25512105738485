<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
    <h2>Membership Plans that helps you to grow parallelly</h2>
  </div> -->
</div>
<section class="common-overlap">
  <div class="common-text">
    <h2>Create Tip</h2>
  </div>
</section>
<section>
<div class="Login contactpage">
  <div align="center">
    <div id="formContent">
      <div class="fadeIn first">
        <!-- <P style="color: black; font-size: 20px; font-weight: bold">Sign-In</P> -->
        <!-- <h2>Create tip</h2> -->
      </div>

      <form [formGroup]="registerForm" novalidate>
        <div *ngIf="lastImage" class="selected-image">
          <img
            onContextMenu="return false;"
            class="rounded-circle"
            *ngIf="lastImage"
            [src]="lastImage"
            style="border-radius: 50%; height: 100px; width: 100px"
          />
        </div>
        <div class="selected-image" *ngIf="videoUrl">
          <video
            poster="{{ videoPosterUrl }}"
            controlsList="nodownload"
            id="myVideo"
            style="
              height: 100% !important;
              /* padding-bottom: 12%; */
              max-height: 100% !important;
              max-width: 100% !important;
              width: 100% !important;
              text-align: center;
              cursor: pointer;
            "
            [src]="videoUrl"
            class="video-display"
            controls
            muted
          >
            <source
              style="
                cursor: pointer;
                height: 150px !important;
                max-width: 100% !important;
                width: 100% !important;
              "
              *ngIf="videoUrl"
              type="video/mp4"
            />
          </video>
        </div>
        <div class="progress form-group" *ngIf="progress > 0">
          <div
            class="progress-bar progress-bar-striped bg-success"
            role="progressbar"
            [style.width.%]="progress"
          ></div>
        </div>
        <div class="file-wrap">
          <label for="selectimage">
            <i
              style="font-size: 15px"
              class="fa fa-video"
              area-hidden="true"
            ></i>
            <i
              style="margin-left: 10%; font-size: 15px"
              class="fa fa-picture-o"
              area-hidden="true"
            ></i
            ><br />
            Choose a media(Image/Video)
          </label>
          <br />
          <input
            class="custom-file-input"
            #imageInput
            name="selectimage"
            type="file"
            accept="video/*,image/*"
            (change)="selectFiles($event)"
            #fileInput
          />
        </div>
        <input
          multiple
          value="title"
          [(ngModel)]="title"
          formControlName="title"
          name="title"
          #message
          (input)="titleChanged()"
          maxlength="250"
          matInput
          class="form-control"
          placeholder="Enter Tip Title"
        />
        <mat-hint class="font16" align="end">{{ title.length }} / 250</mat-hint>
        <div
          *ngIf="
            registerForm.controls['title'].invalid &&
            (registerForm.controls['title'].dirty ||
              registerForm.controls['title'].touched)
          "
          class="alert alert-danger"
        >
          Title is required.
        </div>
        <textarea
          (input)="descChanged()"
          [(ngModel)]="description"
          formControlName="description"
          name="description"
          #message
          maxlength="500"
          matInput
          rows="5"
          class="form-control"
          style="width: 100%; background-color: white"
          placeholder="Enter Tip Description"
        >
        </textarea>
        <mat-hint class="font16" align="end"
          >{{ description.length }} / 500</mat-hint
        >
        <input
          multiple
          value="category"
          [(ngModel)]="category"
          formControlName="category"
          name="category"
          #message
          maxlength="80"
          (input)="categoryChanged()"
          matInput
          class="form-control"
          [matAutocomplete]="auto"
          placeholder="Enter / Select Tip Category"
        />
        <mat-hint class="font16" align="end"
          >{{ category.length }} / 80</mat-hint
        >
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let category of categories" [value]="category">
            {{ category }}
          </mat-option>
        </mat-autocomplete>
        <div
          *ngIf="
            registerForm.controls['category'].invalid &&
            (registerForm.controls['category'].dirty ||
              registerForm.controls['category'].touched)
          "
          class="alert alert-danger"
        >
          Category is required.
        </div>
        <mat-form-field style="width: 100%" appearance="fill">
          <mat-label style="margin-left: 30%; font-weight: bold"
            >Who can see your Tip?</mat-label
          >
          <mat-select
            matInput
            aria-label="Published For"
            name="publishedFor"
            formControlName="publishedFor"
            [(ngModel)]="publishedFor"
            tabindex="0"
          >
            <mat-option value="Public">Anyone</mat-option>
            <mat-option value="OnlyFollowers">Only Followers</mat-option>
          </mat-select>
        </mat-form-field>
      </form>

      <div style="padding-bottom: 20px;margin-top:20px;" class="col-12">
        <button
          [disabled]="disableButton"
          (click)="addTip()"
          class="btn btn-primary"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
</section>

<div *ngIf="noResultReturned" class="img-div-left">
  <img
    alt="loading spinner"
    onContextMenu="return false;"
    class="spinner-global img-fluid"
    style="width: 100px; height: 100px; display: block; margin: 0px auto"
    src="../../../../assets/img/Spinner.gif"
  />
</div>
