<div class="common-banner mobileonly" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
      <h2>Membership Plans that helps you to grow parallelly</h2>
    </div> -->
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2>About Us</h2>
  </div>
</section>
<section (contextmenu)="onRightClick($event)">
  <div class="container">
    <div class="common-text text-center">
      <h2 class="blk">Welcome to YOUITIP know more About us</h2>
      <p
        class="blk"
        style="font-size: 20px !important; font-weight: bold !important"
      >
        "Things in your mind, YouITip will find”
      </p>
    </div>
  </div>
  <div class="twocol-tile about-col">
    <div class="twocol-tilewrap">
      <div class="row">
        <div class="col-sm-6">
          <div class="tile">
            <div class="image">
              <img src="../../../assets/img/about-1.jpg" />
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="tile">
            <div class="coltile-text">
              <!-- <h6 class="blk">Who we are</h6> -->
              <!-- <h4 class="blk">About Us for YOUiTIP</h4> -->
              <ul class="bullet">
                <li>
                  <span><i class="fa fa-check" aria-hidden="true"></i></span>We
                  are providing the platform where people get quick answer to
                  their queries using fingertip on YOUiTIP
                </li>
                <li>
                  <span><i class="fa fa-check" aria-hidden="true"></i></span>We
                  are providing quick and useful tips in structured way that
                  will fulfil user needs
                </li>
                <li>
                  <span><i class="fa fa-check" aria-hidden="true"></i></span
                  >With YOUiTIP you can create useful Tips in structured way for
                  users with ease of use those can be easily followed to get the
                  answer
                </li>
              </ul>

              <h4 class="blk">Quality Content</h4>
              <p>
                To attract and keep users happy, you need to create Tips of high
                quality in structured way, that’s what you can do with YOUiTIP.
              </p>
              <p>
                Adding useful and quality Tips is a great way to gain higher
                Return on Investment and increase your userbase. People will
                view and use Tips when they have all the required details and
                guidance to get answer to their queries.
              </p>
              <p>
                With YOUiTIP, you have reference of already created Tips as
                guidance that you can use to create useful Tips. other search
                engines are hassles that comes with multiple matching results
                having more jargons and likewise matches but not exact one which
                could be lengthy and sometimes confusing to understand.
              </p>
              <p>
                With YOUiTIP, everything is available in the app which can be
                used anytime, anywhere, and on any device that users want to
                know or search. you can update or recreate the Tip at any time
                without any changes to your Tip reference, therefore nobody will
                ever lose Tip which is available.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="tile">
            <div class="coltile-text">
              <p>
                With YOUiTIP, everything is available in the app which can be
                used anytime, anywhere, and on any device that users want to
                know or search. you can update or recreate the Tip at any time
                without any changes to your Tip reference, therefore nobody will
                ever lose Tip which is available.
              </p>
              <h2 class="extra-bold blk">
                We are good people please
                <a class="hyperlink" style="cursor: pointer" (click)="routeToPage('contactus')"
                  >Click Here</a
                >
                to connect with us
              </h2>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="tile">
            <div class="image">
              <img src="../../../assets/img/about-2.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="cat-parent about-category">
  <div class="cat-wrap">
    <h2>How it Works</h2>
    <p>Easy steps to know how YouITip works.</p>
    <div class="cat-tile-wrap">
      <div class="row" style="width: 100%">
        <div class="col-sm-4">
          <img src="../../../assets/img/2.jpg" />
          <h6>STEP 1</h6>
          <h5>Register and create your profile</h5>
          <p>
            Please
            <a
              class="hyperlink"
              style="cursor: pointer"
              (click)="routeToPage('register')"
              >register</a
            >
            on youITip and setup your profile
          </p>
        </div>
        <div class="col-sm-4">
          <img src="../../../assets/img/2.jpg" />
          <h6>STEP 2</h6>
          <h5>Now Create/Post Tips</h5>
          <p>
            <a
              class="hyperlink"
              style="cursor: pointer"
              (click)="routeToPage('login')"
              >Login</a
            >
            with your profile and click
            <a
              class="hyperlink"
              style="cursor: pointer"
              (click)="routeToPage('createtip')"
            >
              Create/Post Tips</a
            >
            to create tips
          </p>
        </div>
        <div class="col-sm-4">
          <img src="../../../assets/img/2.jpg" />
          <h6>STEP 3</h6>
          <h5>Share your tips and create followers</h5>
          <p>
            Download YouITip app from playstore and share the tips
            <a
              class="hyperlink"
              href="https://play.google.com/store/apps/details?id=io.ionic.youitip"
              >YouITip</a
            >
          </p>
        </div>
      </div>
    </div>
    <!-- <div class="btn">
      <button>View All Categories</button>
    </div> -->
  </div>
</div>
<div class="twocol-tip">
  <div class="twocol-tipwrap">
    <div class="row">
      <div class="col-sm-5">
        <!-- <img
          src="https://swash2021.azureedge.net/products/16368034265778bdadc6d-418d-4d9e-8b80-f71bb50a23b4.jpg"
        /> -->
        <img src="../../../assets/img/latest-news.jpg" />
      </div>
      <div class="col-sm-7 flex-d">
        <div class="col-text">
          <h2 class="blk">Why Choose YouITIP</h2>
          <p>
            As the end user, you get the benefit of having a central point where
            you can find all easy Tips from diverse Tip creators in one place,
            for everyday use. The end user can engage with the Tip creators and
            other users within the app, save Tip for future use and share with
            friends and families. We continuously work to have better and
            enhanced user experience.
          </p>
          <h3 class="blk">How do you earn money?</h3>
          <ul class="tick">
            <li>
              <span><i class="fa fa-check" aria-hidden="true"></i></span>You
              create a Public or Connections only tips
            </li>
            <li>
              <span><i class="fa fa-check" aria-hidden="true"></i></span>YouITip
              platform will list your tips on the home page
            </li>
            <li>
              <span><i class="fa fa-check" aria-hidden="true"></i></span> End
              users based on the content of your tip can like, share, save the
              tips
            </li>
            <li>
              <span><i class="fa fa-check" aria-hidden="true"></i></span>To see
              your connections only tips endusers are required to purchase the
              YouITip platform subscription by visiting &nbsp;<a
                class="hyperlink"
                style="cursor: pointer"
                (click)="routeToPage('pricing')"
                >Pricing Plans</a
              >&nbsp;on YouITip
            </li>
            <li>
              <span><i class="fa fa-check" aria-hidden="true"></i></span>You
              earn more money with more followers
            </li>
          </ul>
          <!-- <div class="btn">
            <button>View All Categories</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
