<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2>Tip Details</h2>
  </div>
</section>
<section class="plan-and-pricing" (contextmenu)="onRightClick($event)">
  <div *ngIf="tipDetails" class="common-wrap">
    <div class="row">
      <div class="col-sm-12 col-md-8 view-left">
        <h5 class="blk">
          {{ title }}
        </h5>
        <div class="row">
          <div class="col-sm-6 date-wrap">
            <div class="date">
              <p>
                <i class="fa fa-calendar-alt"></i>{{ tipCreateDate | date }}
              </p>

              <p><i class="fa fa-clock"></i>3 mins Read</p>
            </div>
          </div>
          <div class="col-sm-6 link-wrap">
            <div class="s-link">
              <ul class="share-social">
                <li><i class="fa fa-share"></i></li>
                <li>
                  <a (click)="likeTip()"
                    ><i
                      [ngClass]="{ likeColorClass: tipDetails.IsTipLiked }"
                      style="cursor: pointer"
                      class="fas fa-heart text-muted p-md-1 my-1 me-2"
                      data-mdb-toggle="tooltip"
                      data-mdb-placement="top"
                      title="Like the tip"
                    ></i
                  ></a>
                </li>
                <!-- <li>
                  <a href="#"><i class="fas fa-eye" area-hidden="true"></i></a>
                </li> -->
                <li>
                  <a (click)="saveTip()"
                    ><i
                      [ngClass]="{ savedColorClass: tipDetails.IsTipSaved }"
                      style="cursor: pointer"
                      class="fas fa-bookmark text-muted p-md-1 my-1 me-0"
                      data-mdb-toggle="tooltip"
                      data-mdb-placement="top"
                      title="Save tip to your profile"
                    ></i
                  ></a>
                </li>
                <li>
                  <a
                    ><i
                      style="cursor: pointer"
                      (click)="tipComment()"
                      class="fa fa-commenting-o"
                    ></i
                  ></a>
                </li>
              </ul>
              <div
                *ngIf="tipDetails.AnimateTip"
                class="anisecondheart viewtipanimation"
              >
                <div class="bubbling-heart">
                  <div><i class="fa fa-heart fa-5x"></i></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <img
          *ngIf="tipDetails.ShowImage && tipDetails.Url"
          src="{{ tipDetails.Url }}"
          alt=""
        />
        <video
          poster="{{ tipDetails.VideoPosterUrl }}"
          controlsList="nodownload"
          *ngIf="tipDetails.Url && !tipDetails.ShowImage"
          [src]="tipDetails.Url"
          class="video-display"
          controls
          autoplay="true"
          muted
        >
          <source
            style="
              cursor: pointer;
              height: 150px !important;
              max-width: 100% !important;
              width: 100% !important;
            "
            *ngIf="tipDetails.Url && !tipDetails.ShowImage"
            type="video/mp4"
          />
        </video>
        <p>
          {{ tipDetails.Description }}
        </p>
        <div class="arrow-wrap">
          <div (click)="previousTip()" class="top-arrow">
            <i class="fa fa-angle-up" area-hidden="true"></i>
          </div>
          <div (click)="nextTip()" class="bottom-arrow">
            <i class="fa fa-angle-down" area-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-4 view-right">
        <div class="row">
          <div class="author-box">
            <div class="row">
              <div class="col-sm-12">
                <h3 class="blk">About Author</h3>
                <div class="row">
                  <div class="col-3 col-sm-3">
                    <div class="pro-pic">
                      <img
                        style="cursor: pointer"
                        (click)="viewUserProfile(tipDetails.UserEmail)"
                        *ngIf="profilePic"
                        src="{{ profilePic }}"
                      />
                      <!-- <i
                        *ngIf="!profilePic"
                        class="fa fa-user"
                        aria-hidden="true"
                      ></i> -->
                      <span
                        style="cursor: pointer"
                        (click)="viewUserProfile(tipDetails.UserEmail)"
                        *ngIf="!profilePic"
                      >
                        {{ uInitials }}
                      </span>
                    </div>
                  </div>
                  <div class="col-9 col-sm-9">
                    <h6 class="blk">{{ userName }}</h6>
                    <p>Writer and novelist for social caring and media</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h3 class="tf blk">Related Categories</h3>
            <ul class="rel-topics">
              <li
                style="cursor: pointer; background-color: #b34bc9; color: white"
              >
                {{ categoryName }}
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h3 class="tf blk">Posts by Similar Author</h3>
            <div *ngIf="tips?.length > 1" class="posts">
              <h5 class="blk">
                {{ tips[1].Title }}
              </h5>
              <div class="date">
                <p>
                  <i class="fa fa-calendar-alt"></i>
                  {{ tips[1].Timestamp | date }}
                </p>

                <p><i class="fa fa-clock"></i>3 mins Read</p>
                <div class="postbtn-wrap">
                  <a
                    style="cursor: pointer"
                    (click)="tipByCategory()"
                    class="post-catbtn"
                    >{{ tips[1].CategoryName }}</a
                  >
                </div>
              </div>
              <p>
                {{ tips[1].Description }}
              </p>
              <!-- <a href="#" class="readbtn">Read more</a> -->
            </div>
            <div *ngIf="tips?.length > 2" class="posts">
              <h5 class="blk">
                {{ tips[2].Title }}
              </h5>
              <div class="date">
                <p>
                  <i class="fa fa-calendar-alt"></i>
                  {{ tips[2].Timestamp | date }}
                </p>

                <p><i class="fa fa-clock"></i>3 mins Read</p>
                <div class="postbtn-wrap">
                  <a
                    style="cursor: pointer"
                    (click)="tipByCategory()"
                    class="post-catbtn"
                    >{{ tips[2].CategoryName }}</a
                  >
                </div>
              </div>
              <p>
                {{ tips[2].Description }}
              </p>
              <!-- <a href="#" class="readbtn">Read more</a> -->
            </div>
            <a (click)="tipByCategory()" style="cursor: pointer" class="readall"
              >View All</a
            >
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="noResultReturned" class="img-div-left">
      <img
        alt="loading spinner"
        onContextMenu="return false;"
        class="spinner-global img-fluid"
        style="width: 100px; height: 100px; display: block; margin: 0px auto"
        src="../../../../assets/img/Spinner.gif"
      />
    </div>
  </div>
</section>
