<div class="common-banner">
  <img src="../../../assets/img/aboutus-banner.png" />
</div>
<section class="common-overlap">
  <div class="common-text">
    <h2>Following</h2>
  </div>
</section>
<section>
  <div class="container-fluid" (contextmenu)="onRightClick($event)">
    <div class="main-body">
      <div class="row gutters-sm left-stickypanel">
        <div
          *ngIf="followersList?.length > 0"
          class="scroll user-pro"
          class="card-handler"
          class="right-panel"
        >
          <div class="row">
            <div class="card-handler">
              <div
                class="card-wrap"
                style="height: 200px"
                *ngFor="let user of followersList"
              >
                <div class="text-wrap">
                  <div class="text-inside-category">
                    <div class="imagetext-wrap">
                      <div class="image-wrap">
                        <img
                          (click)="viewUserProfile(user.PartitionKey)"
                          *ngIf="user.picUrl"
                          src="{{ user.picUrl }}"
                          class="rounded-circle me-3"
                          style="height: 30px; width: 30px"
                          alt="avatar"
                        />
                        <span
                          (click)="viewUserProfile(user.PartitionKey)"
                          *ngIf="!user.picUrl"
                          class="material-icons"
                          style="
                            cursor: pointer;
                            font-size: 30px;
                            width: 30px;
                            color: grey;
                            height: 30px;
                          "
                        >
                          account_circle
                        </span>
                      </div>
                    </div>

                    <h1
                      style="margin-left: 10px; margin-top: 5px"
                      class="card-title font-weight-bold mb-2"
                    >
                      {{ user.UserName | titlecase }}
                    </h1>
                    <div class="button-wrap">
                      <span
                        (click)="followUser(user)"
                        style="height: 17px; margin-top: 6px; cursor: pointer"
                        class="badge badge-primary"
                        >{{ user.followButtonText }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="load-more">
            <button *ngIf="loadMore" (click)="loadNextPageTips()">
              Load more
            </button>
            <h5 class="Usermsg" *ngIf="!loadMore">Thats it for now :)</h5>
          </div>
        </div>
        <div *ngIf="noResultReturned" class="img-div-left">
          <img
            alt="loading spinner"
            onContextMenu="return false;"
            class="spinner-global img-fluid"
            style="
              width: 100px;
              height: 100px;
              display: block;
              margin: 0px auto;
            "
            src="../../../../assets/img/Spinner.gif"
          />
        </div>
      </div>
    </div>
  </div>
</section>
