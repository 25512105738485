import { CreatetipComponent } from './../../../featureModule/createtip/createtip.component';
import { WhoisusingyoutipComponent } from './../../../featureModule/whoisusingyoutip/whoisusingyoutip.component';
import { ReelsComponent } from './../../../featureModule/reels/reels.component';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { youitipwebAppHeader } from 'src/app/models/YouITipAppHeader';
import { HeaderApiService } from './services/header-api.service';
import { DataService } from 'src/app/sharedService/data.service';
import { Subscription } from 'rxjs';
import { TipService } from 'src/app/sharedService/tip.service';
import { ViewtipComponent } from 'src/app/featureModule/viewtip/viewtip.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  youitipwebAppHeaderModel: youitipwebAppHeader[];
  valueToBeSanitized: string;
  userEmail: any;
  name: any;
  userData: any;
  userDisplayName: any;
  turnOnNightMode: boolean;
  isAdmin: boolean;
  profilePic: any;
  pageIndex: number;
  notificationList: any = [];

  notifierSubscription: Subscription =
    this.dataService.subjectNotifier.subscribe((notified) => {
      this.registerLoggedInUser();
    });
  cClicked: boolean;
  abClicked: boolean;
  wiClicked: boolean;
  reClicked: boolean;
  prClicked: boolean;
  upClicked: boolean;

  constructor(
    private appHeaderApiService: HeaderApiService,
    private router: Router,
    public dialog: MatDialog,
    private dataService: DataService,
    private tipService: TipService
  ) {}

  registerLoggedInUser() {
    var loggedInUser = localStorage.getItem('YouITipLoggedInUser');
    if (loggedInUser != null && loggedInUser != undefined) {
      this.userData = JSON.parse(loggedInUser);
      console.log(this.userData);
      if (this.userData != null) {
        this.name = this.userData.LoggedInUser.Name;
        this.profilePic = this.userData.LoggedInUser.ProfilePic;
      }
    }
  }

  ngOnDestroy(): void {
    this.notifierSubscription.unsubscribe();
  }

  ngOnInit() {
    this.registerLoggedInUser();

    if (this.userData != null) {
      this.name = this.userData.LoggedInUser.Name;
      this.profilePic = this.userData.LoggedInUser.ProfilePic;
    }
    //this.getyouitipwebAppHeader();
    this.loadNotifications();
  }

  fnturnOnNightMode() {}

  goToUserProfile() {
    this.setClass('up');
    this.router.navigate(['/myprofile']);
  }

  getyouitipwebAppHeader(): void {}

  logout() {
    localStorage.removeItem('YouITipLoggedInUser');
    location.reload();
  }

  openDialog() {
    const dialogRef = this.dialog.open(ReelsComponent);
  }

  setClass(bclick) {
    switch (bclick) {
      case 'ca':
        this.cClicked = true;
        this.abClicked = false;
        this.wiClicked = false;
        this.upClicked = false;
        this.prClicked = false;
        this.reClicked = false;
        break;
      case 'ab':
        this.cClicked = false;
        this.abClicked = true;
        this.wiClicked = false;
        this.upClicked = false;
        this.prClicked = false;
        this.reClicked = false;
        break;
      case 'wi':
        this.cClicked = false;
        this.abClicked = false;
        this.wiClicked = true;
        this.upClicked = false;
        this.prClicked = false;
        this.reClicked = false;
        break;
      case 're':
        this.cClicked = false;
        this.abClicked = false;
        this.wiClicked = false;
        this.upClicked = false;
        this.prClicked = false;
        this.reClicked = true;
        break;
      case 'pr':
        this.cClicked = false;
        this.abClicked = false;
        this.wiClicked = false;
        this.upClicked = false;
        this.prClicked = true;
        this.reClicked = false;
        break;
      case 'up':
        this.cClicked = false;
        this.abClicked = false;
        this.wiClicked = false;
        this.upClicked = true;
        this.prClicked = false;
        this.reClicked = false;
        break;
    }
  }

  reset() {
    this.cClicked = false;
    this.abClicked = false;
    this.wiClicked = false;
    this.upClicked = false;
    this.prClicked = false;
    this.reClicked = false;
    this.wiClicked = false;
    this.abClicked = false;
  }

  category() {
    this.setClass('ca');
    this.router.navigate(['/categories']);
  }

  aboutus() {
    this.setClass('ab');
    this.router.navigate(['/aboutus']);
  }

  reels() {
    this.setClass('re');
    this.router.navigate(['/reels']);
  }

  pricing() {
    this.setClass('pr');
    this.router.navigate(['/pricing']);
  }

  openTipDialog() {
    this.setClass('wi');
    this.router.navigate(['/whoisusingyouitip']);
  }

  savedTips() {
    this.router.navigate(['/msavedtips']);
  }

  changePassword() {
    this.router.navigate(['/mchangepassword']);
  }

  openCreateDialog() {
    this.reset();
    if (this.userData != null && this.userData != undefined) {
      this.router.navigate(['/createtip']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  loadNotifications() {
    if (this.userData != null) {
      this.tipService
        .getUserNotifications(this.userData.LoggedInUser.UserEmail)
        .subscribe(async (data) => {
          data.Notifications.forEach((element) => {
            this.notificationList.push(element);
          });
        });
    }
  }

  openViewTipDialog(tipId) {
    console.log(tipId);
    localStorage.removeItem('selectedTip');
    localStorage.setItem('notificationTipId', tipId);
    const dialogRef = this.dialog.open(ViewtipComponent);
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
