import { Component, HostListener, OnInit } from '@angular/core';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-tandc',
  templateUrl: './tandc.component.html',
  styleUrls: ['./tandc.component.scss']
})
export class TandcComponent implements OnInit {

  constructor(
    private titleAndMetaService: TitleAndMetaService
    ) {}

    ngOnInit(): void {
      this.titleAndMetaService.setTitle('Terms & Conditions Page');
      this.titleAndMetaService.setMetaTag();
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

}
