import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataService } from 'src/app/sharedService/data.service';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { ViewtipComponent } from '../viewtip/viewtip.component';
import { CommentComponent } from '../comment/comment.component';
import { TipHelperService } from 'src/app/sharedService/tiphelper.service';

@Component({
  selector: 'app-otherusertips',
  templateUrl: './otherusertips.component.html',
  styleUrls: ['./otherusertips.component.scss'],
})
export class OtherusertipsComponent implements OnInit {
  noResultReturned: any;
  tips: any = [];
  userData: any;
  userEmail: any;
  showImage: boolean;
  lastImage: any;
  myTipsCount: any;
  followerUserCount: any;
  followingUserCount: any;
  pageIndex: number;

  otherUserEmail: string;
  name: any;
  email: any;
  dialogRef: any;
  tipliked: boolean;
  pageSize: any;
  loadMore: boolean;
  constructor(
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    private titleAndMetaService: TitleAndMetaService,
    private dataService: DataService,
    private _cdf: ChangeDetectorRef,
    private notificationService: NotificationService,
    private tipHelperService: TipHelperService
  ) {
    this.loadMore = true;
  }

  setLoadMore(tips) {
    console.log(tips.length);
    console.log(this.pageSize);
    this.loadMore = !(tips.length < this.pageSize);
  }

  ngOnInit(): void {
    localStorage.removeItem('otherusertipsyoutiphomepageIndex');
    localStorage.setItem('otherusertipsyoutiphomepageIndex', '0');
    this.otherUserEmail = localStorage.getItem('otherUserEmail');
    this.pageSize = localStorage.getItem('AppPageSize');
    
    this.userData = JSON.parse(localStorage.getItem('YouITipLoggedInUser'));
    this.userEmail = '';
    console.log(this.userData);
    if (this.userData != null) {
      this.userEmail = this.userData.LoggedInUser.UserEmail;
      console.log(this.userEmail);
      if (this.userData.LoggedInUser.ProfilePic != null) {
        this.lastImage = this.userData.LoggedInUser.ProfilePic;
        console.log(this.lastImage);
        this.showImage = true;
      } else {
        this.showImage = false;
      }
    }

    this.pageIndex = 0;
    this.loadTips(this.pageIndex);
  }

  tipComment(tip) {
    if (this.userData != null || this.userData != undefined) {
      localStorage.setItem('tipComment', JSON.stringify(tip));
      this.dialogRef = this.dialog.open(CommentComponent);
    } else {
      this.router.navigate(['/login']);
    }
  }

  async loadTips(pageIndex) {
    console.log(this.otherUserEmail);
    this.noResultReturned = true;
    this.tipService
      .getOtherUserTips(pageIndex, this.otherUserEmail, this.userEmail)
      .subscribe(async (data) => {
        this.noResultReturned = false;
        console.log(data);
        data.UserTips.forEach((element) => {
          this.tips.push(element);
        });

        this.setLoadMore(data.UserTips);
      });
  }

  delayedServiceCall() {
    var pageIndex = localStorage.getItem('otherusertipsyoutiphomepageIndex');
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem('otherusertipsyoutiphomepageIndex', pageIndex);
    console.log(pageIndex);
    this.loadTips(pageIndex);
  }

  notifierViewCountSubscription: Subscription =
    this.dataService.subjectViewCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('found tip');
        tip.ViewCount = Input.count;
        this._cdf.detectChanges();
      }
    });

  notifierCommentCountSubscription: Subscription =
    this.dataService.subjectCommentCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('found tip');
        tip.CommentCount = Input.count;
        this._cdf.detectChanges();
      }
    });

  notifierLikeCountSubscription: Subscription =
    this.dataService.subjectLikeCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('like found tip');
        console.log(tip.IsTipLiked);
        tip.IsTipLiked = Input.IsTipLiked;
        if (tip.IsTipLiked) {
          tip.LikeCount++;
        } else {
          tip.LikeCount--;
        }
        this._cdf.detectChanges();
      }
    });

  notifierSaveCountSubscription: Subscription =
    this.dataService.subjectSaveCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('found tip');
        tip.IsTipSaved = Input.IsTipSaved;
        this._cdf.detectChanges();
      }
    });

  async likeTip(tip) {
    if (this.userData != null) {
      var rowKey = this.tipHelperService.getRowKey(tip);
      this.tipService
        .likeTip(rowKey, this.userData.LoggedInUser.UserEmail, tip.LikeCount)
        .subscribe(async (data) => {
          console.log(data.IsTipLiked);
          if (data.IsTipLiked) {
            this.notificationService.showAnimation(this.tips, tip);
            tip.LikeCount++;
            this.tipliked = true;
            tip.IsTipLiked = true;
            setTimeout(() => {
              this.tipliked = false;
            }, 2000);
          } else {
            tip.LikeCount--;
            tip.IsTipLiked = false;
          }
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  viewUserProfile(userEmail) {
    if (this.userData == null) {
      console.log(this.userData);
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        this.router.navigate(['/userprofile']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        this.router.navigate(['/otheruserprofile']);
      }
    }
  }

  tipByCategory(tip) {
    localStorage.setItem(
      'tipCategory',
      JSON.stringify({
        categoryName: tip.CategoryName,
        categoryId: tip.CategoryId,
      })
    );
    this.router.navigate(['/tipbycategory']);
  }

  async saveTip(tip) {
    console.log(tip);
    if (this.userData != null) {
      if (this.userData != null) {
        var rowKey = this.tipHelperService.getRowKey(tip);
        this.tipService
          .saveTip(rowKey, this.userData.LoggedInUser.UserEmail)
          .subscribe(async (data) => {
            console.log(data);
            tip.IsTipSaved = data.IsTipSaved;
            if (data.IsTipSaved) {
              this.notificationService.showAnimation(this.tips, tip);
              tip.SaveCount++;
              this.tipliked = true;
              setTimeout(() => {
                this.tipliked = false;
              }, 2000);
            } else {
              tip.SaveCount--;
            }
          });
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  loadMoreTips() {
    this.delayedServiceCall();
  }

  viewTip(tip) {
    //localStorage.setItem('viewtip', JSON.stringify(this.tipDetails));

    localStorage.setItem('selectedTip', JSON.stringify(tip));
    this.router.navigate(['/viewtip']);
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
