
<div class="common-banner">
    <img src="../../../assets/img/aboutus-banner.png">
    <!-- <div class="common-text">
      <h2>Membership Plans that helps you to grow parallelly</h2>
    </div> -->
  </div>
  <section class="common-overlap">
    <div class="common-text">
      <h2>Select category of your choice.</h2>
    </div>
  </section>
  <section>
  
  <div class="scroll user-pro" class="card-handler">
    <div
      *ngIf="categories?.length > 0"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScrollDown()"
      class="scroll user-pro"
      class="card-handler"
      class="container"
    >
      <div class="row">
        <div class="card-handler categories">
          <div
            (click)="tipByCategory(category)"
            class="card-wrap"
            *ngFor="let category of categories"
          >
            <div class="text-wrap">
              <div class="text-inside-category">
                <div>
                  <h1 style="cursor: pointer" (click)="tipByCategory(category)">
                    {{ category.Name | titlecase }}
                  </h1>
                  <h4>
                    <span>{{ category.TotalTips }}</span
                    >Total Tips
                  </h4>
                </div>
                <div class="cat-arrow">
                  <img
                    (click)="tipByCategory(category)"
                    style="
                      height: 15px;
                      width: 15px;
                      margin-top: 6px;
                      cursor: pointer;
                    "
                    src="assets/img/arrow.png"
                  />
                </div>
              </div>
  
              <!-- <div class="bottom-fix">
            <div class="button-wrap">
              <div class="cat-name">
  
              </div>
            </div>
          </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="noResultReturned" class="img-div-left">
      <img
        alt="loading spinner"
        onContextMenu="return false;"
        class="spinner-global img-fluid"
        style="width: 100px; height: 100px; display: block; margin: 0px auto"
        src="../../../../assets/img/Spinner.gif"
      />
    </div>
  </div>
  </section>