<div (contextmenu)="onRightClick($event)" id="header" class="header-position">
  <div class="top-bar">
    <div class="row">
      <div class="col-sm-12 linkbtn-wrap">
        <ul>
          <li>
            <a href="mailto: support@youitip.com"
              >Email: support@swashsolutions.com</a
            >
          </li>
          <!-- <li><a href="tel:+91-9587469854">Call: + 91-9587469854</a></li> -->
        </ul>
        <span class="candidate-btn">
          <button>Candidate Login</button>
        </span>
      </div>
    </div>
  </div>
  <header>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="navbar-header">
        <a
          style="padding-right: 4rem"
          class="navbar-brand"
          routerLink="/tiphome"
        >
          <div class="navbar-text">
            <img (click)="reset()" src="../../../../assets/img/logo.png" />
          </div>
        </a>
        <button
          (click)="openCreateDialog()"
          class="btn btn-create-post"
          data-toggle="modal"
          data-target="#LoginAccount"
        >
          Create/Post Tips
        </button>
        
        <a class="download-btn" href="https://play.google.com/store/apps/details?id=io.ionic.youitip" target="_blank"><i class="fas fa-download"></i></a>
      </div>
      <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav float-right">
          <li class="nav-item" [ngClass]="{ active: cClicked }">
            <a class="nav-link" (click)="category()">Categories</a>
          </li>

          <li class="nav-item" [ngClass]="{ active: wiClicked }">
            <a
              class="nav-link"
              style="cursor: pointer"
              (click)="openTipDialog()"
              >Who is using Youitip?</a
            >
          </li>
          <li class="nav-item" [ngClass]="{ active: abClicked }">
            <a class="nav-link" style="cursor: pointer" (click)="aboutus()"
              >About Us</a
            >
          </li>
          <li class="nav-item" [ngClass]="{ active: reClicked }">
            <a (click)="reels()" class="nav-link">Reels</a>
          </li>
          <li class="nav-item" [ngClass]="{ active: prClicked }">
            <a (click)="pricing()" class="nav-link">Pricing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/login" *ngIf="!userData">
              <i
                class="fa fa-sign-in"
                aria-hidden="true"
                style="margin-right: 5px"
              ></i
              >Login</a
            >
          </li>

          <li *ngIf="userData" [ngClass]="{ active: upClicked }">
            <div class="u-profile" style="display: grid">
              <img
                (click)="goToUserProfile()"
                src="{{ profilePic }}"
                style="
                  height: 30px;
                  width: 30px;
                  border-radius: 50%;
                  margin-left: 30px;
                  cursor: pointer;
                  pointer-events: all;
                "
              />
              <a
                (click)="goToUserProfile()"
                style="font-weight: bold; cursor: pointer; font-size: 16px"
                >{{ name }}</a
              >
            </div>
          </li>

          <li class="nav-item" *ngIf="!userData">
            <a class="nav-link" routerLink="/register" *ngIf="!userData">
              <i class="fa fa-user-plus" aria-hidden="true"></i>Sign up</a
            >
          </li>
        </ul>
      </div>
      <span style="margin-left: 94%" class="mobileonly">
        <mat-icon
          style="
            font-size: 30px;
            color: gray;
            margin-top: 10px;
            margin-right: 40%;
            cursor: pointer;
          "
          [matMenuTriggerFor]="menu"
        >
          menu</mat-icon
        >
        <mat-menu #menu="matMenu" class="menuMobile newmenumobiledesign">
          <div *ngIf="userData" class="mobile-u-name">
            <h4>Welcome {{ name }}!</h4>
            <img (click)="goToUserProfile()" src="{{ profilePic }}" />
          </div>
          <button class="menu-btn" mat-menu-item [matMenuTriggerFor]="PersonalInfo">
            <i class="fa-solid fa-user"></i>Personal Info
          </button>
          <button class="menu-btn" mat-menu-item [matMenuTriggerFor]="AccountSettings">
            <i _ngcontent-igv-c141="" class="fas fa-user-cog"></i> Account Settings
          </button>
          <button class="menu-btn" mat-menu-item [matMenuTriggerFor]="Legal"><i class="fas fa-balance-scale-left"></i>Legal</button>
          <button class="menu-btn" mat-menu-item [matMenuTriggerFor]="Help"><i class="fas fa-balance-scale-left"></i>Help</button>
          <button class="menu-btn" routerLink="oneminvids" mat-menu-item>
            <i class="fa fa-user" aria-hidden="true"></i>Watch a 1min video
          </button>
          <button class="menu-btn" *ngIf="userData" routerLink="/reels" mat-menu-item>
            <i class="fa fa-video" area-hidden="true"></i>Reels
          </button>
          <div class="bottm-link">Email: support@swashsolutions.com</div>
        </mat-menu>
        <mat-menu #PersonalInfo="matMenu">
          <button *ngIf="userData" routerLink="muserprofile" mat-menu-item>
            <i class="fa fa-user" aria-hidden="true"></i>My Tips
          </button>
          <button *ngIf="userData" routerLink="mmyplan" mat-menu-item>
            <i class="fa fa-user" aria-hidden="true"></i>My Plan
          </button>
          <button *ngIf="userData" (click)="savedTips()" mat-menu-item>
            <i class="fa fa-bookmark" aria-hidden="true"></i>My Saved Tips
          </button>
          <button *ngIf="userData" routerLink="/rewards" mat-menu-item>
            <i class="fa-solid fa-trophy" area-hidden="true"></i>My Rewards
          </button>
          <button *ngIf="userData" routerLink="mfollowerstips" mat-menu-item>
            <i class="fa fa-user" aria-hidden="true"></i>My Tips For Followers
          </button>
          <button *ngIf="userData" routerLink="/mfollowing" mat-menu-item>
            <i class="fa fa-user" aria-hidden="true"></i>Following
          </button>
          <button *ngIf="userData" routerLink="/mfollowers" mat-menu-item>
            <i class="fa fa-user" aria-hidden="true"></i>Followers
          </button>
          <button *ngIf="userData" routerLink="/mnotifications" mat-menu-item>
            <i class="fa fa-bell" aria-hidden="true"></i>Notifications
          </button>
        </mat-menu>

        <mat-menu #AccountSettings="matMenu">
          <button (click)="changePassword()" mat-menu-item>
            <i class="fa fa-key" aria-hidden="true"></i>Reset Password
          </button>
          <button routerLink="/register" *ngIf="!userData" mat-menu-item>
            <i class="fa fa-user-plus"></i>Sign Up
          </button>
          <button routerLink="/login" *ngIf="!userData" mat-menu-item>
            <i class="fa fa-sign-in"></i>Log In
          </button>
          <button
            routerLink="/tiphome"
            (click)="logout()"
            *ngIf="userData"
            mat-menu-item
          >
            <i aria-hidden="true" class="fa fa-sign-out"></i>Log Out
          </button>
        </mat-menu>
        <mat-menu #Legal="matMenu">
          <button *ngIf="userData" routerLink="/pricing" mat-menu-item>
            <i class="fa fa-rupee-sign" area-hidden="true"></i>Pricing
          </button>
          <button routerLink="/aboutus" mat-menu-item>
            <i class="fa fa-address-card"></i>About Us
          </button>
          <button routerLink="/contactus" mat-menu-item>
            <i class="fa fa-address-card"></i>Contact Us
          </button>
          <button routerLink="/ppolicy" mat-menu-item>
            <i class="fa fa-file-alt"></i>Privacy Policy
          </button>
          <button routerLink="/refundpolicy" mat-menu-item>
            <i class="fa fa-file-alt"></i>Refund Policy
          </button>
          <button routerLink="/tandc" mat-menu-item>
            <i class="fa fa-file-alt"></i>Terms and Conditions
          </button>
        </mat-menu>
        <mat-menu #Help="matMenu">
          <button routerLink="/whoisusingyouitip" mat-menu-item>
            <i class="fa fa-male" aria-hidden="true"></i>Who is using Youitip
          </button>
        </mat-menu>
      </span>
    </nav>
  </header>
  
</div>
