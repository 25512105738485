<div class="container" (contextmenu)="onRightClick($event)">
  <div class="search-wrap">
    <div class="searchbar">
      <input
        class="search_input"
        (input)="onSearchChange()"
        [(ngModel)]="searchinput"
        type="text"
        name=""
        placeholder="Search..."
      />
      <a style="cursor: pointer" class="search_icon"
        ><span>Search</span><i class="fas fa-search"></i
      ></a>
    </div>
  </div>
  <div class="count-wrap">
    <div>
      <p>{{ totalTips }}+</p>
      <p>Total tips</p>
    </div>
    <div>
      <p>{{ totalCategories }}+</p>
      <p>Total categories</p>
    </div>
    <!-- <div>
      <p>7851+</p>
      <p>Total tips created</p>
    </div> -->
  </div>
  <div class="btn downloadbtnadd">
    <button (click)="watchoneminvid()">Watch a 1min video</button>
    <a class="downbtn" href="https://play.google.com/store/apps/details?id=io.ionic.youitip" target="_blank">Download&nbsp;<i class="fas fa-download"></i></a>
    <!-- <button (click)="download()">Download&nbsp;<i class="fas fa-download"></i></button> -->

  </div>
</div>
