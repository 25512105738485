import { ViewtipComponent } from './../viewtip/viewtip.component';
import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { TipService } from 'src/app/sharedService/tip.service';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataService } from 'src/app/sharedService/data.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommentComponent } from '../comment/comment.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { TipHelperService } from 'src/app/sharedService/tiphelper.service';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  tips: any = [];
  isMobileView: boolean;
  loadMore: boolean;
  pageSize: any;
  carouselOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,

    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };

  userDetails: any;
  userData: any;
  tipliked: boolean;
  userEmail: any;
  noResultReturned: boolean;
  searchInput: string;

  pageIndex: number;
  mostViewedTips: any;
  mostLikedTips: any;
  dashboardDetails: any;
  IsSearch: boolean;

  totalTips: any;
  totalCategories: any;
  tipsMetadata: any;

  isMostLikedTips: boolean;
  isMostViewedTips: boolean;
  isMostSharedTips: boolean;
  isAllTips: boolean;
  noMoreRecordsAvailable: boolean;

  constructor(
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private titleAndMetaService: TitleAndMetaService,
    private dataService: DataService,
    private _cdf: ChangeDetectorRef,
    private tipHelperService: TipHelperService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.loadMore = true;
    this.noMoreRecordsAvailable = false;
    this.mostViewedTips = [];
    this.mostLikedTips = [];
    this.userData = JSON.parse(localStorage.getItem('YouITipLoggedInUser'));
    console.log(this.userData);
    if (this.userData != null) {
      this.userEmail = this.userData.LoggedInUser.UserEmail;
      console.log(this.userEmail);
    } else {
      this.userEmail = '';
    }

    this.getDashboardDetails();
    this.getMetadataRecords();
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  async likeTip(tip) {
    if (this.userData != null) {
      var rowKey = this.tipHelperService.getRowKey(tip);
      this.tipService
        .likeTip(rowKey, this.userData.LoggedInUser.UserEmail, tip.LikeCount)
        .subscribe(async (data) => {
          console.log(data.IsTipLiked);
          if (data.IsTipLiked) {
            tip.LikeCount++;
            this.tipliked = true;
            tip.IsTipLiked = true;
            this.notificationService.showAnimation(this.tips, tip);
            setTimeout(() => {
              this.tipliked = false;
            }, 2000);
          } else {
            tip.LikeCount--;
            tip.IsTipLiked = false;
          }
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  async saveTip(tip) {
    console.log(tip);
    if (this.userData != null) {
      if (this.userData != null) {
        var rowKey = this.tipHelperService.getRowKey(tip);
        this.tipService
          .saveTip(rowKey, this.userData.LoggedInUser.UserEmail)
          .subscribe(async (data) => {
            console.log(data);
            tip.IsTipSaved = data.IsTipSaved;

            if (data.IsTipSaved) {
              this.notificationService.showAnimation(this.tips, tip);
              tip.SaveCount++;
              this.tipliked = true;
              setTimeout(() => {
                this.tipliked = false;
              }, 2000);
            } else {
              tip.SaveCount--;
            }
          });
      }
    } else {
      this.router.navigate(['/login']);
    }
  }

  viewUserProfile(userEmail) {
    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        console.log('myprofile');
        this.router.navigate(['myprofile/editprofile']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        console.log('otheruserprofile');
        this.router.navigate(['otheruserprofile/otherusertips']);
      }
    }
  }

  viewMobileUserProfile(userEmail) {
    console.log('inviewmobileuser');
    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        this.router.navigate(['/muserprofile']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        this.router.navigate(['/mothertips']);
      }
    }
  }

  tipComment(tip) {
    if (this.userData != null || this.userData != undefined) {
      localStorage.setItem('tipComment', JSON.stringify(tip));
      const dialogRef = this.dialog.open(CommentComponent);
    } else {
      this.router.navigate(['/login']);
    }
  }

  async getMetadataRecords() {
    this.userEmail = '';
    if (this.userData != null) {
      this.userEmail = this.userData.LoggedInUser.UserEmail;
    }

    this.tipService
      .getMetadataRecords(this.userEmail)
      .subscribe(async (data) => {
        console.log(data);
        this.tipsMetadata = data.TipsMetadata.filter(
          (x) => x.Key != 'AppVersion'
        );

        this.pageSize = data.TipsMetadata.filter((x) => x.Key == 'PageSize')[0];

        localStorage.setItem('AppPageSize', this.pageSize.Value);

        console.log(this.pageSize.Value);
        this.setTipLoadCategory('All');
      });
  }

  notifierSubscription: Subscription =
    this.dataService.subjectSearchNotifier.subscribe((searchInput) => {
      if (
        searchInput == '' ||
        searchInput == null ||
        searchInput == undefined
      ) {
        this.resetLocalStorage();
        this.IsSearch = false;
      } else {
        localStorage.removeItem('youtiphomesearchpageIndex');
        localStorage.setItem('youtiphomesearchpageIndex', '0');
        this.tips = [];
        this.userData = JSON.parse(localStorage.getItem('YouITipLoggedInUser'));
        console.log(this.userData);
        this.searchInput = searchInput;
        this.loadSearchResult('0');
        this.IsSearch = true;
      }
    });

  notifierViewCountSubscription: Subscription =
    this.dataService.subjectViewCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('found tip');
        tip.ViewCount = Input.count;
        this._cdf.detectChanges();
      }
    });

  notifierCommentCountSubscription: Subscription =
    this.dataService.subjectCommentCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('found tip');
        console.log(Input.count);
        tip.CommentCount = Input.count;
        this._cdf.detectChanges();
      }
    });

  notifierLikeCountSubscription: Subscription =
    this.dataService.subjectLikeCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('like found tip');
        console.log(tip.IsTipLiked);
        tip.IsTipLiked = Input.IsTipLiked;
        if (tip.IsTipLiked) {
          tip.LikeCount++;
        } else {
          tip.LikeCount--;
        }
        this._cdf.detectChanges();
      }
    });

  notifierSaveCountSubscription: Subscription =
    this.dataService.subjectSaveCountNotifier.subscribe((Input) => {
      var tip = this.tips.filter((t) => t.RowKey == Input.searchInput)[0];
      if (tip != undefined && tip != null) {
        console.log('found tip');
        tip.IsTipSaved = Input.IsTipSaved;
        this._cdf.detectChanges();
      }
    });

  ngOnInit() {
    this.titleAndMetaService.setTitle(
      'YouITip - Effective Tips for all generation in the world'
    );
    this.titleAndMetaService.setMetaTag();
    this.resetLocalStorage();
  }

  resetLocalStorage() {
    localStorage.removeItem('youtiphomepageIndex');
    localStorage.setItem('youtiphomepageIndex', '0');

    localStorage.removeItem('youtiphomesearchpageIndex');
    localStorage.setItem('youtiphomesearchpageIndex', '0');

    localStorage.removeItem('youtiphomemostlikedpageIndex');
    localStorage.setItem('youtiphomemostlikedpageIndex', '0');

    localStorage.removeItem('youtiphomemostviewedpageIndex');
    localStorage.setItem('youtiphomemostviewedpageIndex', '0');

    localStorage.removeItem('youtiphomemostsharedpageIndex');
    localStorage.setItem('youtiphomemostsharedpageIndex', '0');
  }

  setTipLoadCategory(category) {
    this.tips = [];
    switch (category) {
      case 'All':
        this.isAllTips = true;
        this.isMostLikedTips = false;
        this.isMostViewedTips = false;
        this.isMostSharedTips = false;
        this.getTips(0);
        break;
      case 'MV':
        this.isAllTips = false;
        this.isMostLikedTips = false;
        this.isMostViewedTips = true;
        this.isMostSharedTips = false;
        this.getMostViewedTips(0);
        break;
      case 'ML':
        this.isAllTips = false;
        this.isMostLikedTips = true;
        this.isMostViewedTips = false;
        this.isMostSharedTips = false;
        this.getMostLikedTips(0);
        break;
      case 'MS':
        this.isAllTips = false;
        this.isMostLikedTips = false;
        this.isMostViewedTips = false;
        this.isMostSharedTips = true;
        this.getMostSharedTips(0);
        break;
    }
  }

  setLoadMore(tips) {
    this.loadMore = !(tips.length < this.pageSize.Value);
  }

  getTips(pageIndex) {
    this.noResultReturned = true;
    this.tipService.getTips(pageIndex, this.userEmail).subscribe((res) => {
      this.noResultReturned = false;

      res.Tips.forEach((element) => {
        this.tips.push(element);
      });

      this.setLoadMore(res.Tips);
      console.log(this.tips);
      console.log(this.tips);
      this.noResultReturned = false;
    });
  }

  getDashboardDetails() {
    this.tipService.getDashboardDetails(this.userEmail).subscribe((res) => {
      console.log(res.DashboardDetails);

      this.dashboardDetails = res.DashboardDetails.HomePageTiles;

      this.dashboardDetails.forEach((element) => {
        console.log(element);
      });

      console.log(this.dashboardDetails);
      this.noResultReturned = false;
    });
  }

  loadSearchResult(pageIndex) {
    console.log('loadSearchResult(pageIndex)');
    this.noResultReturned = true;
    this.tipService
      .searchTips(pageIndex, this.searchInput)
      .subscribe(async (data) => {
        if (data.Tips.length <= 0) {
          this.noResultReturned = false;
        } else {
          this.noResultReturned = false;
          data.Tips.forEach((element) => {
            this.tips.push(element);
          });
        }
        if (event != undefined) {
        }
      });
  }

  loadMoreTips() {
    console.log(this.isAllTips);
    console.log(this.isMostViewedTips);
    console.log(this.isMostLikedTips);
    this.delayedServiceCall();
  }

  delayedServiceCall() {
    if (this.searchInput != null && this.searchInput != undefined) {
      var pageIndex = localStorage.getItem('youtiphomesearchpageIndex');
      pageIndex = (parseInt(pageIndex) + 1).toString();
      localStorage.setItem('youtiphomesearchpageIndex', pageIndex);
      this.loadSearchResult(pageIndex);
    }

    if (this.isAllTips) {
      var pageIndex = localStorage.getItem('youtiphomepageIndex');
      pageIndex = (parseInt(pageIndex) + 1).toString();
      localStorage.setItem('youtiphomepageIndex', pageIndex);
      console.log(pageIndex);
      this.getTips(pageIndex);
    }

    if (this.isMostViewedTips) {
      var pageIndex = localStorage.getItem('youtiphomemostviewedpageIndex');
      pageIndex = (parseInt(pageIndex) + 1).toString();
      localStorage.setItem('youtiphomemostviewedpageIndex', pageIndex);
      console.log(pageIndex);
      this.getMostViewedTips(pageIndex);
    }

    if (this.isMostLikedTips) {
      var pageIndex = localStorage.getItem('youtiphomemostlikedpageIndex');
      pageIndex = (parseInt(pageIndex) + 1).toString();
      localStorage.setItem('youtiphomemostlikedpageIndex', pageIndex);
      console.log(pageIndex);
      this.getMostLikedTips(pageIndex);
    }

    if (this.isMostSharedTips) {
      var pageIndex = localStorage.getItem('youtiphomemostsharedpageIndex');
      pageIndex = (parseInt(pageIndex) + 1).toString();
      localStorage.setItem('youtiphomemostsharedpageIndex', pageIndex);
      console.log(pageIndex);
      this.getMostSharedTips(pageIndex);
    }
  }

  tipByCategory(tip) {
    localStorage.setItem(
      'tipCategory',
      JSON.stringify({
        categoryName: tip.CategoryName,
        categoryId: tip.CategoryId,
      })
    );
    this.router.navigate(['/tipbycategory']);
  }

  viewTip(tip) {
    localStorage.setItem('selectedTip', JSON.stringify(tip));
    this.router.navigate(['/viewtip']);
  }

  async getMostViewedTips(pageIndex) {
    console.log('most V');
    this.noResultReturned = true;
    this.tipService
      .getMostViewedTips(pageIndex, this.userEmail)
      .subscribe(async (data) => {
        this.noResultReturned = false;
        if (data.Tips.length <= 0) {
          this.noResultReturned = false;
          this.noMoreRecordsAvailable = true;
        } else {
          this.noResultReturned = false;

          data.Tips.forEach((element) => {
            this.tips.push(element);
          });
        }

        this.setLoadMore(data.Tips);
      });
  }

  async getMostLikedTips(pageIndex) {
    console.log('most L');
    this.noResultReturned = true;
    this.tipService
      .getMostLikedTips(pageIndex, this.userEmail)
      .subscribe(async (data) => {
        this.noResultReturned = false;
        if (data.Tips.length <= 0) {
          this.noResultReturned = false;
          this.noMoreRecordsAvailable = true;
        } else {
          this.noResultReturned = false;

          data.Tips.forEach((element) => {
            this.tips.push(element);
          });
        }

        this.setLoadMore(data.Tips);
      });
  }

  async getMostSharedTips(pageIndex) {
    console.log('most S');
    this.noResultReturned = true;
    this.tipService
      .getMostSharedTips(pageIndex, this.userEmail)
      .subscribe(async (data) => {
        this.noResultReturned = false;
        if (data.Tips.length <= 0) {
          this.noResultReturned = false;
          this.noMoreRecordsAvailable = true;
        } else {
          this.noResultReturned = false;

          data.Tips.forEach((element) => {
            this.tips.push(element);
          });
        }

        this.setLoadMore(data.Tips);
      });
  }

  loadAllTips() {
    this.resetLocalStorage();
    this.setTipLoadCategory('All');
    console.log('All');
  }

  loadMostViewedTips() {
    console.log('loadMostViewedTips');
    this.resetLocalStorage();
    this.setTipLoadCategory('MV');
  }

  loadMostLikedTips() {
    this.resetLocalStorage();
    this.setTipLoadCategory('ML');
    console.log('loadMostLikedTips');
  }

  loadMostSharedTips() {
    this.resetLocalStorage();
    this.setTipLoadCategory('MS');
    console.log('loadMostSharedTips');
  }

  onTabChanged(tabChangeEvent) {
    this.noMoreRecordsAvailable = false;
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);

    switch (tabChangeEvent.index) {
      case 0:
        this.loadAllTips();
        break;
      case 1:
        this.loadMostViewedTips();
        break;
      case 2:
        this.loadMostLikedTips();
        break;
      case 3:
        this.loadMostSharedTips();
        break;
    }
  }

  onScrollDown() {}
}
