import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss'],
})
export class ContactusComponent implements OnInit {
  public ownerForm: UntypedFormGroup;
  messaage: string;
  messaageCtrl: any;
  currentImage: any;
  previousIndex: any;
  showImg = false;
  arrow = false;
  itemCount = 0;
  scrHeight: any;
  scrWidth: any;
  SlideOptions: any;
  profilePicture: string;
  userDisplayName: any;
  userData: any;
  showDefaultImage: boolean;
  email: any;
  phone: any;
  info: any;
  website: any;
  uname: any;
  name: any;
  isLogin = false;
  activeSection = 1;
  checkbox: boolean;
  maxdesclenght: number;
  currentdesclenght: number;
  disableButton: boolean;
  token: any;
  
  constructor(
    private titleAndMetaService: TitleAndMetaService,
    private router: Router,
    private tipService: TipService,
    private notificationService: NotificationService
  ) {
    this.messaage = '';
    this.disableButton = true;
  }

  ngOnInit(): void {
    this.userData = JSON.parse(localStorage.getItem('YouITipLoggedInUser'));
    console.log(this.userData);
    this.titleAndMetaService.setTitle('Contact Us Page');
    this.titleAndMetaService.setMetaTag();

    this.ownerForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(60),
      ]),
      messaageCtrl: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
    });
    this.maxdesclenght = 500;
    this.currentdesclenght = 0;
    if (this.userData == null) {
      this.router.navigate(['/login']);
    }
  }

  addTokenLog(message: string, token: string | null) {
    console.log(`${message}: ${this.formatToken(token)}`);
  }

  public formatToken(token: string | null) {
    if (token !== null) {
      this.token = token;
      return this.token;
    } else return 'null';
  }


  descChanged() {
    console.log(this.ownerForm.valid);
    console.log('description changed');
    if (this.messaage == null || this.messaage == undefined) return;

    this.messaage = this.messaage.trim();

    if (this.messaage.length == this.maxdesclenght) {
      this.messaage = this.messaage.substring(0, this.messaage.length);
    }
    if (this.currentdesclenght <= this.maxdesclenght) {
      this.currentdesclenght = this.messaage.length;
    }

    this.setIsDisable();
  }

  setIsDisable() {
    console.log(this.currentdesclenght);
    if (this.currentdesclenght > 0) {
      this.disableButton = false;
      console.log(this.disableButton);
    } else {
      console.log(this.disableButton);
      this.disableButton = true;
    }
  }

  submit() {
    const contactUsModel = {
      PartitionKey: this.email,
      Name: this.name,
      Message: this.messaage,
    };

    this.tipService.contactUs(contactUsModel).subscribe(async (data) => {
      if (data.IsConactUsRequestCreated) {
        this.notificationService.showSuccess(
          'Successfully saved the contact us request'
        );
      } else {
        this.notificationService.showError(
          'Failed to save the contact us request. Please contact administrator'
        );
      }
    });
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
