import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TipHelperService } from 'src/app/sharedService/tiphelper.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-reels',
  templateUrl: './oneminvideos.component.html',
  styleUrls: ['./oneminvideos.component.scss'],
})
export class OneminvideosComponent implements OnInit {
  noResultReturned: boolean;
  tips: any = [];
  userData: any;
  pageIndex: number;
  lastImage: any;
  showImage: boolean;
  tipliked: boolean;
  previousVideoTipId: string;
  tipDetails: any;
  isTipPaused: boolean;
  userEmail: string;
  showplaybutton: boolean;
  mute: boolean;
  isplaying: boolean;
  scrolledDown: boolean;
  isVideoDivClicked: boolean;
  currentIndex: number;

  constructor(
    private tipService: TipService,
    private router: Router,
    private titleAndMetaService: TitleAndMetaService,
    private notificationService: NotificationService,
    private tipHelperService: TipHelperService
  ) {}

  ngOnInit(): void {
    this.titleAndMetaService.setTitle('YouITip Reels Page');
    this.titleAndMetaService.setMetaTag();
    if (this.userData != null) {
      this.userData = JSON.parse(localStorage.getItem('YouITipLoggedInUser'));
      console.log(this.userData);
      this.userEmail = this.userData.LoggedInUser.UserEmail;
      console.log(this.userEmail);
    } else {
      this.userEmail = '';
    }

    this.pageIndex = 0;
    this.getAllTips('0');
  }

  watchoneminvid() {}

  slideUp() {
    this.currentIndex--;
    if (this.currentIndex > 0) {
      this.tipDetails = this.tips[this.currentIndex];
    } else {
      this.currentIndex = 0;
      this.tipDetails = this.tips[0];
    }
    console.log(this.currentIndex);
  }

  slideDown() {
    console.log(this.currentIndex);
    console.log(this.tips.length);
    this.currentIndex++;
    if (this.currentIndex < this.tips.length) {
      this.tipDetails = this.tips[this.currentIndex];
    } else {
      this.currentIndex = this.tips.length;
    }
  }

  async getAllTips(pageIndex) {
    this.tipService.getoneminVideoTips(pageIndex).subscribe(async (data) => {
      console.log(data);
      if (data.Tips.length <= 0) {
        this.noResultReturned = true;
      } else {
        this.noResultReturned = false;
        console.log(data.Tips);
        data.Tips.forEach((element) => {
          console.log(element.ShowImage);
          this.tips.push(element);
        });

        console.log(this.tips);
        // this.previousVideoTipId = this.tips[0].RowKey;
        this.tipDetails = this.tips[0];
        //this.playIfVideo(this.tips[0].RowKey);
        this.currentIndex = 0;
        console.log(this.tipDetails);
        this.showplaybutton = false;
      }
    });
  }

  vidEnded(event) {
    console.log(event);
    event.target.play();
  }

  videoClick() {
    console.log('video clicked');
    var vid = document.getElementById('myVideo') as any;
    console.log(vid);
    vid.play();
    this.isplaying = false;
    this.showplaybutton = false;
  }

  videoDivClick() {
    console.log('video clicked');
    var vid = document.getElementById('myVideo') as any;
    if (!this.showplaybutton) {
      vid.pause();
      this.isplaying = true;
      this.showplaybutton = true;
    } else {
      vid.play();
      this.isplaying = false;
      this.showplaybutton = false;
    }
  }

  pauseVideo() {
    console.log('pauseVideo');
    var vid = document.getElementById('myVideo') as any;
    vid.pause();
    this.isplaying = true;
    this.showplaybutton = true;
  }

  playVid() {
    console.log('playVid');
    var vid = document.getElementById('myVideo') as any;
    vid.play();
    this.isplaying = false;
    this.showplaybutton = false;
  }

  vidplay(event) {
    event.target.play();
  }

  muteUnmuteVideo(event) {
    var vid = document.getElementById('myVideo') as any;

    if (!vid.muted) {
      vid.muted = true;
      this.mute = true;
    } else {
      vid.muted = false;
      this.mute = false;
    }
  }

  async likeTip(tip) {
    if (this.userData != null) {
      var rowKey = this.tipHelperService.getRowKey(tip);
      this.tipService
        .likeTip(rowKey, this.userData.LoggedInUser.UserEmail, tip.LikeCount)
        .subscribe(async (data) => {
          console.log(data.IsTipLiked);
          if (data.IsTipLiked) {
            this.notificationService.showAnimation(this.tips, tip);
            tip.LikeCount++;
            this.tipliked = true;
            tip.IsTipLiked = true;
            setTimeout(() => {
              this.tipliked = false;
            }, 2000);
          } else {
            tip.LikeCount--;
            tip.IsTipLiked = false;
          }
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  async saveTip(tip) {
    console.log(tip);
    if (this.userData != null) {
      if (this.userData != null) {
        var rowKey = this.tipHelperService.getRowKey(tip);
        this.tipService
          .saveTip(rowKey, this.userData.LoggedInUser.UserEmail)
          .subscribe(async (data) => {
            console.log(data);
            tip.IsTipSaved = data.IsTipSaved;
            if (data.IsTipSaved) {
              this.notificationService.showAnimation(this.tips, tip);
              tip.SaveCount++;
              this.tipliked = true;
              setTimeout(() => {
                this.tipliked = false;
              }, 2000);
            } else {
              tip.SaveCount--;
            }
          });
      }
    } else {
      this.router.navigate(['/login']);
    }
  }


  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
