<div class="card card_violet text-center" (contextmenu)="onRightClick($event)">
  <div class="price">
    <h4 style="font-size: 35px">{{ offer }}</h4>
    <h6 style="font-size: 18px">
      {{ shortDescription }}
    </h6>

    <img style="height: 50%; width: 50%" src="assets/img/logo.png" />
    <br>
    <img
      *ngIf="showImage"
      src="{{ profilePic }}"
      style="height: 60px; width: 60px; border-radius: 50%"
    />
    <h4 style="font-size: 35px">Offer Details</h4>
    <ul>
      <li *ngFor="let tc of rewardDetails.TcList">{{ tc }}</li>
    </ul>

    <div class="terms-condition">
      <h6>Terms and condition apply</h6>
    </div>
    <div class="option"></div>
    <a href="{{ siteUrl }}">Check Offer</a>
  </div>
</div>

<!-- <mat-accordion>
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> This is the expansion title </mat-panel-title>
      <mat-panel-description>
        This is a summary of the content
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>This is the primary content of the panel.</p>
  </mat-expansion-panel>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Self aware panel </mat-panel-title>
      <mat-panel-description>
        Currently I am {{ panelOpenState ? "open" : "closed" }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p>I'm visible because I am open</p>
  </mat-expansion-panel>
</mat-accordion> -->
