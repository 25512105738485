<div class="common-banner">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
    <h2>Membership Plans that helps you to grow parallelly</h2>
  </div> -->
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2>Contact Us</h2>
  </div>
</section>
<section (contextmenu)="onRightClick($event)">
  <div class="Login">
    <div align="center">
      <div id="formContent">
        <div class="fadeIn first">
          <!-- <P style="color: black; font-size: 20px; font-weight: bold">Sign-In</P> -->
          <!-- <h4 class="blk">Contact Us</h4> -->
        </div>
        <!-- <form
        [formGroup]="ownerForm"
        autocomplete="off"
        novalidate
        fxLayout="column wrap"
        fxLayoutAlign="center center"
        fxLayoutGap="10px"
        class="example-form contact-form row"
      >
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="name"
            type="text"
            placeholder="Name"
            formControlName="name"
            id="name"
          />
        </mat-form-field>
        <div class="form-group">
          <div
            *ngIf="
              ownerForm.controls['name'].invalid &&
              (ownerForm.controls['name'].dirty ||
                ownerForm.controls['name'].touched)
            "
            class="alert alert-danger"
          >
            Name is required.
          </div>
        </div>

        <mat-form-field>
          <input
            matInput
            [(ngModel)]="email"
            type="email"
            placeholder="Email"
            formControlName="email"
            id="email"
          />
        </mat-form-field>
        <div class="form-group">
          <div
            *ngIf="
              ownerForm.controls['email'].invalid &&
              (ownerForm.controls['email'].dirty ||
                ownerForm.controls['email'].touched)
            "
            class="alert alert-danger"
          >
            Email Address is required.
          </div>
        </div>

        <mat-form-field>
          <textarea
            (input)="descChanged()"
            [(ngModel)]="messaage"
            formControlName="messaage"
            name="messaage"
            #message
            maxlength="500"
            matInput
            rows="5"
            class="form-control"
            style="width: 100%; background-color: white"
            placeholder="Enter Tip Description"
          >
          </textarea>
          <mat-hint class="font16" align="end"
            >{{ messaage.length }} / 500</mat-hint
          >
        </mat-form-field>

        <div class="form-group">
          <div
            *ngIf="
              ownerForm.controls['message'].invalid &&
              (ownerForm.controls['message'].dirty ||
                ownerForm.controls['message'].touched)
            "
            class="alert alert-danger"
          >
            Message is required.
          </div>
        </div>

        <button
          style="width: 120px; font-size: 16px"
          type="submit"
          (click)="submit()"
          [disabled]="!ownerForm.valid"
          class="btn btn-primary btn-lg mb-3 fadeIn fourth"
        >
          Submit
        </button>
      </form> -->

        <form [formGroup]="ownerForm" novalidate>
          <input
            type="text"
            class="form-control"
            formControlName="name"
            [(ngModel)]="name"
            [ngClass]="{
              'is-invalid':
                ownerForm.get('name').touched && ownerForm.get('name').invalid
            }"
            name="login"
            required
            class="fadeIn second"
            placeholder="Your name"
          />
          <div
            *ngIf="
              ownerForm.controls['name'].invalid &&
              (ownerForm.controls['name'].dirty ||
                ownerForm.controls['name'].touched)
            "
            class="alert alert-danger"
          >
            Your name required
          </div>
          <input
            type="email"
            class="form-control"
            formControlName="email"
            [(ngModel)]="email"
            [ngClass]="{
              'is-invalid':
                ownerForm.get('email').touched && ownerForm.get('email').invalid
            }"
            name="login"
            required
            class="fadeIn second"
            placeholder="Email Address"
          />
          <div
            *ngIf="
              ownerForm.controls['email'].invalid &&
              (ownerForm.controls['email'].dirty ||
                ownerForm.controls['email'].touched)
            "
            class="alert alert-danger"
          >
            Email Address is required.
          </div>
          <textarea
            (input)="descChanged()"
            [(ngModel)]="messaage"
            formControlName="messaageCtrl"
            name="messaage"
            #message
            maxlength="500"
            matInput
            rows="5"
            class="form-control"
            placeholder="Please enter the details"
          >
          </textarea>
          <mat-hint class="font16" align="end"
            >{{ messaage.length }} / 500</mat-hint
          >
          <br />
          <div align="center" style="width: 100%">
            <re-captcha
              class="g-recaptcha"
              (resolved)="addTokenLog('Normal mode resolved', $event)"
            ></re-captcha>
          </div>
          <br />
          <button
            style="width: 120px; font-size: 16px"
            type="submit"
            (click)="submit()"
            [disabled]="!ownerForm.valid || disableButton || !token"
            class="btn btn-primary btn-lg mb-3 fadeIn fourth"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
