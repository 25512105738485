import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { TipService } from './tip.service';

@Injectable({
  providedIn: 'root',
})
export class TipHelperService {
  userData: any;
  tipliked: boolean;
 
  constructor(private tipService: TipService, private router: Router) {
    this.userData = JSON.parse(localStorage.getItem('YouITipLoggedInUser'));
  }

  getRowKey(tip) {
    var rowKey = tip.MasterRowKey != null ? tip.MasterRowKey : tip.RowKey;
    console.log(rowKey);
    return rowKey;
  }

  // async likeTip(tip) {
  //   if (this.userData != null) {
  //     var rowKey = this.getRowKey(tip);

  //     this.tipService
  //       .likeTip(rowKey, this.userData.UserEmail, tip.LikeCount)
  //       .subscribe(async (data) => {
  //         console.log(data);
  //         if (data.IsTipLiked) {
  //           tip.LikeCount++;
  //           return true;
  //           tip.IsTipLiked = data.IsTipLiked;
  //         } else {
  //           tip.LikeCount--;
  //           tip.IsTipLiked = false;
  //         }
  //       });
  //   } else {
  //     this.router.navigate(['/login']);
  //   }
  // }

  // async saveTip(tip) {
  //   console.log(tip);
  //   if (this.userData != null) {
  //     if (this.userData != null) {
  //       var rowKey = this.tipHelperService.getRowKey(tip);
  //       this.tipService
  //         .saveTip(rowKey, this.userData.LoggedInUser.UserEmail)
  //         .subscribe(async (data) => {
  //           console.log(data);
  //           tip.IsTipSaved = data.IsTipSaved;
  //           if (data.IsTipSaved) {
  //             tip.SaveCount++;
  //             this.tipliked = true;
  //             setTimeout(() => {
  //               this.tipliked = false;
  //             }, 2000);
  //           } else {
  //             tip.SaveCount--;
  //           }
  //         });
  //     }
  //   } else {
  //     this.router.navigate(['/login']);
  //   }
  // }
}
