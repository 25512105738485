<div class="right-panel" (contextmenu)="onRightClick($event)">
  <div class="Login">
    <div align="center">
      <div id="formContent">
        <div class="fadeIn first">
          <!-- <P style="color: black; font-size: 20px; font-weight: bold">Sign-In</P> -->
          <h2>Edit Profile</h2>
        </div>
        <form [formGroup]="registerForm" novalidate>
          <div class="form-container">
            <div class="edit-user">
              <img
                onContextMenu="return false;"
                class="rounded-circle"
                *ngIf="profilePicture"
                [src]="profilePicture"
                style="border-radius: 50%; height: 100px; width: 100px"
              />
              <h3 class="blk">{{ name }}</h3>
            </div>
            <div>
              <label class="hidetext">sample</label>
              <input
                #imageInput
                type="file"
                accept="image/*"
                (change)="selectFiles($event)"
                #fileInput
              />
            </div>
            <div>
              <label>Name</label>
              <input
                
                class="form-control"
                type="text"
                [(ngModel)]="name"
                formControlName="name"
                class="fadeIn second"
                required
              />
            </div>
            <div
              class="alert alert-danger"
              *ngIf="
                registerForm.get('name').hasError('required') &&
                registerForm.get('name').touched
              "
            >
              Name is required
            </div>
            <div>
              <label>Username</label>
              <input
                class="form-control"
                required
                class="fadeIn second"
                
                (focusout)="IsUserNameTaken()"
                type="text"
                [(ngModel)]="userName"
                formControlName="userName"
              />
            </div>
            <div
              class="alert alert-danger"
              *ngIf="
                registerForm.get('userName').hasError('required') &&
                registerForm.get('userName').touched
              "
            >
              UserName is required
            </div>
            <div class="error" *ngIf="isUserNameTaken">
              This user name is taken. Please try another.
            </div>
            <div>
              <label>Email</label>
              <input
                class="form-control"
                required
                class="fadeIn second"
                
                type="email"
                [(ngModel)]="email"
                formControlName="email"
              />
            </div>
            <div
              class="alert alert-danger"
              *ngIf="
                registerForm.get('email').hasError('required') &&
                registerForm.get('email').touched
              "
            >
              Email is required
            </div>
            <div
              class="alert alert-danger"
              *ngIf="
                registerForm.get('email').hasError('email') &&
                registerForm.get('email').touched
              "
            >
              Please enter valid email
            </div>
            <div class="alert alert-danger" *ngIf="userEmailTaken">
              That email address is taken. Please try another.
            </div>

            <div>
              <label>Profession</label>
              <input
                class="form-control"
                required
                class="fadeIn second"
               
                type="text"
                [(ngModel)]="profession"
                formControlName="profession"
              />
            </div>
            <div>
              <label>Bio</label>
              <input
                class="form-control"
                required
                class="fadeIn second"
               
                type="text"
                [(ngModel)]="bio"
                [ngModelOptions]="{ standalone: true }"
              />
            </div>
            <div>
              <label>Phone</label>
              <input
                class="form-control"
                required
                class="fadeIn second"
               
                type="text"
                maxlength="10"
                [(ngModel)]="phone"
                formControlName="phone"
              />
            </div>
            <div
              *ngIf="
                registerForm.controls['phone'].invalid &&
                (registerForm.controls['phone'].dirty ||
                  registerForm.controls['phone'].touched)
              "
              class="alert alert-danger"
            >
              Phone number is required.
            </div>
            <div
              *ngIf="
                !isPhoneValid &&
                (registerForm.controls['phone'].dirty ||
                  registerForm.controls['phone'].touched)
              "
              class="alert alert-danger"
            >
              Please enter valid phone number.
            </div>
          </div>
          <button
            style="width: 120px; font-size: 16px"
            type="submit"
            (click)="register()"
            class="btn btn-primary btn-lg mb-3 fadeIn fourth"
          >
            Save
          </button>
        </form>
      </div>
    </div>
  </div>
  <div *ngIf="noResultReturned" class="img-div-left">
    <img
      alt="loading spinner"
      onContextMenu="return false;"
      class="spinner-global img-fluid"
      style="width: 100px; height: 100px; display: block; margin: 0px auto"
      src="../../../../assets/img/Spinner.gif"
    />
  </div>
</div>
