<div class="right-panel" (contextmenu)="onRightClick($event)">
  <div class="Login">
    <div align="center">
      <div *ngIf="PlanAmount" id="formContent" class="formContent col-12">
        <div class="fadeIn first">
          <P>My Plan</P>
        </div>
        <div>
          <div>
            <p>{{ PlanType }}</p>
            <p class="p-mode"><span></span>Rs.{{ PlanAmount }}/month</p>
          </div>
          <div class="myplan-right">
            <p>
              Next Renewal on <b>{{ PurchaseEndDate | date }}</b>
            </p>
            <p>Subscribed</p>
          </div>
        </div>
      </div>

      <div *ngIf="!PlanAmount" id="formContent" class="formContent col-12">
        <div class="fadeIn first">
          <P>My Plan</P>
        </div>
        <div>
          <div>
            <p>You are on No Money Plan</p>
            <p class="p-mode">
              <span></span>Please <a class="hyperlink" routerLink="/pricing">Subscribe</a> to enjoy the YouITip
              services
            </p>
          </div>
          <!-- <div class="myplan-right">
            <p>
              Next Renewal on <b>{{ PurchaseEndDate | date }}</b>
            </p>
            <p>Subscribed</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
