import { Injectable, NgZone } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarModule,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(public snackBar: MatSnackBar, public zone: NgZone) {}

  showSuccess(message: string): void {
    this.zone.run(() =>
      this.snackBar.open(message, '', {
        duration: 2000,
        panelClass: 'snackBarpanelClass',
      })
    );
  }

  showError(message: string): void {
    this.zone.run(() =>
      this.snackBar.open(message, 'X', { panelClass: ['snackBarpanelClassError'] })
    );
  }

  showAnimation(tips, tip) {
    tips.forEach((element) => {
      element.AnimateTip = false;
      tip.AnimateTip = true;
    });
  }
}
