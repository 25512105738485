import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ResolveEnd, Router, ActivatedRouteSnapshot } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class ErrorLoggingService {
  appInsights: ApplicationInsights;
  private routerSubscription: Subscription;

  constructor(private router: Router) {
    //   this.appInsights = new ApplicationInsights({
    //   config: {
    //     instrumentationKey: environment.appInsights.instrumentationKey,
    //     enableAutoRouteTracking: true, // option to log all route changes
    //   },
    // });
    //   this.appInsights.loadAppInsights();
    //   this.appInsights.trackPageView();

    //   this.routerSubscription = this.router.events
    //   .pipe(filter((event) => event instanceof ResolveEnd))
    //   .subscribe((event: ResolveEnd) => {
    //     const activatedComponent = this.getActivatedComponent(event.state.root);
    //     if (activatedComponent) {
    //       this.logPageView(
    //         `${activatedComponent.name} ${this.getRouteTemplate(
    //           event.state.root
    //         )}`,
    //         event.urlAfterRedirects
    //       );
    //     }
    //   });
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }

    return path;
  }

  // tslint:disable-next-line: typedef
  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name,
      uri: url,
    });
  }

  // tslint:disable-next-line: typedef
  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name }, properties);
  }

  // tslint:disable-next-line: typedef
  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  // tslint:disable-next-line: typedef
  logException(exception: Error, severityLevel?: number) {

    // if (exception?.message?.toLowerCase()?.includes('OAuthError: User is not assigned to the client application'.toLowerCase())) {
    //   this.toastr.info(`Please request access for application at https://capita.okta-emea.com/.`);
    // }

      // this.appInsights.trackException({
      //       exception,
      //       severityLevel,
      //     });
  }

  // tslint:disable-next-line: typedef
  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message }, properties);
  }
}
