import { ErrorHandler, Injector, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorLoggingService } from '../sharedService/error-logging.service';
import { ErrorService } from '../sharedService/error.service';
import { NotificationService } from '../sharedService/notification.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  handleError(error: Error | HttpErrorResponse): void {
    const errorService = this.injector.get<ErrorService>(ErrorService);
    const logger = this.injector.get(ErrorLoggingService);
    const notifier =
      this.injector.get<NotificationService>(NotificationService);

    let message: string;

    if (error instanceof HttpErrorResponse) {
      // tslint:disable-next-line: no-debugger
      //debugger;
      message = errorService.getServerErrorMessage(error);
      logger.logException(error);
      // notifier.showError(message);
    } else {
      // tslint:disable-next-line: no-debugger
      //debugger;
      message = errorService.getClientErrorMessage(error);
      console.log(error);
      logger.logException(error);
      notifier.showError(message);
    }
    super.handleError(error);
  }
}
