import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/* Angular Material Module imports */
import { AppRoutingModule } from './app-routing.module';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { GlobalErrorHandler } from './sharedModule/global-error-handler';
import { ErrorLoggingService } from './sharedService/error-logging.service';
import { ServerErrorInterceptor } from './interceptors/server-error-interceptor';
import { HeaderComponent } from './sharedModule/header/header/header.component';
import { FooterComponent } from './sharedModule/footer/footer/footer.component';
import { ChangepasswordComponent } from './featureModule/changepassword/changepassword.component';
import { ResetpasswordComponent } from './featureModule/resetpassword/resetpassword.component';
import { LoginComponent } from './featureModule/login/login.component';
import { RegisterComponent } from './featureModule/register/register.component';
import { HomeComponent } from './featureModule/home/home.component';
import { TipbycategoryComponent } from './featureModule/tipbycategory/tipbycategory.component';
import { UserprofileComponent } from './featureModule/userprofile/userprofile.component';
import { EditprofileComponent } from './featureModule/editprofile/editprofile.component';
import { PreviewprofileComponent } from './featureModule/previewprofile/previewprofile.component';
import { SavedtipsComponent } from './featureModule/savedtips/savedtips.component';
import { ViewtipComponent } from './featureModule/viewtip/viewtip.component';
import { WhoisusingyoutipComponent } from './featureModule/whoisusingyoutip/whoisusingyoutip.component';
import { MyrewardsComponent } from './featureModule/myrewards/myrewards.component';
import { FollowersComponent } from './featureModule/followers/followers.component';
import { FollowingComponent } from './featureModule/following/following.component';
import { CreatetipComponent } from './featureModule/createtip/createtip.component';
import { EdittipComponent } from './featureModule/edittip/edittip.component';
import { TipcommentsComponent } from './featureModule/tipcomments/tipcomments.component';
import { ActivateaccountComponent } from './featureModule/activateaccount/activateaccount.component';
import { PpolicyComponent } from './featureModule/ppolicy/ppolicy.component';
import { TandcComponent } from './featureModule/tandc/tandc.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
//import { youitipwebMaterialModule } from './sharedModule/youitip-material.module';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
// import { MsalModule } from '@azure/msal-angular';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThirdpartylibraryComponent } from './featureModule/thirdpartylibrary/thirdpartylibrary.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { OwlModule } from 'ngx-owl-carousel';
import { SearchComponent } from './featureModule/search/search.component';
import { NotificationsComponent } from './featureModule/notifications/notifications.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AboutusComponent } from './sharedModule/aboutus/aboutus.component';
import { ContactusComponent } from './sharedModule/contactus/contactus.component';
import { PricingComponent } from './sharedModule/pricing/pricing.component';
import { ReelsComponent } from './featureModule/reels/reels.component';
import { RouterModule } from '@angular/router';
import { BannerComponent } from './sharedModule/banner/banner.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DateAgoPipeModule } from './pipes/date-ago.pipe';
import { CategoriesComponent } from './featureModule/categories/categories.component';
//import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { OtheruserprofileComponent } from './featureModule/otheruserprofile/otheruserprofile.component';
import { CommentComponent } from './featureModule/comment/comment.component';
import { EditcommentComponent } from './featureModule/editcomment/editcomment.component';
import { MatGridListModule } from '@angular/material/grid-list';

import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatBadgeModule } from '@angular/material/badge';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ScratchcardComponent } from './featureModule/scratchcard/scratchcard.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FilterComponent } from './featureModule/filter/filter.component';
import { MatListModule } from '@angular/material/list';
import { ReportComponent } from './featureModule/report/report.component';
import { MyprofileComponent } from './featureModule/myprofile/myprofile.component';
import { OneminvideosComponent } from './sharedModule/oneminvideos/oneminvideos.component';
import { OtherusertipsComponent } from './featureModule/otherusertips/otherusertips.component';
import { MyTipsComponent } from './featureModule/my-tips/my-tips.component';
import { MFollowersComponent } from './featureModule/m-followers/m-followers.component';
import { MFollowingComponent } from './featureModule/m-following/m-following.component';
import { MSavedTipsComponent } from './featureModule/m-saved-tips/m-saved-tips.component';
import { MChangedPasswordComponent } from './featureModule/m-changed-password/m-changed-password.component';
import { MOtherTipsComponent } from './featureModule/m-other-tips/m-other-tips.component';
import { MEditProfileComponent } from './featureModule/m-edit-profile/m-edit-profile.component';
import { CheckoutComponent } from './featureModule/checkout/checkout.component';
import { MrewardComponent } from './featureModule/mreward/mreward.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { McategoriesComponent } from './featureModule/mcategories/mcategories.component';
import { MnotificationsComponent } from './featureModule/mnotifications/mnotifications.component';
import { FollowerstipsComponent } from './featureModule/followerstips/followerstips.component';
import { MfollowerstipsComponent } from './featureModule/mfollowerstips/mfollowerstips.component';
import { MyplanComponent } from './featureModule/myplan/myplan.component';
import { MmyplanComponent } from './featureModule/mmyplan/mmyplan.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ChangepasswordComponent,
    ResetpasswordComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    TipbycategoryComponent,
    UserprofileComponent,
    EditprofileComponent,
    PreviewprofileComponent,
    SavedtipsComponent,
    ViewtipComponent,
    WhoisusingyoutipComponent,
    MyrewardsComponent,
    FollowersComponent,
    FollowingComponent,
    CreatetipComponent,
    EdittipComponent,
    TipcommentsComponent,
    ActivateaccountComponent,
    PpolicyComponent,
    TandcComponent,
    ThirdpartylibraryComponent,
    SearchComponent,
    NotificationsComponent,

    AboutusComponent,
    ContactusComponent,
    PricingComponent,

    ReelsComponent,

    BannerComponent,
    CategoriesComponent,
    OtheruserprofileComponent,
    CommentComponent,
    EditcommentComponent,
    ScratchcardComponent,
    FilterComponent,
    ReportComponent,
    MyprofileComponent,
    OneminvideosComponent,
    OtherusertipsComponent,
    MyTipsComponent,
    MFollowersComponent,
    MFollowingComponent,
    MSavedTipsComponent,
    MChangedPasswordComponent,
    MOtherTipsComponent,
    MEditProfileComponent,
    CheckoutComponent,
    MrewardComponent,
    McategoriesComponent,
    MnotificationsComponent,
    FollowerstipsComponent,
    MfollowerstipsComponent,
    MyplanComponent,
    MmyplanComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    MatSnackBarModule,
    FormsModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSidenavModule,
    CarouselModule,
    OwlModule,
    MatDialogModule,
    RouterModule,
    InfiniteScrollModule,
    DateAgoPipeModule,
    MatMenuModule,
    MatIconModule,
    MatGridListModule,
    MatTableModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatToolbarModule,
    MatCheckboxModule,
    CarouselModule, 
    MatExpansionModule,
    MatListModule,
    MatSelectModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    // MsalModule.forRoot({
    //   auth: {
    //     clientId:  ''
    //   }
    // })
  ],
  exports: [HeaderComponent, FooterComponent],

  providers: [
    ErrorLoggingService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: "6Ldp3uohAAAAAADWsSw-6QFyiA0d2eTywQywjpMp",
      } as RecaptchaSettings,
    },
    //youitipwebMaterialModule,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
