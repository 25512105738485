<footer *ngIf="!hideFooter" (contextmenu)="onRightClick($event)">
  <div class="fab-buttonwrapper">
    <button (click)="openCreateDialog()" mat-fab color="primary">
      <i class="fa fa-plus" aria-hidden="true"></i>
    </button>
  </div>
  <div class="container1">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xl-5">
            <div class="row">
              <div class="col-sm-12">
                <div class="f-logo">
                  <img src="../../../../assets/img/logo-white.png" />
                </div>
                <p>
                  We are providing the platform where people get quick answer to
                  their queries using fingertip on YOUiTIP. We are providing
                  quick and useful tips in structured way that will fulfil user
                  needs.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-sm-12 col-xl-7">
            <div class="f-links">
              <ul>
                <li><a routerLink="/categories">Categories</a></li>
                <li>
                  <a routerLink="/whoisusingyouitip">Who is using Youitip?</a>
                </li>
                <li><a routerLink="/reels">Reels</a></li>
                <li><a routerLink="/pricing">Pricing</a></li>
                <li><a routerLink="/aboutus">About us</a></li>
                <li><a routerLink="/faq">How it Works?</a></li>
                <li><a routerLink="/contactus">Contact</a></li>
              </ul>
            </div>
            <div *ngIf="!userData" class="signup-btn">
              <button
                data-toggle="modal"
                [routerLink]="['/register']"
                data-target="#LoginAccount"
                class="btn btn-create-post"
              >
                Sign Up Free
              </button>
              <!-- <button
                data-toggle="modal"
                [routerLink]="['/tandc']"
                data-target="#LoginAccount"
                class="btn btn-create-post"
              >
                Watch Demo
              </button> -->
            </div>
            <div class="social-link">
              <ul class="list-inline d-flex social-list float-right">
                <li style="cursor: pointer">
                  <span
                    ><a
                      href="https://www.facebook.com/profile.php?id=100085314312882"
                      target="_blank"
                    >
                      <i style="color: white" class="fab fa-facebook-f"></i></a
                  ></span>
                </li>
                <li style="cursor: pointer">
                  <span>
                    <a
                      href="http://instagram.com/youitip_02?utm_source=qr"
                      target="_blank"
                    >
                      <i style="color: white" class="fab fa-instagram"></i
                    ></a>
                  </span>
                </li>
                <li>
                  <a
                    href=" https://www.linkedin.com/company/swash-solutions/about/"
                    target="_blank"
                  >
                    <i style="color: white" class="fab fa-linkedin-in"></i
                  ></a>
                </li>
                <!-- <li>
                  <span><i class="fab fa-twitter"></i></span>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
        <div class="row copy-rights">
          <div class="col-md-5 col-12">
            <ul class="list-inline d-flex cpyrgt">
              <li>@2022 - 2023 YoulTip.com All Right Reserved.</li>
            </ul>
          </div>
          <div class="col-md-7 col-12">
            <ul class="list-inline d-flex cpyrgt float-right">
              <li><a [routerLink]="['/tandc']">Terms and conditions</a></li>
              <li><a [routerLink]="['/ppolicy']">Privacy policy</a></li>
              <!-- <li><a [routerLink]="['/aboutus']">About Us</a></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
