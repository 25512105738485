import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-following',
  templateUrl: './following.component.html',
  styleUrls: ['./following.component.scss'],
})
export class FollowingComponent implements OnInit {
  noResultReturned: boolean;
  pageIndex: number;
  followersList: any = [];
  userData: any;
  userEmail: any;
  loadMore: boolean;
  pageSize: any;

  constructor(
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    private titleAndMetaService: TitleAndMetaService
  ) {
    this.pageSize = localStorage.getItem('AppPageSize');
    console.log(this.pageSize);
    this.loadMore = true;
  }

  setLoadMore(tips) {
    console.log(tips.length);
    console.log(this.pageSize);
    this.loadMore = !(tips.length < this.pageSize);
  }

  ngOnInit(): void {
    localStorage.removeItem('youtipfollowingpageIndex');
    localStorage.setItem('youtipfollowingpageIndex', '0');
    this.titleAndMetaService.setTitle('Following Page');
    this.titleAndMetaService.setMetaTag();
    this.userData = JSON.parse(localStorage.getItem('YouITipLoggedInUser'));
    console.log(this.userData);
    this.userEmail = this.userData.LoggedInUser.UserEmail;
    console.log(this.userEmail);
    this.pageIndex = 0;
    this.loadMyFollowing('0', this.userEmail);
  }

  loadMyFollowing(pageIndex, useremailforfollower) {
    this.noResultReturned = true;
    this.tipService
      .getMyFollowing(pageIndex, useremailforfollower)
      .subscribe(async (data) => {
        this.noResultReturned = false;
        console.log(data);

        data.FollowingList.forEach((element) => {
          console.log(element);

          this.followersList.push({
            showFollow: false,
            followButtonText: element.IsFollowing ? 'Following' : 'Follow',
            picUrl: element.FollowUserProfilePic,
            Title: element.Name,
            UniqueCode: element.FirstName,
            customerEmail: element.UserEmail,
            UniqueCodeWithoutDot: element.FirstName,
            UserName: element.FollowUserName,
            TipCount: element.TipCount,
            FollowerCount: element.FollowerCount,
            FollowingCount: element.FollowingCount,
            PartitionKey: element.PartitionKey,
          });
        });
        this.setLoadMore(data.FollowingList);
      });
  }

  loadNextPageTips() {
    console.log('ondemand');
    this.delayedServiceCall();
  }

  onScrollDown() {
    //this.spinner.show();
    console.log('scrolled');
    //this.noResultReturned = true;
    this.delay(2000).then(() => {
      console.log('ondemand');
      this.delayedServiceCall();
    });
  }
  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  delayedServiceCall() {
    var pageIndex = localStorage.getItem('youtipfollowingpageIndex');
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem('youtipfollowingpageIndex', pageIndex);
    console.log(pageIndex);
    this.loadMyFollowing(pageIndex, this.userEmail);
  }

  followUser(selectedUser) {
    console.log(this.userEmail);

    console.log(selectedUser);

    console.log(selectedUser.PartitionKey);

    let followModel = {
      PartitionKey: selectedUser.PartitionKey,
      RowKey: this.userEmail,
    };

    console.log(followModel);

    this.tipService.followUser(followModel).subscribe(async (data) => {
      this.noResultReturned = false;
      console.log(data.Result);
      if (data.Result == 'Following') {
        selectedUser.followButtonText = 'Following';
        selectedUser.FollowerCount++;
      } else {
        selectedUser.followButtonText = 'Follow';
        if (selectedUser.FollowerCount > 0) {
          selectedUser.FollowerCount--;
        }
      }
    });
  }

  viewUserProfile(userEmail) {
    if (this.userData == null) {
      console.log(this.userData);
      this.router.navigate(['/login']);
    } else {
      if (userEmail != this.userEmail) {
        localStorage.setItem('otherUserEmail', userEmail);
        this.router.navigate(['/otheruserprofile']);
      }
    }
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
