<div (contextmenu)="onRightClick($event)" class="common-banner">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
        <h2>Membership Plans that helps you to grow parallelly</h2>
      </div> -->
</div>
<section (contextmenu)="onRightClick($event)" class="common-overlap">
  <div class="common-text">
    <h2>YouITip Going To PayU..</h2>
  </div>
</section>
<section (contextmenu)="onRightClick($event)" class="plan-and-pricing">
  <div class="common-wrap">
    <div class="row">
      <div class="col-12 text-center pricing-heading heading">
        <h4 style="color: green !important" class="blk">
          We are taking you to the PayU payment page
        </h4>
        <br />
        Enjoy your benefits<br />
        <br />
        <br />
        <p>YouITip offers a variety of plans to meet your needs...</p>
      </div>
      <br />
      <div class="col-12">
        <div class="col-12 text-center pricing-heading heading">
          <div class="navbar-header">
            <a
              style="padding-right: 4rem; cursor: pointer"
              class="navbar-brand"
            >
              <div class="navbar-text">
                <img style="width: 20%" src="../../../../assets/img/logo.png" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
