<div (contextmenu)="onRightClick($event)">
  <div>
    <mat-card>
      <mat-card-title>rxjs</mat-card-title>
      <mat-card-content>
        Reactive Extensions Library for JavaScript. This is a rewrite of
        Reactive-Extensions/RxJS and is the latest production-ready version of
        RxJS. This rewrite is meant to have better performance, better
        modularity, better debuggable call stacks, while staying mostly
        backwards compatible, with some breaking changes that reduce the API
        surface.</mat-card-content
      >
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>Firebase - App success made simple</mat-card-title>
      <mat-card-content>
        Firebase provides the tools and infrastructure you need to develop,
        grow, and earn money from your app. This package supports web (browser),
        mobile-web, and server (Node.js) clients.</mat-card-content
      >
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>karma-chrome-launcher</mat-card-title>
      <mat-card-content>
        Launcher for Google Chrome, Google Chrome Canary and Google Chromium.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>karma-coverage</mat-card-title>
      <mat-card-content>
        Generate code coverage using Istanbul.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>jasmine-core</mat-card-title>
      <mat-card-content>
        Jasmine is a Behavior Driven Development testing framework for
        JavaScript. It does not rely on browsers, DOM, or any JavaScript
        framework. Thus it's suited for websites, Node.js projects, or anywhere
        that JavaScript can run.</mat-card-content
      >
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>jasmine-spec-reporter</mat-card-title>
      <mat-card-content>
        Real time console spec reporter for jasmine testing framework.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>Karma</mat-card-title>
      <mat-card-content>
        The main purpose of Karma is to make your test-driven development easy,
        fast, and fun.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>karma-coverage-istanbul-reporter</mat-card-title>
      <mat-card-content>
        The main purpose of Karma is to make your test-driven development easy,
        fast, and fun.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>karma-jasmine-html-reporter</mat-card-title>
      <mat-card-content>
        Reporter that dynamically shows tests results at debug.html page.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>protractor</mat-card-title>
      <mat-card-content>
        Protractor is an end-to-end test framework for Angular and AngularJS
        applications. Protractor is a Node.js program built on top of
        WebDriverJS. Protractor runs tests against your application running in a
        real browser, interacting with it as a user would.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>Firebase - App success made simple</mat-card-title>
      <mat-card-content>
        Firebase provides the tools and infrastructure you need to develop,
        grow, and earn money from your app. This package supports web (browser),
        mobile-web, and server (Node.js) clients.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>rxjs</mat-card-title>
      <mat-card-content>
        Reactive Extensions Library for JavaScript. This is a rewrite of
        Reactive-Extensions/RxJS and is the latest production-ready version of
        RxJS. This rewrite is meant to have better performance, better
        modularity, better debuggable call stacks, while staying mostly
        backwards compatible, with some breaking changes that reduce the API
        surface.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>Firebase - App success made simple</mat-card-title>
      <mat-card-content>
        Firebase provides the tools and infrastructure you need to develop,
        grow, and earn money from your app. This package supports web (browser),
        mobile-web, and server (Node.js) clients.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>karma-chrome-launcher</mat-card-title>
      <mat-card-content>
        Launcher for Google Chrome, Google Chrome Canary and Google Chromium.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>karma-coverage</mat-card-title>
      <mat-card-content>
        Generate code coverage using Istanbul.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title> jasmine-core</mat-card-title>
      <mat-card-content>
        Jasmine is a Behavior Driven Development testing framework for
        JavaScript. It does not rely on browsers, DOM, or any JavaScript
        framework. Thus it's suited for websites, Node.js projects, or anywhere
        that JavaScript can run.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>jasmine-spec-reporter</mat-card-title>
      <mat-card-content>
        Real time console spec reporter for jasmine testing framework.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>karma-coverage-istanbul-reporter</mat-card-title>
      <mat-card-content>
        The main purpose of Karma is to make your test-driven development easy,
        fast, and fun.
      </mat-card-content>
    </mat-card>
  </div>

  <div>
    <mat-card>
      <mat-card-title>karma-jasmine-html-reporter</mat-card-title>
      <mat-card-content>
        Reporter that dynamically shows tests results at debug.html page.
      </mat-card-content>
    </mat-card>
  </div>
</div>
