<div class="common-banner mobileonly" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
      <h2>Membership Plans that helps you to grow parallelly</h2>
    </div> -->
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2>How it Works?</h2>
  </div>
</section>
<section>
  <div class="cat-parent about-category">
    <div class="cat-wrap">
      <p style="color: #fff">Easy steps to know how YouITip works?</p>
      <div class="cat-tile-wrap">
        <div class="row" style="width: 100%">
          <div class="col-sm-4">
            <img src="../../../assets/img/2.jpg" />
            <h6>STEP 1</h6>
            <h5>Register and create your profile</h5>
            <p style="color: #fff">
              Please
              <a
                class="hyperlink"
                style="cursor: pointer"
                (click)="routeToPage('register')"
                >register</a
              >
              on youITip and setup your profile
            </p>
          </div>
          <div class="col-sm-4">
            <img src="../../../assets/img/2.jpg" />
            <h6>STEP 2</h6>
            <h5>Now Create/Post Tips</h5>
            <p style="color: #fff">
              <a
                class="hyperlink"
                style="cursor: pointer"
                (click)="routeToPage('login')"
                >Login</a
              >
              with your profile and click
              <a
                class="hyperlink"
                style="cursor: pointer"
                (click)="routeToPage('createtip')"
              >
                Create/Post Tips</a
              >
              to create tips
            </p>
          </div>
          <div class="col-sm-4">
            <img src="../../../assets/img/2.jpg" />
            <h6>STEP 3</h6>
            <h5>Share your tips and create followers</h5>
            <p style="color: #fff">
              Download YouITip app from playstore and share the tips
              <a
                class="hyperlink"
                href="https://play.google.com/store/apps/details?id=io.ionic.youitip"
                >YouITip</a
              >
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="btn">
        <button>View All Categories</button>
      </div> -->
    </div>
  </div>
  <div class="title h3 text-center">
    <div class="title-wrap">Get Your Answer From Below FAQ</div>
  </div>
  <section (contextmenu)="onRightClick($event)" class="py-5 faq-section">
    <div class="container">
      <div class="col-12 mb-4">
        <div id="accordion" class="accordion">
          <div class="card">
            <div class="card-header" id="headingOne">
              <a
                class="btn btn-link"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <span class="heading-text"> What is YouITip? #1 </span>
                <span> <i class="fas fa-chevron-right"></i> </span>
              </a>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                <ul>
                  <li>
                    YouITip is a platform users use to create and share the
                    short information in the form of tips
                  </li>
                  <li>Users are allowed to create unlimited "Public" tips.</li>
                  <li>
                    To Create "Connection Only" tips users are required to
                    purchase the appropriate plan as explained above
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <a
                class="btn btn-link"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <span class="heading-text"> What is a Tip? #2 </span>
                <span> <i class="fas fa-chevron-right"></i> </span>
              </a>
            </div>

            <div
              id="collapseTwo"
              class="collapse show"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">
                <ul>
                  <li>
                    Tip is short information to address any perticular question.
                  </li>
                  <li>
                    information in the form of Tip can be used by any users to
                    as a solution to any problem
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <a
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <span class="heading-text">YouITip - How to register? #3 </span>
                <span> <i class="fas fa-chevron-right"></i> </span>
              </a>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <div class="card-body">
                <ul>
                  <li>
                    Users can register on YouITip platform using the Signup flow
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFour">
              <a
                class="btn btn-link collapsed"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <span class="heading-text">
                  YouItip - How to create a Tip? #4
                </span>
                <span> <i class="fas fa-chevron-right"></i> </span>
              </a>
            </div>
            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordion"
            >
              <div class="card-body">
                <ul>
                  <li>
                    Users can create "Public" and/or "Connnection tips" on
                    YouITip platform using the Create/Post tip functionality
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
