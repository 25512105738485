import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/sharedService/data.service';
import { NotificationService } from 'src/app/sharedService/notification.service';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  tipDetails: any;
  userData: any;
  showImage: boolean;
  noResultReturned: boolean;
  pageIndex: number;
  comments: any = [];
  maxdesclenght = 500;
  currentdesclenght = 0;
  disableButton: boolean;
  description: string;
  userEmail: any;

  constructor(
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private titleAndMetaService: TitleAndMetaService,
    private cdr: ChangeDetectorRef,
    private dataService: DataService
  ) {
    this.description = '';
  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('YouITipLoggedInUser'));
    console.log(this.userData);

    if (this.userData != null) {
      this.userEmail = this.userData.LoggedInUser.UserEmail;
      console.log(this.userEmail);
    } else {
      this.userEmail = '';
    }
    this.tipDetails = JSON.parse(localStorage.getItem('tipComment'));
    console.log(this.tipDetails);

    if (this.tipDetails.Url == null || this.tipDetails.Url == '') {
      console.log(this.showImage);
      this.showImage = false;
    } else {
      console.log(this.showImage);
      this.showImage = true;
    }
    this.noResultReturned = false;
    console.log(this.tipDetails.Url);
    this.pageIndex = 0;
    this.getComments(this.pageIndex);
  }

  async getComments(pageIndex) {
    this.noResultReturned = true;
    this.tipService
      .getTipComments(pageIndex, this.tipDetails.RowKey)
      .subscribe(async (data) => {
        this.comments = [];
        this.noResultReturned = false;
        console.log(data);
        if (this.userEmail === data.CommentedByUserEmail) {
          data.IsTipOwner = true;
        }
        data.Comments.forEach((element) => {
          this.comments.push(element);
        });
      });
  }

  descChanged() {
    if (this.description.length == this.maxdesclenght) {
      this.description = this.description.substring(0, this.description.length);
    }
    if (this.currentdesclenght <= this.maxdesclenght)
      this.currentdesclenght = this.description.length;
    this.setIsDisable();
  }

  setIsDisable() {
    if (this.currentdesclenght > 0) {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }

  async addComment() {
    console.log(this.description);

    this.noResultReturned = true;
    let commentModel = {
      PartitionKey: this.tipDetails.RowKey,
      Description: this.description,
      UserName: this.userData.LoggedInUser.UserName,
      UserEmail: this.userData.LoggedInUser.UserEmail,
    };

    console.log(commentModel);

    this.tipService.postTipComment(commentModel).subscribe(async (data) => {
      this.noResultReturned = false;
      this.dataService.notifyAboutCommentCount(
        this.tipDetails.RowKey,
        data.TotalCommentCount
      );
      this.cdr.detectChanges();
      this.description = '';
      this.getComments(this.pageIndex);
    });
  }

  edit(comment) {
    if (this.userData != null) {
      console.log(comment);
      localStorage.removeItem('editComment');
      localStorage.setItem('editComment', JSON.stringify(comment));
      this.router.navigate(['/editcomment']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  async delete(comment) {
    if (this.userData != null) {
      console.log(comment);
      console.log(this.userEmail);

      this.tipService
        .deleteTipComment(comment, this.userEmail)
        .subscribe(async (data) => {
          console.log(data);
          if (data.IsCommentDeleted) {
            this.router.navigate(['/tiphome']);
          }
        });
    } else {
      this.router.navigate(['/login']);
    }
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  viewUserProfile(userEmail) {
    if (this.userData == null) {
      this.router.navigate(['/login']);
    } else {
      if (userEmail == this.userEmail) {
        console.log('myprofile');
        this.router.navigate(['myprofile/editprofile']);
      } else {
        localStorage.setItem('otherUserEmail', userEmail);
        console.log('otheruserprofile');
        this.router.navigate(['otheruserprofile/otherusertips']);
      }
    }
  }

  close() {}
}
