import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-otheruserprofile',
  templateUrl: './otheruserprofile.component.html',
  styleUrls: ['./otheruserprofile.component.scss'],
})
export class OtheruserprofileComponent implements OnInit {
  userData: any;
  userEmail: any;
  showImage: boolean;
  lastImage: any;
  myTipsCount: any;
  followerUserCount: any;
  followingUserCount: any;
  pageIndex: number;
  tips: any = [];
  noResultReturned: boolean;
  otherUserEmail: string;
  name: any;
  email: any;
  tipsForFollowersCount: number;

  constructor(
    private tipService: TipService,
    private router: Router,
    public dialog: MatDialog,
    private titleAndMetaService: TitleAndMetaService
  ) {}

  ngOnInit(): void {
    this.titleAndMetaService.setTitle('Other User Profile Page');
    this.titleAndMetaService.setMetaTag();
    this.otherUserEmail = localStorage.getItem('otherUserEmail');
    console.log(this.otherUserEmail);
    this.pageIndex = 0;
    this.userData = JSON.parse(localStorage.getItem('YouITipLoggedInUser'));
    console.log(this.userData);
    this.userEmail = this.userData.LoggedInUser.UserEmail;
    console.log(this.userEmail);
    this.setClass('mpt');
    this.loaduserdetails();
    this.loadTips(this.pageIndex);
    this.getMyCounts();
  }

  async loaduserdetails() {
    this.tipService
      .getUserByUserEmail(this.otherUserEmail)
      .subscribe(async (data) => {
        console.log(data);

        if (data.UserDetails.ProfilePic == null) {
          this.showImage = false;
        } else {
          this.showImage = true;
        }

        this.userData = data.UserDetails;
        this.lastImage = data.UserDetails.ProfilePic;
        this.name = data.UserDetails.Name;
        this.email = data.UserDetails.UserEmail;
        this.myTipsCount = data.UserDetails.TipCount;
        this.followerUserCount = data.UserDetails.FollowerCount;
        this.followingUserCount = data.UserDetails.FollowingCount;
        console.log(this.userData);
      });
  }

  async loadTips(pageIndex) {
    this.noResultReturned = true;
    this.tipService
      .getUserTips(pageIndex, this.otherUserEmail)
      .subscribe(async (data) => {
        this.noResultReturned = false;
        console.log(data);
        data.UserTips.forEach((element) => {
          this.tips.push(element);
        });
      });
  }

  onScrollDown() {
    //this.spinner.show();
    console.log('scrolled');
    //this.noResultReturned = true;
    this.delay(2000).then(() => {
      console.log('ondemand');
      this.delayedServiceCall();
    });
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  delayedServiceCall() {
    var pageIndex = localStorage.getItem('youtiphomepageIndex');
    pageIndex = (parseInt(pageIndex) + 1).toString();
    localStorage.setItem('youtiphomepageIndex', pageIndex);
    console.log(pageIndex);
    this.loadTips(pageIndex);
  }

  getMyCounts() {
    console.log('getmycounts');
    this.tipService.getMyCounts(this.otherUserEmail).subscribe(async (data) => {
      this.myTipsCount = data.MyCounts.TipCount;
      this.followerUserCount = data.MyCounts.FollowerCount;
      this.followingUserCount = data.MyCounts.FollowingCount;
      this.tipsForFollowersCount = data.MyCounts.TipsForFollowersCount;
      console.log(data);
    });
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  mtffClicked: boolean;
  mptClicked: boolean;
  flClicked: boolean;
  frClicked: boolean;

  setClass(bclick) {
    switch (bclick) {
      case 'fr':
        this.mtffClicked = false;
        this.mptClicked = false;
        this.flClicked = false;
        this.frClicked = true;
        break;
      case 'fl':
        this.mtffClicked = false;
        this.mptClicked = false;
        this.flClicked = true;
        this.frClicked = false;
        break;
      case 'mpt':
        this.mtffClicked = false;
        this.mptClicked = true;
        this.flClicked = false;
        this.frClicked = false;
        break;
      case 'mtff':
        this.mtffClicked = true;
        this.mptClicked = false;
        this.flClicked = false;
        this.frClicked = false;
        this.showFollowerTips();
        break;
    }
  }

  showFollowerTips() {
    console.log(this.otherUserEmail);
    localStorage.setItem('OtherUserFollowerTipsEmail', this.otherUserEmail);
    this.router.navigate(['/otheruserprofile/followerTips']);
  }
}
