import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-paymentsuccessful',
  templateUrl: './paymentsuccessful.component.html',
  styleUrls: ['./paymentsuccessful.component.scss'],
})
export class PaymentsuccessfulComponent {
  constructor(private router: Router) {
    this.onScrollDown();
  }

  onScrollDown() {
    //this.spinner.show();
    console.log('scrolled');
    //this.noResultReturned = true;
    this.delay(5000).then(() => {
      console.log('ondemand');
      this.router.navigate(['/tiphome']);
    });
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
  reset() {
    this.router.navigate(['/tiphome']);
  }
}
