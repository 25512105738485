import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppFooter } from 'src/app/models/YouITipAppFooter';

@Injectable({
  providedIn: 'root'
})
export class FooterApiService {

  constructor(private httpClient: HttpClient) { }
  private youitipwebBaseUrl = "";//environment.youitipwebApiUrl;

  getyouitipwebAppFooter(): Observable<AppFooter[]>{
    return this.httpClient.get<AppFooter[]>(this.youitipwebBaseUrl + `api/app-footer`)
    .pipe(
      map((data: AppFooter[]) => data as AppFooter[]),
      catchError(error => throwError('Error while retrieving App-Footer data from YouITip'))
    );
  }
}
