import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss'],
})
export class AboutusComponent implements OnInit {
  constructor(
    private titleAndMetaService: TitleAndMetaService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.titleAndMetaService.setTitle('About Us Page');
    this.titleAndMetaService.setMetaTag();
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  routeToPage(page) {
    this.router.navigate(['/' + page]);
  }
}
