import { TipService } from 'src/app/sharedService/tip.service';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/sharedService/data.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';
import { NotificationService } from 'src/app/sharedService/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  public email: string;
  public password: string;
  userSetting: any;
  YouITipLoggedInUser: any;
  userData: any;
  userName: any;
  Password: any;
  loginFailed: any;
  noResultReturned: boolean;
  token: any;
  constructor(
    private router: Router,
    public formbuilder: UntypedFormBuilder,
    private tipService: TipService,
    private dataService: DataService,
    private notificationService: NotificationService,
    // tslint:disable-next-line:use-lifecycle-interface
    private titleAndMetaService: TitleAndMetaService
  ) {
    this.token = null;
    this.userData = localStorage.removeItem('YouITipLoggedInUser');
  }

  ngOnInit(): void {
    this.titleAndMetaService.setTitle('Login Page');
    this.titleAndMetaService.setMetaTag();
    this.loginForm = this.formbuilder.group({
      password: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  addTokenLog(message: string, token: string | null) {
    console.log(`${message}: ${this.formatToken(token)}`);
  }

  public formatToken(token: string | null) {
    return token !== null ? (this.token = token) : 'null';
  }
  // tslint:disable-next-line:variable-name
  equalto(field_name: string | number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;
      const isValid = control.root.value[field_name] === input;
      if (!isValid) {
        return { equalTo: { isValid } };
      } else {
        return null;
      }
    };
  }

  login() {
    this.noResultReturned = true;
    const loginModel = {
      UserEmail: this.email,
      Password: this.password,
    };
    console.log(loginModel);
    this.tipService.login(loginModel).subscribe(async (data) => {
      console.log(data);
      this.noResultReturned = false;
      if (data.LoggedInUser != null) {
        if (data.LoggedInUser != null && data.LoggedInUser.IsActiveUser) {
          localStorage.setItem('YouITipLoggedInUser', JSON.stringify(data));
          this.userData = data.LoggedInUser;
          this.email = data.LoggedInUser.UserEmail;
          this.dataService.notifyAboutChange();
          this.router.navigate(['/tiphome']);
        }
        if (data.LoggedInUser != null && !data.LoggedInUser.IsActiveUser) {
          this.router.navigate(['/activateaccount']);
        }
      } else {
        this.notificationService.showError(
          'Login failed. Your email or password is invalid. Please try again or reset your password.'
        );
      }
    });
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
