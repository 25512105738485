
<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png">
  <!-- <div class="common-text">
    <h2>Membership Plans that helps you to grow parallelly</h2>
  </div> -->
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2>Privacy Policy</h2>
  </div>
</section>
<section>
<div class="container-fluid" (contextmenu)="onRightClick($event)">
  <div class="container">
    <div class="policy-content">
      <h3 class="blk">Privacy Policy</h3>
      <span>Last updated: 07/07/2022</span>
      <p>
        YOUITIP recognizes that your privacy is very important, and we take it
        seriously. This Privacy Policy (“Privacy Policy”) describes our policies
        and procedures on the collection, use, disclosure, and sharing of your
        personal information when you use the YOUITIP Platform.
      </p>
      <p>
        This Privacy Policy applies to activities by YOUITIP, Inc. and its
        affiliates and subsidiaries (collectively “YOUITIP,” “we” or “us”).
        Capitalized terms that are not defined in this Privacy Policy have the
        meaning given to them in our <a class="hyperlink" routerLink="/tandc">Terms of Service</a
        >.
      </p>
      <h3 class="blk">The Information We Collect</h3>
      <p>
        We collect information directly from individuals, from third parties,
        and automatically through the YOUITIP Platform.
      </p>
      <p>
        <b>Account and Profile Information:</b> When you create an account and
        profile on the YOUITIP Platform, we collect your name, contact
        information (including your telephone number), demographic information,
        and other information you provide, such as topics that you know about or
        find interesting. Your name, photo, and any other information that you
        choose to add to your public-facing profile will be available for
        viewing by the public and other users of the YOUITIP Platform. Once you
        create a profile, others will be able to see in your profile certain
        information about your activity on the YOUITIP Platform, such as the
        tips you post, your followers and who you follow, topics of interest to
        you, the information you list as credentials, and your edits to your
        content. For more information about your choices for publicly displayed
        information, see the section below about Your Choices.
      </p>
      <p>
        <b>Your Content:</b> We collect the information and content that you
        post to the YOUITIP Platform, including your tips in the form of
        questions, answers, photos, and comments. Unless you have posted certain
        content anonymously, your content, date and time stamps, and all
        associated comments are publicly viewable on the YOUITIP Platform, along
        with your name. This also may be indexed by search engines and be
        republished elsewhere on the Internet in accordance with our <a
        class="hyperlink" routerLink="/tandc"
          >Terms of Service</a
        >. For more information about what you can change, see the below section
        on Your Choices.
      </p>
      <p>
        <b>Communications.</b> Your contact information will be used to
        communicate with you (via email, phone, text message, through the
        YOUITIP Platform or otherwise). When you communicate with us (via email,
        phone, text message, through the YOUITIP Platform, or otherwise), we may
        maintain a record of your communication. By disclosing your contact
        information to YOUITIP, you consent to our use of your contact
        information to communicate with you.
      </p>
      <p>
        <b>Integrated Service Provider and Linked Networks.</b> You can connect
        your existing YOUITIP account with certain third-party networks like
        Twitter or Google, for example (each a “Linked Network”). You can also
        elect to sign in or sign up to the YOUITIP Platform through a Linked
        Network. If you elect to sign up through or connect a Linked Network, we
        receive certain profile and account information about you from the
        Linked Network. These Linked Networks may also appear in your profile,
        so that people can find you on these Linked Networks. The specific
        information provided to us by Linked Networks is determined by you and
        such Linked Networks, and may vary by network. In all cases, the
        permissions page for the Linked Network will describe the information
        being shared. You should consult their respective privacy policies for
        information about their practices. You may elect to use information from
        the Linked Network to populate your profile on the YOUITIP Platform or
        to import your contacts to help you find and connect with them on the
        YOUITIP Platform or to invite them to join. For information on your
        choices, including how to disconnect a Linked Network from your YOUITIP
        profile, see the Your Choices section below. You may also elect to
        connect and make and receive payments to and from use through
        third-party networks (“Integrated Service Provider”); if you do so, you
        will be allowing us to pass to and receive from the Integrated Service
        Provider your login information and other user data for payment
        purposes.
      </p>
      <p>
        <b>Automatically Collected Information About Your Activity.</b> We use
        cookies, log files, pixel tags, local storage objects, and other
        tracking technologies to automatically collect information about your
        activities, such as your searches, page views, date and time of your
        visit, and other information about your use of the YOUITIP Platform. We
        also collect information that your computer or mobile device provides to
        us in connection with your use of the YOUITIP Platform such as your
        browser type, type of computer or mobile device, browser language, IP
        address, mobile carrier, unique device identifier, location, and
        requested and referring URLs. We also receive information when you view
        content on or otherwise interact with the YOUITIP Platform, even if you
        have not created an account. For more information, see the “Cookies,
        Pixels and Tracking” section below and our <a class="hyperlink" routerLink="/tandc"
          >Cookie Policy</a
        >.
      </p>
      <p>
        <b>Engagement.</b> We collect browsing information – such as IP address
        and location, date and time stamp, user agent, YOUITIP cookie ID (if
        applicable), URL, unique advertising or content identifiers (if
        applicable) and time zone, and other information about user activities
        on the YOUITIP Platform, as well as on third-party sites and services
        that have embedded our YOUITIP pixels (“Pixels”), widgets, plug-ins,
        buttons, or related services. See the section below about YOUITIP Ads
        and Personalization for more detailed information about how our Pixels
        may be used by publishers or users of our advertising services (“Ad
        Services”) on the YOUITIP Platform to enable personalization, as well as
        your choices related to advertising and personalization. We may also
        receive information about you from third parties, such as other users,
        partners (including ad partners), or our affiliated companies.
      </p>
      <h3 class="blk">How We Use Your Information</h3>
      <p>
        We do not sell your personal information – such as your name and contact
        information – to third parties to use for their own marketing purposes.
        YOUITIP uses the information we collect for the following purposes:
      </p>
      <ul>
        <li>
          Provide our Services. To provide you the services we offer on the
          YOUITIP Platform and make the YOUITIP Platform available to the
          public, communicate with you about your use of the YOUITIP Platform,
          respond to your inquiries, provide troubleshooting, and for other
          customer service purposes.
        </li>
        <li>
          Personalization. To tailor the content and information that we may
          send or display to you in the YOUITIP Platform, to suggest followers
          and content, to help others find your profile, to offer location
          customization, and personalized help and instructions, and to
          otherwise personalize your experiences while using the YOUITIP
          Platform.
        </li>
        <li>
          Advertising. To display interest-based advertising to you in the
          YOUITIP Platform, to improve our advertising and measurement systems
          so we can show you relevant ads, to pre-fill forms in ads, and to
          measure the effectiveness and reach of ads and services. For more
          information, see the Ad Services section below about YOUITIP Ads and
          Personalization.
        </li>
        <li>
          Marketing and Promotions. For marketing and promotional purposes, such
          as to send you news and newsletters, special offers, and promotions,
          or to otherwise contact you about products or information we think may
          interest you, including information about third-party products and
          services.
        </li>
        <li>
          Analytics. To gather metrics to better understand how users access and
          use the YOUITIP Platform; to evaluate and improve the YOUITIP
          Platform, including the Ad Services and personalization, and to
          develop new products and services.  Comply with Law. To comply with
          legal obligations, as part of our general business operations, and for
          other business administration purposes.
        </li>
        <li>
          Prevent Misuse. Where we believe necessary to investigate, prevent or
          take action regarding illegal activities, suspected fraud, situations
          involving potential threats to the safety of any person or violations
          of our Terms of Service or this Privacy Policy.
        </li>
      </ul>

      <h3 class="blk">How We Share Your Information</h3>
      <p>
        We share information as set forth below, and where individuals have
        otherwise consented:
      </p>
      <p>
        <b>Service Providers.</b> We may share your information with third-party
        service providers who use this information to perform services for us,
        such as payment processors, hosting providers, auditors, advisors,
        consultants, customer service and support providers, as well as those
        who assist us in providing the Ad Services.
      </p>
      <p>
        <b>Affiliates.</b> The information collected about you may be accessed
        by or shared with subsidiaries and affiliates of YOUITIP, Inc., whose
        use and disclosure of your personal information is subject to this
        Privacy Policy.>
      </p>
      <p>
        <b>Business Transfers.</b> We may disclose or transfer information,
        including personal information, as part of any merger, sale, and
        transfer of our assets, acquisition or restructuring of all or part of
        our business, bankruptcy, or similar event.
      </p>
      <p>
        <b>Legally Required.</b> We may disclose your information if we are
        required to do so by law.
      </p>
      <p>
        <b>Protection of Rights.</b> We may disclose information where we
        believe it necessary to respond to claims asserted against us or, comply
        with legal process (e.g., subpoenas or warrants), enforce or administer
        our agreements and terms, for fraud prevention, risk assessment,
        investigation, and protect the rights, property or safety of YOUITIP,
        its users, or others.
      </p>
      <p>
        <b>Your Content and Public Information.</b> Your content, including your
        name, profile picture, profile information, and certain associated
        activity information is available to other users of the YOUITIP Platform
        and may also be viewed publicly. Public viewing includes availability to
        non-registered visitors and can occur when users share your content
        across other sites or services. In addition, your content may be indexed
        by search engines. In some cases, we may charge for access to your
        content and public information on the YOUITIP Platform. See the section
        below about Your Choices for information about how you may change how
        certain information is shared or viewed by others.
      </p>
      <p>
        <b>Metrics.</b> We may share with our advertisers or publishers
        aggregate statistics, metrics and other reports about the performance of
        their ads or content in the YOUITIP Platform such as the number of
        unique user views, demographics about the users who saw their ads or
        content, conversion rates, and date and time information. We do not
        share IP addresses or personal information, but certain features may
        allow you to share your personal information with advertisers on our
        platform if you choose to do so. We may also allow our advertisers or
        publishers to use Pixels on the YOUITIP Platform in order to collect
        information about the performance of their ads or content.
      </p>
      <p>
        <b>Anonymized and Aggregated Data.</b> We may share aggregated or
        de-identified information with third parties for research, marketing,
        analytics and other purposes, provided such information does not
        identify a particular individual.
      </p>
      <p>
        <b>As Directed by You.</b> We may also share data as otherwise directed
        by you (for example, to fulfill a transaction).
      </p>
      <h3>Cookies, Pixels and Tracking</h3>
      <p>
        We and our third-party providers use cookies, clear GIFs/pixel tags,
        JavaScript, local storage, log files, and other mechanisms to
        automatically collect and record information about your usage and
        browsing activities on the YOUITIP Platform and across third-party sites
        or online services. We may combine this information with other
        information we collect about users. Below, we provide a brief summary of
        these activities. For more detailed information about these mechanisms
        and how we collect activity information, see our <a class="hyperlink" routerLink="/tandc"
          >Cookie Policy</a
        >.
      </p>
      <ul>
        <li>
          Cookies. These are small files with a unique identifier that are
          transferred to your browser through our websites. They allow us to
          remember users who are logged in, to understand how users navigate
          through and use the YOUITIP Platform, and to display personalized
          content and targeted ads (including on third-party sites and
          applications).
        </li>
        <li>
          Pixels, web beacons, clear GIFs. These are tiny graphics with a unique
          identifier, similar in function to cookies, which we use to track the
          online movements of users of the YOUITIP Platform and the web pages of
          users of our Ad Services, and to personalize content. We also use
          these in our emails to let us know when they have been opened or
          forwarded, so we can gauge the effectiveness of our communications.
        </li>
        <li>
          Analytics Tools. We may use internal and third-party analytics tools,
          including Google Analytics. The third-party analytics companies we
          work with may combine the information collected with other information
          they have independently collected from other websites and/or other
          online products and services. Their collection and use of information
          is subject to their own privacy policies.
        </li>
      </ul>
      <p>
        <b>Do-Not-Track Signals.</b> Please note we do not change system
        behavior within the YOUITIP Platform in response to browser requests not
        to be tracked. You may, however, disable certain tracking by third
        parties as discussed in the YOUITIP Ads and Personalization section
        below. You may also opt out of tracking by YOUITIP Pixels, as described
        below in YOUITIP Ads and Personalization.
      </p>
      <h3 class="blk">YOUITIP Ads and Personalization</h3>
      <p>
        We may display personalized content (including from third-party content
        publishers) and personalized ads (including sponsored content), based on
        information that we have collected via the YOUITIP Platform, and through
        our Pixels, widgets, and buttons embedded on third-party sites. We also
        may report aggregated or de-identified information about the number of
        users that saw a particular ad or content and related audience
        engagement information to users of our Ad Services and to publishers of
        content on the YOUITIP Platform. See YOUITIP’s <a class="hyperlink" routerLink="/ppolicy"
          >Pixel Privacy Terms</a
        > for more information about how our Pixels are used, and also see the
        Your Choices section below for information about opting out of tracking
        by our Pixels.
      </p>
      <p>
        Advertisers who use our Ad Services may also provide us with information
        as part of their ad campaigns, including customer information (e.g.,
        email addresses, phone numbers, or other contact information,
        demographic or interest data) in order to create custom audiences for
        personalizing their ad campaigns or for measuring the effectiveness of
        their ads; we only use this information to facilitate the particular
        advertiser’s campaign (including ad metrics and reporting to that
        advertiser); and we do not disclose this information to third parties
        (other than our service providers) unless required by law. We also do
        not disclose to the advertisers who use our Ad Services the names or
        contact information of their customers that were successfully reached as
        part of such campaigns without those customers’ consent.
      </p>
      <h3 class="blk">Third-Party Ads on YOUITIP</h3>
      <p>
        As described in our <a class="hyperlink" routerLink="/tandc">Cookie Policy</a>, we may
        also work with third parties such as network advertisers to serve ads on
        the YOUITIP Platform and on third-party websites or other media (e.g.,
        social networking platforms), such as Google AdSense
        <!--(more info <a href="#"
          >here</a
        >)-->
         and Facebook Audience Network
        <!--(more info <a href="#">here</a>).-->
        These third parties may use cookies, JavaScript, web beacons (including
        clear GIFs), Flash LSOs and other tracking technologies to measure the
        effectiveness of their ads and to personalize advertising content to
        you.
      </p>
      <p>
        In addition to opting out of tracking across sites by our Pixels (see
        Your Choices section below), you also may opt out of much interest-based
        advertising on third-party sites and through third-party ad networks
        (including Facebook Audience Network and Google AdSense). See Your
        Choices section below for more information about opting out of third-
        party ad networks.
      </p>
      <h3 class="blk">How We Protect Your Information</h3>
      <p>
        The security of your information is important to us. YOUITIP has
        implemented safeguards to protect the information we collect. However,
        no website or Internet transmission is completely secure. We urge you to
        take steps to keep your personal information safe, such as choosing a
        strong password and keeping it private, as well as logging out of your
        user account, and closing your web browser when finished using the
        YOUITIP Platform on a shared or unsecured device.
      </p>
      <h3 class="blk">Access and Amend Your Information</h3>
      <p>
        You may update or correct your account information at any time by
        logging in to your account. You may also make a number of other
        adjustments to settings or the display of information about you as
        described in more detail in the following section about Your Choices.
      </p>
      <h3 class="blk">Your Choices</h3>
      <p>
        You may, of course, decline to submit information through the YOUITIP
        Platform, in which case we may not be able to provide certain services
        to you. You may also control the types of notifications and
        communications we send, limit the information shared within the YOUITIP
        Platform about you, and otherwise amend certain privacy settings. Here
        is some further information about some of your choices:
      </p>
      <p>
        <b>Anonymous Posts.</b> You may post certain content anonymously,
        including tips in the form of questions and answers. In such event, your
        name is not displayed along with the content, and YOUITIP does not
        associate such content with your user ID and other profile data.
      </p>
      <p>
        <b>Your Content.</b> You may edit or delete the answers that you post at
        any time. Any questions you have posted may remain on the YOUITIP
        Platform. Any deleted content will be removed from third-party sites
        from which it has been shared via YOUITIP’s standard sharing features;
        however we have no control over deletions or changes to your content if
        it has been shared manually by others. When you make edits to your
        content, other users will be able to see the history of those edits in
        your profile activity and on content edit logs. Any contents posted by
        you if marked as inappropriate the content will be removed from the
        YOUITIP along with the history. Your account will be blocked and you
        will not be allowed to use any of the YOUITIP services if the any of the
        content posted by you is marked as inappropriate 3 times or immediately
        subjected to right and choice of YOUITIP.
      </p>
      <p>
        <b>Adult Content.</b> In your profile’s privacy settings, you can elect
        whether to receive adult content.
      </p>
      <p>
        <b>Emails and Notifications.</b> When you join the YOUITIP Platform by
        signing up for an account or creating a profile, as part of the service,
        you will receive email digests containing content from the YOUITIP
        Platform that we believe may match your interests. As permitted by law,
        you are also opting to receive notices, including by emails and text
        messages, about other content from the YOUITIP Platform that may be of
        interest to you, such as Spaces that you follow or that match your
        interests. In addition, users may be able to send communications and
        invites to their imported contacts through their YOUITIP account, by
        email, text, or phone. You can manage all your email, text messages, and
        notice preferences in your account profile settings, under your Emails
        and Notifications settings, but note that you cannot opt out of
        receiving certain administrative or legal notices from us.
      </p>

      <p>
        If you opt out of receiving emails or other notifications about
        recommendations or other information we think may interest you, we may
        still send you transactional emails and text messages about your account
        or any services you have requested or received from us.
      </p>
      <p>
        Third parties may comment on your postings within the YOUITIP Platform.
        In your profile, under your Privacy Settings, you can adjust whether to
        allow people to comment on your answers and posts. You can also adjust
        permissions about who you allow to send you messages on the YOUITIP
        Platform.
      </p>
      <p>
        <b>Followers.</b> You can block the ability of another YOUITIP user to
        follow you by selecting the setting for this in the other user’s
        profile. You can change whether or not you follow other users.
      </p>
      <p>
        <b>Profile discoverability.</b> You can adjust your settings to block
        others from discovering your account by searching for your name or using
        your contact information, including when another user imports their
        contacts from a third-party service.
      </p>
      <p>
        <b>Topics and Spaces.</b> You can change topics and Spaces that you are
        affiliated within your profile.
      </p>
      <p>
        <b>Credentials.</b> You can change your credentials that are displayed
        in your profile or in connection with a specific answer.
      </p>
      <p>
        <b>Indexed Search.</b> In your privacy settings, you can control whether
        your profile and name is indexed by search engines. Changes to privacy
        settings may only apply on a going-forward basis; for example, your name
        (e.g., answers and profile) that has already been indexed by search
        engines may remain indexed for a period of time even after you have
        turned off indexing, as implementing this change is outside of our
        control.
      </p>
      <p>
        <b>Deleted or Deactivated Account.</b> If you choose “Delete Account” in
        your profile’s “Privacy Settings,” then all of your content will be
        removed from public visibility on the YOUITIP Platform, and it may not
        be restored by us, even if you change your mind. If you choose
        “Deactivate Account,” then you will no longer receive any communications
        from us, and users will not be able to interact with you; however your
        content will remain on the YOUITIP Platform. Once you deactivate your
        account, you can reactivate it any time by choosing to log in.
      </p>
      <p>
        <b>Pixels.</b> To opt out of tracking via the YOUITIP Pixels, please
        visit this page. Opting out of Pixels tracking may impact our ability to
        personalize ads and content tailored to your interests. This setting is
        tied to your cookies, and, unless you are logged in to the YOUITIP
        Platform, will only be effective on the browser for which you have
        performed the opt-out.
      </p>
      <p>
        <b>Linked Networks.</b> You may connect or disconnect your Linked
        Networks, such as Google, through the Account Settings tab in your
        profile settings, and you may access, amend and delete much of your
        profile information through your profile settings. Once you disconnect a
        Linked Network, we will not receive information from that Linked Network
        going forward unless you choose to reconnect it. You may also control
        whether the Linked Network is visible in your profile and, if you enable
        contact imports, how you message your contacts to find and connect with
        you on the YOUITIP Platform.
      </p>
      <h3 class="blk">Transferring Your Data</h3>
      <p>
        YOUITIP is headquartered in India, and has operations, entities and
        service providers in India and throughout the world. As such, we and our
        service providers may transfer your personal information to, or access
        it in, jurisdictions (including India) that may not provide equivalent
        levels of data protection as your home jurisdiction. We will take steps
        to ensure that your personal information receives an adequate level of
        protection in the jurisdictions in which we process it, including
        through appropriate written data processing terms and/or data transfer
        agreements.
      </p>
      <h3 class="blk">Children’s Privacy</h3>
      <p>
        We do not knowingly collect or solicit personal information from anyone
        under the age of 13 (or under the age of 18 for anyone living in India)
        or knowingly allow such persons to register. If we become aware that we
        have collected personal information from a child under the relevant age
        without parental consent, we take steps to delete that information.
      </p>
      <h3 class="blk">Links to Other Websites</h3>
      <p>
        The YOUITIP Platform may contain links to third-party sites or online
        services. We are not responsible for the practices of such third
        parties, whose information practices are subject to their own policies
        and procedures, not to this Privacy Policy.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions or grievances about our practices or this
        Privacy Policy, please contact us at <a class="hyperlink" routerLink="/ppolicy"
          >privacy@YOUITIP.com</a
        >
      </p>
      <h3 class="blk">Changes to Our Privacy Policy</h3>
      <p>
        If we change our privacy policies and procedures, we will post those
        changes on this page. If we make any changes to this Privacy Policy that
        materially change how we treat your personal information, we will
        endeavor to provide you with reasonable notice of such changes, such as
        via prominent notice in the YOUITIP Platform or to your email address of
        record, and where required by law, we will obtain your consent or give
        you the opportunity to opt out of such changes. By continuing to use the
        YOUITIP Platform and YOUITIP’s services without consenting to the
        changes or opting out, it will be deemed that you have consented to such
        changes by your conduct.
      </p>
      <h3 class="blk">Country-Specific Terms for India</h3>
      <p>
        India law defines ‘sensitive personal information’ to mean information
        relating to: (i) passwords; (ii) financial information such as bank
        account/credit card/debit card details; (iii) physical, physiological
        and mental health condition; (iv) sexual orientation; (v) medical
        records and history; (vi) biometric information; (vii) any details
        relating to the above clauses as required for providing you with access
        to the YOUITIP Platform; and (viii) any of the information received
        under above clauses for processing, stored or processed under lawful
        contract or otherwise. Unless otherwise specified in the Privacy Policy,
        ‘personal information’ will include ‘sensitive personal information’ as
        well. By using the YOUITIP Platform and YOUITIP’s services, you will be
        deemed to have consented to YOUITIP’s collection, disclosure and
        transfer of your personal information. By way of illustration, you will
        have provided us such consent by your conduct if you choose to share
        your personal information while using the YOUITIP Platform, such as by
        including sensitive personal information in Your Content or by providing
        sensitive personal information in your registration information.
      </p>
      <p>
        If an individual in India deletes or deactivates their account, then
        their personal information will be deleted as per requirements under
        applicable law.
      </p>
      <p>
        While transferring personal information collected from individuals in
        India, we will ensure that it is transferred to entities that offer at
        least the same levels of data protection as adhered to by us.
      </p>
    </div>
  </div>
</div>
</section>
