<div class="Login" (contextmenu)="onRightClick($event)" style="top: 20vh">
  <div class="wrapper fadeInDown">
    <div id="formContent" style="margin-top: 170px">
      <!-- <div class="fadeIn first">
        <P style="color: black; font-size: 20px; font-weight: bold"> </P>
      </div> -->
      <!-- <div *ngIf="comment">
        <input
          type="text"
          (input)="descChanged()"
          class="form-control"
          [(ngModel)]="comment.Description"
          name="comment"
          class="fadeIn second"
          placeholder="Write  a comment"
        />

        <br />
      </div> -->
      <div class="botom-wrap">
        <p
          style="
            color: #b34bc9;
            font-size: 14px !important;
            padding: 7px 20px 0px;
            margin: 0px;
            font-weight: 500;
          "
        >
          Remember to keep comments respectful and to follow our Community
          Guidlines.
        </p>
        <div class="textareawrap">
          <textarea
            (input)="descChanged()"
            class="form-control"
            [(ngModel)]="description"
            name="description"
            class="fadeIn second"
            placeholder="Enter your comment"
          >
          </textarea>
          <mat-hint class="font16 textcount" align="end"
            >{{ description.length }} / 500</mat-hint
          >
          <button
            (click)="addTip()"
            style="cursor: pointer; border: none; background-color:transparent;color:#b34bc9;padding:0px;box-shadow:none;"
            class="material-icons"
          >
            send
          </button>
        </div>
      </div>
      <!-- <button
        style="margin-bottom: 5px; margin-top: 5px"
        (click)="addTip()"
        type="button"
        class="btn btn-primary"
        color="primary"
      >
        Submit
      </button> -->
    </div>
  </div>
</div>
