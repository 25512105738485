<!-- <header>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <a class="navbar-brand" href="#">Navbar</a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Contact</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Login</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Signup</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header> -->

<div class="right-panel">
<section class="section-50" (contextmenu)="onRightClick($event)">
  <div class="container">
    <!-- <h3 class="m-b-50 heading-line blk">
      Notifications <i class="fa fa-bell text-muted"></i>
    </h3> -->

    <div
      *ngFor="let notification of notificationList"
      class="notification-ui_dd-content"
    >
      <div class="notification-list notification-list--unread">
        <div class="notification-list_content">
          <div class="notification-list_img">
            <img
              style="border: 1.5px solid lightgray"
              src="{{ notification.NotifierUserProfilePic }}"
              alt="user"
            />
          </div>
          <div class="notification-list_detail">
            <p>
              <b>{{ notification.Body }}</b> reacted to your post
            </p>
            <p class="text-muted">{{ notification.Message }}</p>
            <!-- <p class="text-muted"><small>10 mins ago</small></p> -->
          </div>
        </div>
        <div class="notification-list_feature-img">
          <p class="text-muted">
            <small>{{ notification.ReceivedDate | dateAgo }}</small>
          </p>
        </div>
      </div>
      <!-- <div class="notification-list notification-list--unread">
              <div class="notification-list_content">
                  <div class="notification-list_img">
                      <img src="https://i.imgur.com/w4Mp4ny.jpg" alt="user">
                  </div>
                  <div class="notification-list_detail">
                      <p><b>Richard Miles</b> liked your post</p>
                      <p class="text-muted">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde, dolorem.</p>
                      <p class="text-muted"><small>10 mins ago</small></p>
                  </div>
              </div>
              <div class="notification-list_feature-img">
                  <img src="https://i.imgur.com/AbZqFnR.jpg" alt="Feature image">
              </div>
          </div> -->
    </div>

    <div class="text-center">
      <a href="#!" class="dark-link">Load more activity</a>
    </div>
  </div>
</section>
</div>
