<div class="common-banner">
  <img src="../../../assets/img/aboutus-banner.png" />
</div>
<section class="common-overlap">
  <div class="common-text">
    <h2>Reward</h2>
  </div>
</section>
<section>
  <div class="container wrap-box" (contextmenu)="onRightClick($event)">
    <div class="col-sm-4 box-parent" *ngFor="let reward of rewards">
      <div
        class="box"
        [ngClass]="{
          'is-flipped': reward.IsRewardFlipped
        }"
      >
        <div class="award-wrap">
          <div class="award-image-wrap">
            <div class="image-bg">
              <img src="../../../assets/img/reward-bg2.png" />
            </div>
          </div>
        </div>
        <div class="box-text">
          <h2 class="blk">{{ reward.Offer }}</h2>
          <a
            *ngIf="reward.SiteUrl"
            style="cursor: pointer"
            href="{{ reward.SiteUrl }}"
            target="_blank"
          >
            Check offer</a
          >
        </div>
        <div class="flip-box">
          <div class="details-point">
            <h4 class="blk">Offer Details</h4>
            <ul *ngIf="reward">
              <li *ngFor="let tc of reward.TcList">{{ tc }}</li>
            </ul>
          </div>
          <div class="terms-condition">
            <h4>Terms and condition apply</h4>
          </div>
        </div>
        <div
          class="flip-icon"
          *ngIf="!reward.IsRewardFlipped"
          (click)="flipCard(reward)"
        >
          <i class="fa-solid fa-rotate" area-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
</section>
