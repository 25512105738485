import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { youitipwebAppHeader } from 'src/app/models/YouITipAppHeader';

@Injectable({
  providedIn: 'root',
})
export class HeaderApiService {
  constructor(private httpClient: HttpClient) {}
  private youitipwebBaseUrl = ''; //environment.youitipwebApiUrl;

  getyouitipwebAppHeader(): Observable<youitipwebAppHeader[]> {
    // tslint:disable-next-line: no-debugger
    // debugger;
    return this.httpClient
      .get<youitipwebAppHeader[]>(this.youitipwebBaseUrl + `api/app-header`)
      .pipe(
        map((data: youitipwebAppHeader[]) => data as youitipwebAppHeader[])
        //catchError(error => throwError('Error while retrieving App-Header data from YouITip'))
      );
  }
}
