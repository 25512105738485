<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
    <h2>Membership Plans that helps you to grow parallelly</h2>
  </div> -->
</div>
<section class="common-overlap">
  <div class="common-text">
    <h2>Change Password</h2>
  </div>
</section>
<section>
    <div class="Login">
      <div align="center">
        <div id="formContent" class="formContent col-12">
          <div class="fadeIn first">
            <!-- <P>Change Password</P> -->
          </div>

          <div class="fadeIn second">
            <!-- <h6 style="color: black; font-weight: bold">
            Please check your email {{ EmailAddress }} for activation code and
            enter below
          </h6> -->
          </div>

          <form
            [formGroup]="activatePasswordForm"
            novalidate
            *ngIf="!isUserExist"
          >


            <div class="form-group">
              <input
                (change)="IsUserEmailTaken()"
                type="email"
                class="form-control"
                formControlName="activatePasswordEmail"
                (focusout)="IsUserEmailTaken()"
                [(ngModel)]="activatePasswordEmail"
                name="login"
                required
                class="fadeIn second"
                placeholder="Enter email and click get code"
              />
              <p _ngcontent-sbi-c137="" style="font-size:16px !important;">
                Please reset your password using the code sent to your email.
              </p>
              <div
                *ngIf="
                  activatePasswordForm.controls['activatePasswordEmail']
                    .invalid &&
                  (activatePasswordForm.controls['activatePasswordEmail'].dirty ||
                    activatePasswordForm.controls['activatePasswordEmail']
                      .touched)
                "
                class="alert alert-danger"
              >
                Email Address is required.
              </div>
              <button
                type="submit"
                style="margin-bottom: 20px"
                class="btn btn-primary btn-lg fadeIn fourth"
              >
                Get Code
              </button>
            </div>
          </form>

          <form [formGroup]="changePasswordForm" novalidate *ngIf="isUserExist">
            <input
              type="email"
              class="form-control"
              formControlName="email"
              [(ngModel)]="email"
              [ngClass]="{
                'is-invalid':
                  changePasswordForm.get('email').touched &&
                  changePasswordForm.get('email').invalid
              }"
              name="login"
              required
              class="fadeIn second"
              placeholder="Email Address"
            />
            <div
              *ngIf="
                changePasswordForm.controls['email'].invalid &&
                (changePasswordForm.controls['email'].dirty ||
                  changePasswordForm.controls['email'].touched)
              "
              class="alert alert-danger"
            >
              Email Address is required.
            </div>

            <input
              type="text"
              class="form-control"
              formControlName="activationCode"
              [(ngModel)]="activationCode"
              [ngClass]="{
                'is-invalid':
                  changePasswordForm.get('activationCode').touched &&
                  changePasswordForm.get('activationCode').invalid
              }"
              name="login"
              required
              class="fadeIn second"
              placeholder="Activation Code"
            />
            <div
              *ngIf="
                changePasswordForm.controls['activationCode'].invalid &&
                (changePasswordForm.controls['activationCode'].dirty ||
                  changePasswordForm.controls['activationCode'].touched)
              "
              class="alert alert-danger"
            >
              Activation Code is required.
            </div>

            <div class="form-group">
              <input
                type="password"
                class="form-control"
                formControlName="password"
                [(ngModel)]="password"
                [ngClass]="{
                  'is-invalid':
                    changePasswordForm.get('password') &&
                    changePasswordForm.get('password')
                }"
                name="login"
                required
                class="fadeIn second"
                placeholder="Password"
              />
            </div>

            <div class="form-group">
              <div
                *ngIf="
                  changePasswordForm.controls['password'].invalid &&
                  (changePasswordForm.controls['password'].dirty ||
                    changePasswordForm.controls['password'].touched)
                "
                class="alert alert-danger"
              >
                Password is required.
              </div>
              <label
                class="text-danger"
                *ngIf="
                  changePasswordForm.controls['password'].hasError('hasNumber')
                "
              >
                Must have at least 1 number!
              </label>
              <label
                class="text-danger"
                *ngIf="
                  changePasswordForm.controls['password'].hasError('minlength')
                "
              >
                Must be at least 8 characters!
              </label>

              <label
                class="text-danger"
                *ngIf="
                  changePasswordForm.controls['password'].hasError(
                    'hasCapitalCase'
                  )
                "
              >
                Must contain at least 1 in Capital Case!
              </label>

              <label
                class="text-danger"
                *ngIf="
                  changePasswordForm.controls['password'].hasError('hasSmallCase')
                "
              >
                Must contain at least 1 Letter in Small Case!
              </label>

              <label
                class="text-danger"
                *ngIf="
                  changePasswordForm.controls['password'].hasError(
                    'hasSpecialCharacters'
                  )
                "
              >
                Must contain at least 1 Special Character!
              </label>
            </div>

            <div class="form-group">
              <input
                type="password"
                class="form-control"
                formControlName="confirmPassword"
                [(ngModel)]="confirmPassword"
                [ngClass]="{
                  'is-invalid':
                    changePasswordForm.get('confirmPassword') &&
                    changePasswordForm.get('confirmPassword')
                }"
                name="login"
                required
                class="fadeIn second"
                placeholder="Confirm Password"
              />
            </div>

            <div class="form-group">
              <div
                *ngIf="
                  changePasswordForm.controls['confirmPassword'].invalid &&
                  (changePasswordForm.controls['confirmPassword'].dirty ||
                    changePasswordForm.controls['confirmPassword'].touched)
                "
                class="alert alert-danger"
              >
                Confirm your password.
              </div>
            </div>
          </form>

          <br />
          <button
            type="submit"
            [disabled]="isEmailTaken"
            (click)="changePassword()"
            style="margin-bottom: 20px"
            class="btn btn-primary btn-lg fadeIn fourth"
          >
            Change Password
          </button>
        </div>
      </div>
    </div>
  </section>

