<div
  class="container-fluid mobileoutoheight"
  (contextmenu)="onRightClick($event)"
>
  <div class="main-body">
    <div class="row gutters-sm left-stickypanel">
      <div
        *ngIf="tips?.length > 0"
        class="scroll user-pro"
        class="card-handler"
        class="right-panel"
      >
        <div class="row">
          <div class="card-handler">
            <div
              class="scroll user-pro"
              class="card-handler"
              class="container"
              class="title h3 text-center"
            >
              <!-- <h4 class="blk">{{ categoryName | titlecase }}</h4> -->

              <div
                *ngIf="tips?.length > 0"
                class="scroll user-pro"
                class="card-handler"
                class="container mobileoutoheight newdesigntile"
              >
                <div class="row homeTip">
                  <div class="card-handler">
                    <div class="card-wrap" *ngFor="let tip of tips">
                      <div class="image-wrap">
                        <img
                          *ngIf="tip.ShowImage && tip.Url"
                          (click)="viewTip(tip)"
                          class="img-fluid"
                          src="{{ tip.Url }}"
                          alt="Card image cap"
                          style="cursor: pointer"
                        />

                        <img
                          *ngIf="!tip.ShowImage && tip.Url"
                          (click)="viewTip(tip)"
                          class="img-fluid"
                          src="{{ tip.VideoPosterUrl }}"
                          alt="Card image cap"
                          style="cursor: pointer"
                        />
                        <div
                          class="play-btn"
                          *ngIf="!tip.ShowImage && tip.Url"
                          (click)="viewTip(tip)"
                        >
                          <i
                            class="fas fa-play text-muted p-md-1 my-1 me-2"
                            style="
                              color: #ec4a89 !important;
                              font-size: 25px;
                              cursor: pointer;
                            "
                          ></i>
                        </div>
                        <h1
                          (click)="viewTip(tip)"
                          (click)="viewTip(tip)"
                          *ngIf="!tip.Url"
                        >
                          {{ tip.Title | titlecase }}
                        </h1>
                        <div class="cat-name">
                          <span
                            (click)="tipByCategory(tip)"
                            style="
                              height: 17px;
                              margin-top: 6px;
                              cursor: pointer;
                            "
                            class="badge badge-primary"
                          >
                            #{{ tip.CategoryName }}
                          </span>
                        </div>
                      </div>
                      <div class="text-wrap">
                        <h1
                          (click)="viewTip(tip)"
                          (click)="viewTip(tip)"
                          *ngIf="tip.Url"
                        >
                          {{ tip.Title | titlecase }}
                        </h1>
                        <div class="user-group usergroupdesktop">
                          <i
                            (click)="viewUserProfile(tip.UserEmail)"
                            *ngIf="!tip.ProfilePic"
                            class="rounded-circle"
                            width="150"
                            style="
                              font-size: 20px;
                              color: gray !important;
                              cursor: pointer;
                              padding-left: 20px;
                            "
                            class="fa fa-user"
                            aria-hidden="true"
                          ></i>
                          <img
                            *ngIf="tip.ProfilePic"
                            (click)="viewMobileUserProfile(tip.UserEmail)"
                            src="{{ tip.ProfilePic }}"
                            style="
                              height: 30px;
                              width: 30px;
                              border-radius: 50%;
                              margin-left: 30px;
                              cursor: pointer;
                              pointer-events: all;
                            "
                          />
                          <h4
                            style="cursor: pointer"
                            (click)="viewUserProfile(tip.UserEmail)"
                          >
                            {{ tip.UserName | titlecase }}
                          </h4>
                          <div class="days">
                            <p>
                              <span class="txt"
                                ><i class="fas fa-clock" title="I like it"></i
                                >{{ tip.Timestamp | dateAgo }}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div class="user-group usergroupmobile">
                          <i
                            (click)="viewUserProfile(tip.UserEmail)"
                            *ngIf="!tip.ProfilePic"
                            class="rounded-circle"
                            width="150"
                            style="
                              font-size: 20px;
                              color: gray !important;
                              cursor: pointer;
                            "
                            class="fa fa-user"
                            aria-hidden="true"
                          ></i>
                          <img
                            *ngIf="tip.ProfilePic"
                            (click)="viewMobileUserProfile(tip.UserEmail)"
                            src="{{ tip.ProfilePic }}"
                            style="
                              height: 30px;
                              width: 30px;
                              border-radius: 50%;
                              margin-left: 30px;
                              cursor: pointer;
                              pointer-events: all;
                            "
                          />
                          <h4
                            style="cursor: pointer"
                            (click)="viewMobileUserProfile(tip.UserEmail)"
                          >
                            {{ tip.UserName }}
                          </h4>
                        </div>
                        <p (click)="viewTip(tip)">{{ tip.Description }}</p>

                        <div class="bottom-fix">
                          <div
                            *ngIf="
                              tip.CommentCount > 0 ||
                              tip.ShareCount > 0 ||
                              tip.LikeCount > 0 ||
                              tip.SaveCount > 0
                            "
                            class="countdetails-page"
                          >
                            <div
                              *ngIf="tip.LikeCount > 0"
                              class="col-md-5 pr-0"
                            >
                              <ul class="combo-icon">
                                <li><i class="fa fa-thumbs-up"></i></li>
                                <li><i class="fa fa-heart"></i></li>
                                <li>
                                  <i
                                    class="fa fa-lightbulb"
                                    area-hidden="true"
                                  ></i>
                                </li>
                              </ul>
                              <p *ngIf="tip.LikeCount > 0">
                                {{ tip.LikeCount }} like
                              </p>
                            </div>
                            <div class="col-md-3 p-0">
                              <p *ngIf="tip.CommentCount > 0">
                                {{ tip.CommentCount }} comments
                              </p>
                            </div>
                            <div class="col-md-2 p-0">
                              <p *ngIf="tip.SaveCount > 0">
                                {{ tip.SaveCount }} Save
                              </p>
                            </div>
                            <div class="col-md-2 p-0">
                              <p *ngIf="tip.ShareCount > 0">
                                {{ tip.ShareCount }} Share
                              </p>
                            </div>
                          </div>
                          <div class="social">
                            <ul>
                              <li>
                                <a class="btn-floating btn-fb hvr-bounce-in"
                                  ><i
                                    [ngClass]="{
                                      likeColorClass: tip.IsTipLiked
                                    }"
                                    style="cursor: pointer"
                                    (click)="likeTip(tip)"
                                    class="fas fa-heart text-muted p-md-1 my-1 me-2"
                                    data-mdb-toggle="tooltip"
                                    data-mdb-placement="top"
                                    title="Like the tip"
                                  ></i
                                ></a>
                              </li>
                              <li>
                                <!-- <span
                   ><span class="count">{{ tip.ViewCount }}</span> Views</span
                 > -->
                                <a
                                  (click)="viewTip(tip)"
                                  class="btn-floating btn-tw hvr-bounce-in"
                                  ><i
                                    class="fas fa-eye text-muted p-md-1 my-1 me-0"
                                    data-mdb-toggle="tooltip"
                                    data-mdb-placement="top"
                                    title="View Tip Details"
                                  ></i
                                ></a>
                              </li>
                              <li>
                                <!-- <span> {{ tip.IsTipSaved ? "Saved" : "Save" }}</span> -->
                                <a
                                  (click)="saveTip(tip)"
                                  class="btn-floating btn-dribbble hvr-bounce-in"
                                  ><i
                                    [ngClass]="{
                                      savedColorClass: tip.IsTipSaved
                                    }"
                                    style="cursor: pointer"
                                    class="fas fa-bookmark text-muted p-md-1 my-1 me-0"
                                    data-mdb-toggle="tooltip"
                                    data-mdb-placement="top"
                                    title="Save tip to your profile"
                                  ></i
                                ></a>
                              </li>
                              <li>
                                <!-- <span
                   ><span class="count">{{ tip.CommentCount }} </span>
                   Comments</span
                 > -->
                                <a class="btn-floating btn-com hvr-bounce-in"
                                  ><i
                                    (click)="tipComment(tip)"
                                    style="cursor: pointer"
                                    class="fa fa-commenting-o text-muted p-md-1 my-1 me-0"
                                    data-mdb-toggle="tooltip"
                                    data-mdb-placement="top"
                                  ></i
                                ></a>
                              </li>
                            </ul>
                            <div>
                              <a (click)="viewTip(tip)" style="cursor: pointer"
                                >See Details</a
                              >
                            </div>
                          </div>
                          <div class="button-wrap">
                            <div class="cat-name">
                              <span
                                (click)="tipByCategory(tip)"
                                style="
                                  height: 17px;
                                  margin-top: 6px;
                                  cursor: pointer;
                                "
                                class="badge badge-primary"
                              >
                                #{{ tip.CategoryName }}
                              </span>
                            </div>
                            <div class="days">
                              <p>
                                <span class="txt"
                                  >{{ tip.Timestamp | dateAgo }}
                                  <i class="fas fa-clock" title="I like it"></i
                                ></span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="tip.AnimateTip" class="anisecondheart">
                          <div class="bubbling-heart">
                            <div><i class="fa fa-heart fa-5x"></i></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="load-more">
              <button *ngIf="loadMore" (click)="loadMoreTips()">
                Load more
              </button>
              <h5 class="Usermsg" *ngIf="!loadMore">Thats it for now :)</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="noResultReturned" class="img-div-left">
  <img
    alt="loading spinner"
    onContextMenu="return false;"
    class="spinner-global img-fluid"
    style="width: 100px; height: 100px; display: block; margin: 0px auto"
    src="../../../../assets/img/Spinner.gif"
  />
</div>
