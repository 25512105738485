import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class TitleAndMetaService {
  constructor(
    public http: HttpClient,
    private meta: Meta,
    private title: Title
  ) {}

  setTitle(title) {
    this.title.setTitle(title);
  }

  setMetaTag() {
    this.meta.addTags([
      {
        name: 'title',
        content: 'YouITip - Effective Tips for all generation in the world',
      },
      {
        name: 'description',
        content:
          'YouITip is a platform to create tips in any category of your choice',
      },
    ]);
  }
}
