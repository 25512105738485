<div
  class="container-fluid"
  style="margin-top: 90px"
  (contextmenu)="onRightClick($event)"
>
  <div class="main-body">
    <div class="row gutters-sm left-stickypanel">
      <div class="mb-3 left-panel">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
              <div class="button-link">
                <div class="btn-grp">
                  <button
                    [ngClass]="{
                      active: mpClicked
                    }"
                    (click)="setClass('mp')"
                    *ngIf="userData"
                    routerLink="./editprofile"
                    mat-menu-item
                  >
                    <i class="fa fa-user" aria-hidden="true"></i>My Profile
                  </button>
                  <button
                    [ngClass]="{
                      active: mtClicked
                    }"
                    (click)="setClass('mt')"
                    *ngIf="userData"
                    routerLink="./userprofile"
                    mat-menu-item
                  >
                    <i class="fa-solid fa-lightbulb"></i>My Tips
                  </button>
                  <button
                    [ngClass]="{
                      active: mrClicked
                    }"
                    (click)="setClass('mr')"
                    *ngIf="userData"
                    routerLink="./rewards"
                    mat-menu-item
                  >
                    <i class="fa-solid fa-trophy" aria-hidden="true"></i>My
                    Rewards
                  </button>
                  <button
                    [ngClass]="{
                      active: msClicked
                    }"
                    *ngIf="userData"
                    (click)="savedTips()"
                    mat-menu-item
                  >
                    <i class="fa fa-bookmark" aria-hidden="true"></i>My Saved
                    Tips
                  </button>
                  <button
                    [ngClass]="{
                      active: mpnlClicked
                    }"
                    *ngIf="userData"
                    (click)="myPlan()"
                    mat-menu-item
                  >
                    <i class="fa fa-bookmark" aria-hidden="true"></i>My YouITip
                    Plan
                  </button>
                </div>
                <div class="btn-grp">
                  <button
                    [ngClass]="{
                      active: foltClicked
                    }"
                    (click)="setClass('folt')"
                    *ngIf="userData"
                    routerLink="./followerstips"
                    mat-menu-item
                  >
                    <i class="fa fa-user" aria-hidden="true"></i>Tips for
                    Followers
                  </button>
                  <button
                    [ngClass]="{
                      active: cpClicked
                    }"
                    *ngIf="userData"
                    (click)="changePassword()"
                    mat-menu-item
                  >
                    <i class="fa fa-key" aria-hidden="true"></i>Change Password
                  </button>
                  <button
                    [ngClass]="{
                      active: ntClicked
                    }"
                    *ngIf="userData"
                    (click)="ntclicked()"
                    routerLink="./notifications"
                    mat-menu-item
                  >
                    <!-- <i
                      _ngcontent-lsw-c156=""
                      class="fa fa-bell text-muted"
                      style="color: #b34bc9 !important"
                    ></i> -->
                    <i class="fa fa-bell" aria-hidden="true"></i>
                    Notifications
                  </button>
                </div>
                <div class="btn-grp followers">
                  <button
                    [ngClass]="{
                      active: flClicked
                    }"
                    (click)="openDialog()"
                    class="btn btn-link"
                  >
                    Followers
                  </button>
                  <button class="badge kc_fab_main_btn">
                    {{ followerUserCount }}
                  </button>
                </div>
                <div class="btn-grp followers">
                  <button
                    [ngClass]="{
                      active: frClicked
                    }"
                    (click)="OpenSecondDialog()"
                    class="btn btn-link"
                  >
                    Following
                  </button>
                  <button class="badge kc_fab_main_btn">
                    {{ followingUserCount }}
                  </button>
                </div>
                <div class="btn-grp followers">
                  <button class="btn btn-link">Tips</button>
                  <button class="badge kc_fab_main_btn">
                    {{ myTipsCount }}
                  </button>
                </div>
                <div class="btn-grp">
                  <button *ngIf="userData" (click)="logout()" mat-menu-item>
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
