<div class="Login" (contextmenu)="onRightClick($event)">
  <div align="center">
    <div id="formContent">
      <div class="fadeIn first">
        <P style="color: black; font-size: 20px; font-weight: bold"
          >Account Activation</P
        >
      </div>

      <div class="fadeIn first">
        <h6 style="color: black !important; font-weight: bold">
          Please check your email {{ email }} for activation code and enter
          below
        </h6>
      </div>
      <form [formGroup]="activateForm" novalidate>
        <input
          type="text"
          class="form-control"
          formControlName="activationCode"
          [(ngModel)]="activationCode"
          [ngClass]="{
            'is-invalid':
              activateForm.get('activationCode').touched &&
              activateForm.get('activationCode').invalid
          }"
          name="login"
          required
          class="fadeIn second"
          placeholder="Activation Code"
        />
        <div
          *ngIf="
            activateForm.controls['activationCode'].invalid &&
            (activateForm.controls['activationCode'].dirty ||
              activateForm.controls['activationCode'].touched)
          "
          class="alert alert-danger"
        >
          Activation Code is required.
        </div>

        <button
          type="submit"
          (click)="activateAccount()"
          style="width: 120px; margin-bottom: 5px"
          class="btn btn-primary btn-lg fadeIn fourth"
        >
          Activate
        </button>
      </form>
      <br />
      <br />
      <br />
      <br />
      <div class="fadeIn first">
        <h4 style="color: black !important; font-weight: bold">
          Request new code to activate account
        </h4>
      </div>
      <form [formGroup]="getCodeForm" novalidate>
        <input
          type="text"
          class="form-control"
          formControlName="getCodeEmailAddress"
          [(ngModel)]="getCodeEmailAddress"
          [ngClass]="{
            'is-invalid':
              getCodeForm.get('getCodeEmailAddress').touched &&
              getCodeForm.get('getCodeEmailAddress').invalid
          }"
          name="login"
          required
          class="fadeIn second"
          placeholder="Email Address"
        />
        <div
          *ngIf="
            getCodeForm.controls['getCodeEmailAddress'].invalid &&
            (getCodeForm.controls['getCodeEmailAddress'].dirty ||
              getCodeForm.controls['getCodeEmailAddress'].touched)
          "
          class="alert alert-danger"
        >
          Email Address is required.
        </div>

        <button
          type="submit"
          (click)="getCode()"
          [disabled]="!getCodeForm.valid"
          style="width: 120px; margin-bottom: 5px"
          class="btn btn-primary btn-lg fadeIn fourth"
        >
          Get Code
        </button>
      </form>
    </div>
  </div>
</div>
<div *ngIf="noResultReturned" class="img-div-left">
  <img
    alt="loading spinner"
    onContextMenu="return false;"
    class="spinner-global img-fluid"
    src="assets/images/Spinner.gif"
  />
</div>
