import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-refundpolicy',
  templateUrl: './refundpolicy.component.html',
  styleUrls: ['./refundpolicy.component.scss']
})
export class RefundpolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

}
