<div
  class="container-fluid"
  style="margin-top: 90px"
  (contextmenu)="onRightClick($event)"
>
  <div class="main-body">
    <div class="row gutters-sm left-stickypanel">
      <div class="mb-3 left-panel">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
              <div *ngIf="userData" class="user">
                <img
                  *ngIf="showImage"
                  [src]="lastImage"
                  alt="Admin"
                  class="rounded-circle"
                  width="150"
                />
                <i
                  *ngIf="!showImage"
                  class="rounded-circle"
                  width="150"
                  style="font-size: 32px"
                  class="fa fa-user"
                  aria-hidden="true"
                ></i>
                <h3>{{ name }}</h3>
                <!-- <h4>{{ otherUserEmail }}</h4> -->
              </div>
              <div class="button-link">
                <div class="btn-grp followers">
                  <button
                    routerLink="./otherusertips"
                    [ngClass]="{
                      active: mptClicked
                    }"
                    (click)="setClass('mpt')"
                    class="btn btn-link"
                  >
                    Tips
                  </button>
                  <button class="badge kc_fab_main_btn">
                    {{ myTipsCount }}
                  </button>
                </div>
                <div class="btn-grp followers">
                  <button
                    [ngClass]="{
                      active: mtffClicked
                    }"
                    (click)="setClass('mtff')"
                    class="btn btn-link"
                  >
                    Tips For Followers
                  </button>
                  <button class="badge kc_fab_main_btn">
                    {{ tipsForFollowersCount }}
                  </button>
                </div>
                <div class="btn-grp followers">
                  <button
                    [ngClass]="{
                      active: frClicked
                    }"
                    (click)="setClass('fr')"
                    class="btn btn-link"
                  >
                    Followers
                  </button>
                  <button class="badge kc_fab_main_btn">
                    {{ followerUserCount }}
                  </button>
                </div>
                <div class="btn-grp followers">
                  <button
                    [ngClass]="{
                      active: flClicked
                    }"
                    (click)="setClass('fl')"
                    class="btn btn-link"
                  >
                    Following
                  </button>
                  <button class="badge kc_fab_main_btn">
                    {{ followingUserCount }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
