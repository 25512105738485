<div style="display: flex" (contextmenu)="onRightClick($event)">
  <div style="width: 50%; margin-top: 100px; margin-left: 80px">
    <mat-selection-list style="display: inline-grid" *ngIf="categories">
      <div><h2>Categories</h2></div>
      <mat-checkbox
        [checked]="category.value"
        [(ngModel)]="category.isChecked"
        [value]="category"
        (change)="changeSelection()"
        style="width: 60%"
        *ngFor="let category of categories"
      >
        {{ category.Name }}<br />
      </mat-checkbox>
    </mat-selection-list>
  </div>
  <div style="width: 50%; margin-top: 100px">
    <mat-selection-list style="display: inline-grid" *ngIf="users">
      <div><h2>Tip Authors</h2></div>
      <mat-checkbox
        [checked]="user.value"
        [(ngModel)]="user.isChecked"
        [value]="user"
        [isMultiple]="false"
        (change)="changeUserSelection()"
        style="width: 60%"
        *ngFor="let user of users"
      >
        {{ user.Name }}
      </mat-checkbox>
    </mat-selection-list>
  </div>

  <div style="width: 50%; margin-top: 100px; margin-left: 80px">
    <div style="margin-top: 50%">
      <button
        primary
        (click)="addFilter()"
        style="cursor: pointer"
        class="btn btn-primary"
      >
        Done
      </button>

      <span
        (click)="clearFilter()"
        style="cursor: pointer; margin-left: 20px"
        class="btn btn-primary"
      >
        Clear
      </span>
    </div>
  </div>
</div>
