import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomValidators } from 'src/app/customValidators';
import { TipService } from 'src/app/sharedService/tip.service';
import { TitleAndMetaService } from 'src/app/sharedService/TitleAndMetaService';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  phone: string;
  address: string;
  public registerForm: UntypedFormGroup;
  registeredUser: any;
  userName: any;
  userEmailTaken: boolean;
  isCapsLockPressed: any;
  isUserNameTaken: boolean;
  isTermsAndConditionChecked: boolean;
  noResultReturned: boolean;
  showImage: boolean;
  lastImage: string;
  pwdIcon = 'eye-outline';
  showPwd = false;
  isEmailValid: boolean;
  isPhoneValid: boolean;
  tandc: any;
  isSetDisabled: boolean;
  fileData: File;
  token: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private tipService: TipService,
    private router: Router,
    private titleAndMetaService: TitleAndMetaService,
    private http: HttpClient
  ) {
    this.token = null;
    this.setDisabled();
  }

  ngOnInit(): void {
    this.titleAndMetaService.setTitle('Register Page');
    this.titleAndMetaService.setMetaTag();
    this.registerForm = this.formBuilder.group({
      name: new UntypedFormControl('', Validators.required),
      userName: new UntypedFormControl('', Validators.required),
      phone: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        this.invalidMobile('phone'),
      ]),
      password: new UntypedFormControl(
        '',
        Validators.compose([
          Validators.required,
          // 2. check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          // 3. check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          // 4. check whether the entered password has a lower-case letter
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          CustomValidators.patternValidator(
            /[!,@,#,$,%,^,&,*,-,_,:,;,",',<,>,`,~,{,},/,?,|,(,)]/,
            { hasSpecialCharacters: true }
          ),
          Validators.minLength(8),
        ])
      ),
      confirmPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        this.equalto('password'),
      ]),
      email: new UntypedFormControl('', [
        Validators.required,
        this.validateEmail('email'),
        Validators.email,
      ]),
    });
  }

  equalto(field_name): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      // eslint-disable-next-line prefer-const
      let input = control.value;

      // eslint-disable-next-line prefer-const
      let isValid = control.root.value[field_name] == input;
      // eslint-disable-next-line curly
      if (!isValid) return { equalTo: { isValid } };
      // eslint-disable-next-line curly
      else return null;
    };
  }

  validateEmail(field_name: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const input = control.value;

      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var result = re.test(input);
      console.log(result);
      const isValid = (this.isEmailValid = result);
      if (!isValid) {
        return { equalTo: { isValid } };
      } else {
        return null;
      }
    };
  }

  invalidMobile(field_name): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let input = control.value;

      let regExp = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;

      var result = regExp.test(control.value);
      const isValid = (this.isPhoneValid = result);
      if (!isValid) return { invalidMobile: { isValid } };
      else return null;
    };
  }

  IsUserEmailTaken() {
    if (this.email != null && this.email != '' && this.email != undefined) {
      this.noResultReturned = true;
      console.log(this.email);
      this.userEmailTaken = false;
      this.tipService.isUserEmailTaken(this.email).subscribe((data) => {
        this.noResultReturned = false;
        console.log(data);
        if (data.IsUserEmailTaken) {
          this.userEmailTaken = true;
        } else {
          this.userEmailTaken = false;
        }
        this.setDisabled();
      });
    }
  }

  tandcChecked() {
    this.setDisabled();
  }

  IsUserNameTaken() {
    if (
      this.userName != null &&
      this.userName != '' &&
      this.userName != undefined
    ) {
      console.log(this.userName);
      this.isUserNameTaken = false;
      this.tipService.isUserNameTaken(this.userName).subscribe((data) => {
        console.log(data);
        if (data.IsUserNameTaken) {
          this.isUserNameTaken = true;
        } else {
          this.isUserNameTaken = false;
        }

        this.setDisabled();
      });
    }
  }

  setDisabled() {
    if (
      this.isTermsAndConditionChecked &&
      !this.isUserNameTaken &&
      !this.userEmailTaken
    ) {
      this.isSetDisabled = false;
    } else {
      this.isSetDisabled = true;
    }
  }

  private catchErrors() {
    return async (res: Response) => {
      alert(res.status);

      if (
        res.status === 401 ||
        res.status === 403 ||
        res.status === 501 ||
        res.status === 500
      ) {
        //handle authorization errors
        //in this example I am navigating to login.
      }
      return Observable.throw(res);
    };
  }

  validateUserName(field_name): ValidatorFn {
    if (!this.isCapsLockPressed) {
      return (control: AbstractControl): { [key: string]: any } => {
        let input = control.value;
        var count = 0;
        let regExp = /^[a-zA-Z0-9-_.]+$/;
        let isValid = regExp.test(input);
        let alphabets = 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z';
        let upperCasealphabets =
          'A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z';

        if (isValid) {
          if (
            input.length == 1 &&
            !alphabets.toLowerCase().includes(input.toLowerCase())
          ) {
            isValid = false;
          }
        }

        if (isValid) {
          if (input != '' && input != undefined) {
            count = (input.match(/\./g) || []).length;
            if (count > 1) {
              isValid = false;
            }
          }
        }

        if (isValid) {
          if (input != '' && input != undefined) {
            var lastLetter = input.charAt(input.length - 1);
            if (lastLetter == '.') {
              isValid = false;
            }
          }

          input = input.toLowerCase();
        }

        if (isValid) {
          var numbers = /^[0-9]+$/;
          if (input.match(numbers)) {
            isValid = false;
          }
        }
        this.userName = this.userName.toLocaleLowerCase();
        if (!isValid) return { validateUserName: { isValid } };
        else return null;
      };
    } else {
      this.isCapsLockPressed = false;
    }
  }

  register() {
    if (!this.isSetDisabled) {
      this.noResultReturned = true;
      let customerModel = {
        Name: this.name,
        UserName: this.userName,
        EmailAddress: this.email,
        Password: this.password,
        ConfirmPassword: this.confirmPassword,
        ProfilePic: this.lastImage,
        AgreedToTermsAndConditions: this.isTermsAndConditionChecked,
      };

      console.log(customerModel);

      this.tipService.registerUser(customerModel).subscribe(async (data) => {
        this.noResultReturned = false;
        console.log(data);
        if (data != null) {
          localStorage.setItem('activateAccountEmail', this.email);
          this.router.navigate(['/activateaccount']);
        } else {
          console.log('Not able register user');
        }
      });
    }
  }

  addTokenLog(message: string, token: string | null) {
    console.log(`${message}: ${this.formatToken(token)}`);
  }

  public formatToken(token: string | null) {
    if (token !== null) {
      this.token = token;
      return this.token;
    } else return 'null';
  }

  selectFiles(event) {
    this.lastImage = null;
    if (event.target.files) {
      this.noResultReturned = true;
      //this.spinner.show();
      this.fileData = <File>event.target.files[0];
      const formData = new FormData();
      formData.append('file', this.fileData);
      var showImageOrVideoUrl = '';

      showImageOrVideoUrl =
        'https://swashmarket.azurewebsites.net/v1/ImageController/PostUploadImage';

      this.http.post(showImageOrVideoUrl, formData).subscribe((res) => {
        this.noResultReturned = false;
        var mediaUploadStatus = res as any;
        console.log(mediaUploadStatus);
        this.noResultReturned = false;
        this.lastImage =
          'https://swashstorage.blob.core.windows.net/products/' +
          mediaUploadStatus.MediaUploadStatus.FileName;
      });
    }
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }
}
