<div (contextmenu)="onRightClick($event)" class="common-banner">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
    <h2>Membership Plans that helps you to grow parallelly</h2>
  </div> -->
</div>
<section (contextmenu)="onRightClick($event)" class="common-overlap">
  <div class="common-text">
    <h2>YouITip Pricing Plan</h2>
  </div>
</section>
<section (contextmenu)="onRightClick($event)" class="plan-and-pricing">
  <div class="common-wrap">
    <div class="row">
      <div class="col-12 text-center pricing-heading heading">
        <h4 class="blk">Flexible Plan and Pricing</h4>
        <p>YouITip offers a variety of plans to meet your needs.</p>
      </div>
      <br />
      <div class="col-12" >
        <div style="float: left;"
          *ngFor="let pricingPlan of pricingPlans"
          class="col-md-4 col-12 mb-4"
        >
          <div class="card">
            <div class="card-header">
              <div class="price">
                <span class="price-amout">{{ pricingPlan.PlanPrice }}</span
                ><span class="month">/ {{ pricingPlan.PlanDuration }}</span>
              </div>
              <h4 class="plan-name blk">{{ pricingPlan.PlanName }}</h4>
              <p>
                Please don't wait
                <a class="hyperlink" routerLink="/register">Register</a> and
                Enjoy the
                <b> The {{ pricingPlan.PlanName }} Plan</b>
              </p>
            </div>
            <div class="card-body">
              <ul class="description">
                <li
                  *ngFor="let pricingPlantcs of pricingPlan.PricingPlanTcList"
                  class=""
                >
                  <span
                    ><i class="fa fa-check" aria-hidden="true"></i>
                    {{ pricingPlantcs }}
                  </span>
                </li>
              </ul>
            </div>
            <div class="card-footer">
              <button
                (click)="goToPlan(pricingPlan)"
                routerLink="/createtip"
                class="btn btn-danger btn-block"
              >
                Get Started Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <button (click)="goToCreateTip()" class="btn btn-transparent">
          Have a plan please -
          <a class="hyperlink" style="color: blue" routerLink="/createtip"
            >Get Started</a
          >
        </button>
      </div>
    </div>
  </div>
</section>
<section class="mb-5 pt-5 faq-section">
  <div class="container">
    <div class="col-12 text-center">
      <h2 class="h2-head blk">Frequently Asked Question</h2>
      <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate, cupiditate et. Iusto rerum perferendis ab cum velit laboriosam sed nulla!</p> -->
    </div>
    <div class="col-12 mb-4">
      <div id="accordion" class="accordion">
        <div class="card">
          <div class="card-header" id="headingOne">
            <a
              class="btn btn-link"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <span class="heading-text">How to add tips and earn money ?</span>
              <span> <i class="fas fa-chevron-right"></i> </span>
            </a>
          </div>
          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div class="card-body">
              <ul>
                <li>
                  To add tips users are required to register onto the YouITip
                  platform
                </li>
                <li>
                  On YouITip platform users can create public tips and
                  connection only tips
                </li>
                <li>
                  Users will earn money one the user has followers as users
                  followers are allowed to see the connection only tips
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <a
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              <span class="heading-text"
                >Do I need to send the account confirmation to admin?</span
              >
              <span> <i class="fas fa-chevron-right"></i> </span>
            </a>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div class="card-body">
              <ul>
                <li>Account confirmation is an automated process on YouITip</li>
                <li>
                  Once user registers onto the YouITip platform users are sent
                  the account verification email with verification code
                </li>
                <li>
                  To activate account users are required to use the verification
                  email sent on their email and activate account onto YouITup
                  platform
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <a
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <span class="heading-text"
                >What will be the max amount to be earned?</span
              >
              <span> <i class="fas fa-chevron-right"></i> </span>
            </a>
          </div>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordion"
          >
            <div class="card-body">
              <ul>
                <li>
                  YouITip has no limit on the maximum amount the user can earn.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFour">
            <a
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <span class="heading-text"
                >How do I de-register from my account?</span
              >
              <span> <i class="fas fa-chevron-right"></i> </span>
            </a>
          </div>
          <div
            id="collapseFour"
            class="collapse"
            aria-labelledby="headingFour"
            data-parent="#accordion"
          >
            <div class="card-body">
              <ul>
                <li>
                  Users can register on YouITip platform using the Signup flow
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFive">
            <a
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <span class="heading-text"
                >Does any fee will be deducted from my earning?</span
              >
              <span> <i class="fas fa-chevron-right"></i> </span>
            </a>
          </div>
          <div
            id="collapseFive"
            class="collapse"
            aria-labelledby="headingFive"
            data-parent="#accordion"
          >
            <div class="card-body">
              <ul>
                <li>
                  On YouITip platform users are paid 80% from there earning and
                  20% are deducted as a platform maintainance fees
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingSix">
            <a
              class="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <span class="heading-text"
                >How many tips do I will post in a month?</span
              >
              <span> <i class="fas fa-chevron-right"></i> </span>
            </a>
          </div>
          <div
            id="collapseSix"
            class="collapse"
            aria-labelledby="headingSix"
            data-parent="#accordion"
          >
            <div class="card-body">
              <ul>
                <li>
                  On YouITip platform users can create "Public" tips and
                  "Connection Only" tips
                </li>
                <li>Users are allowed to create unlimited "Public" tips.</li>
                <li>
                  To Create "Connection Only" tips users are required to
                  purchase the appropriate plan as explained above
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center">
      <a (click)="gotofaq()" class="btn btn-transparent"> View All FAQs</a>
    </div>
  </div>
</section>
