import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class TipService {
  private baseAzureUrl = 'https://swashmarket.azurewebsites.net/';
  //private baseAzureUrl = 'http://localhost:53481/';
  private postUserLoggedInUrl = 'v1/TipUserController/loginUser';
  private postContactUserUrl =
    'v1/TipAdministrativeController/postCreateConactUsRequest';
  private postUserRegisterUserUrl = 'v1/TipUserController/registerUser';
  private postRewardFlippedUrl = 'v1/TipRewardsController/postRewardFlipped';
  private postupdateUserRegisterUserUrl = 'v1/TipUserController/updateUser';
  private checkoutPageUrl = 'v1/TipAdministrativeController/Checkout';
  private postResetPasswordUrl = 'v1/TipUserController/resetUserPassword';
  private postActivateAccountUrl = 'v1/TipUserController/activateUserAccount';
  private postTipUrl = 'v1/TipController/postTip';
  private userEmailExists = 'v1/TipUserController/getIsUserEmailTaken';
  private userNameTaken = 'v1/TipUserController/getIsUserNameTaken';
  private sendActivationCOdeEmailURL =
    'v1/TipUserController/sendActivationCodeEmail';
  private getPasswordChangeUrl =
    'v1/TipUserController/getPasswordChangeActivationCodeEmail';
  private getCategoriesUrl = 'v1/TipCategoryController/getTipCategories';
  private getTipsUrl = 'v1/TipController/getTips';
  private getMostViewedTipsUrl = 'v1/TipController/getMostViewedTips';
  private getMostLikedTipsUrl = 'v1/TipController/getMostLikedTips';
  private getMostSharedTipsUrl = 'v1/TipController/getMostSharedTips';
  private getUserTipsUrl = 'v1/TipController/getUserTips';
  private getOtherUserTipsUrl = 'v1/TipController/getOtherUserTips';
  private getCategoryTipsUrl = 'v1/TipController/getCategoryTips';
  private getTotalTipsCountUrl = 'v1/TipController/getTotalTipsCount';
  private getlikeTipUrl = 'v1/TipController/likeTip';
  private getviewTipUrl = 'v1/TipController/getTipViews';
  private postTipCommentUrl = 'v1/TipController/postTipComment';
  private getTipCommentUrl = 'v1/TipController/getTipComment';
  private postTipDeleteCommentUrl = 'v1/TipController/postTipCommentToDelete';
  private getUserByUserEmailUrl = 'v1/TipUserController/getUserByEmailAddress';
  private isUserFollowUserUrl = 'v1/TipFollowUserController/getIsUserFollowed';
  private followUserUrl = 'v1/TipFollowUserController/followUser';
  private getMyFollowersUrl = 'v1/TipFollowUserController/getMyFollowersUrl';
  private getMyFollowingUrl = 'v1/TipFollowUserController/getMyFollowingUrl';
  private getusersUrl = 'v1/TipUserController/getUsers';
  private getFilteredTipsUrl = 'v1/TipUserController/getFilteredTips';
  private getMyCountsUrl = 'v1/TipController/getMyCounts';
  private getTipByTipIdUrl = 'v1/TipController/getTipByTipId';
  private searchTipsUrl = 'v1/TipSearchController/searchTips';
  private searchCatoryUrl = 'v1/TipCategoryController/searchTipCategories';
  private saveTipUrl = 'v1/TipSaveController/postSaveTip';
  private getSavedTipsUrl = 'v1/TipSaveController/getSavedTips';
  private getFollowersTipsUrl = 'v1/TipController/getFollowersTips';
  private getUnseenUserNotificationCountUrl =
    'v1/NotificationController/getUserNotifications';
  private getUserNotificationsUrl =
    'v1/NotificationController/getUserNotifications';
  private getVideoTipsUrl = 'v1/TipController/getVideoTips';
  private getOneMinVideosUrl = 'v1/TipController/getOneMinVideos';
  private getMyRewardsUrl = 'v1/TipRewardsController/getMyRewards';
  private getpayupaymentUrl = 'v1/getpayu-payment/details';
  private getDashboardDetailsUrl =
    'v1/TipAdministrativeController/getDashboardDetails';
  private getMetadataRecordsUrl = 'v1/TipController/getMetadataRecords';
  private searchCategoryTipsUrl = 'v1/TipSearchController/searchCategoryTips';
  private getMyPlanURL = 'v1/TipUserController/getMyPlan';
  private getPricingPlansUrl = 'v1/TipAdministrativeController/getPricingPlans';

  constructor(public http: HttpClient) {}

  searchCategoryTips(pageIndex, categoryid, searchTipText, userEmail) {
    console.log(pageIndex);
    console.log('pageIndex');

    console.log(searchTipText);
    console.log('searchTipText');

    let header = new HttpHeaders();
    //    console.log(searchTipText + pageIndex);
    header = header.set('searchString', searchTipText + '');
    header = header.set('pageIndex', pageIndex + '');
    header = header.set('categoryid', categoryid + '');
    header = header.set('userEmail', userEmail + '');
    return this.http.get<any>(this.baseAzureUrl + this.searchCategoryTipsUrl, {
      headers: header,
    });
  }

  getpayupaymentdetails(userEmail, planAmount, planName) {
    let header = new HttpHeaders();
    header = header.set('userEmail', userEmail);
    header = header.set('planAmount', planAmount + '');
    header = header.set('planName', planName + '');
    return this.http.get<any>(this.baseAzureUrl + this.getpayupaymentUrl, {
      headers: header,
    });
  }

  getMyRewards(userEmail) {
    let header = new HttpHeaders();
    header = header.set('UserEmail', userEmail);
    return this.http.get<any>(this.baseAzureUrl + this.getMyRewardsUrl, {
      headers: header,
    });
  }

  getDashboardDetails(userEmail) {
    let header = new HttpHeaders();
    header = header.set('UserEmail', userEmail);
    return this.http.get<any>(this.baseAzureUrl + this.getDashboardDetailsUrl, {
      headers: header,
    });
  }

  getMetadataRecords(userEmail): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('userEmail', userEmail + '');
    return this.http.get<any>(this.baseAzureUrl + this.getMetadataRecordsUrl, {
      headers: header,
    });
  }

  searchTips(pageIndex, searchTipText) {
    let header = new HttpHeaders();
    console.log(searchTipText + pageIndex);
    header = header.set('searchString', searchTipText);
    header = header.set('pageIndex', pageIndex + '');
    return this.http.get<any>(this.baseAzureUrl + this.searchTipsUrl, {
      headers: header,
    });
  }

  searchCategories(pageIndex, searchTipText) {
    console.log(pageIndex);
    console.log('pageIndex');

    console.log(searchTipText);
    console.log('searchTipText');

    let header = new HttpHeaders();
    //    console.log(searchTipText + pageIndex);
    header = header.set('searchString', searchTipText + '');
    header = header.set('pageIndex', pageIndex + '');
    return this.http.get<any>(this.baseAzureUrl + this.searchCatoryUrl, {
      headers: header,
    });
  }

  login(loginModel) {
    console.log('Inservice');
    console.log(loginModel);
    const header = new HttpHeaders();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.baseAzureUrl + this.postUserLoggedInUrl,
      loginModel,
      {
        headers: header,
      }
    );
  }

  isUserFollowUser(userEmail, YouITipLoggedInUserEmail) {
    let header = new HttpHeaders();
    header = header.set('followUserEmail', userEmail);
    header = header.set('followingUserEmail', YouITipLoggedInUserEmail);
    return this.http.get<any>(this.baseAzureUrl + this.isUserFollowUserUrl, {
      headers: header,
    });
  }

  getMyCounts(userEmail) {
    let header = new HttpHeaders();
    header = header.set('UserEmail', userEmail);
    return this.http.get<any>(this.baseAzureUrl + this.getMyCountsUrl, {
      headers: header,
    });
  }

  getTipByTipId(tipId, userEmail) {
    let header = new HttpHeaders();
    header = header.set('tipId', tipId);
    header = header.set('userEmail', userEmail);
    return this.http.get<any>(this.baseAzureUrl + this.getTipByTipIdUrl, {
      headers: header,
    });
  }

  getMySavedTips(pageIndex, userEmail) {
    console.log(userEmail);
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    header = header.set('userEmail', userEmail + '');
    return this.http.get<any>(this.baseAzureUrl + this.getSavedTipsUrl, {
      headers: header,
    });
  }

  getFollowersTips(pageIndex, userEmail) {
    console.log(userEmail);
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    header = header.set('userEmail', userEmail + '');
    return this.http.get<any>(this.baseAzureUrl + this.getFollowersTipsUrl, {
      headers: header,
    });
  }

  getUserNotifications(userEmail) {
    console.log(userEmail);
    let header = new HttpHeaders();
    header = header.set('userEmail', userEmail);
    // header = header.set('pageIndex', pageIndex + '');
    return this.http.get<any>(
      this.baseAzureUrl + this.getUserNotificationsUrl,
      {
        headers: header,
      }
    );
  }

  getMyFollowers(pageIndex, useremailforfollower) {
    let header = new HttpHeaders();
    header = header.set('followUserEmail', useremailforfollower);
    header = header.set('pageIndex', pageIndex + '');
    return this.http.get<any>(this.baseAzureUrl + this.getMyFollowersUrl, {
      headers: header,
    });
  }

  getMyFollowing(pageIndex, useremailforfollower) {
    let header = new HttpHeaders();
    header = header.set('followUserEmail', useremailforfollower);
    header = header.set('pageIndex', pageIndex + '');
    return this.http.get<any>(this.baseAzureUrl + this.getMyFollowingUrl, {
      headers: header,
    });
  }

  followUser(followModel) {
    const header = new HttpHeaders();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.baseAzureUrl + this.followUserUrl,
      followModel,
      {
        headers: header,
      }
    );
  }

  contactUs(contactUsModel) {
    console.log(contactUsModel);

    const header = new HttpHeaders();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.baseAzureUrl + this.postContactUserUrl,
      contactUsModel,
      {
        headers: header,
      }
    );
  }

  postRewardFlipped(userModel, userEmail) {
    console.log(userModel);
    let header = new HttpHeaders();
    header = header.append('Accept', 'application/json');
    header = header.append('Content-Type', 'application/json');
    header = header.set('userEmail', userEmail);
    return this.http.post<any>(
      this.baseAzureUrl + this.postRewardFlippedUrl,
      userModel,
      {
        headers: header,
      }
    );
  }

  registerUser(userModel) {
    console.log(userModel);
    let customerModel = {
      Name: userModel.Name,
      UserName: userModel.UserName,
      UserEmail: userModel.EmailAddress,
      Password: userModel.Password,
      ConfirmPassword: userModel.ConfirmPassword,
      ProfilePic: userModel.ProfilePic,
      AgreedToTermsAndConditions: userModel.AgreedToTermsAndConditions,
    };

    const header = new HttpHeaders();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.baseAzureUrl + this.postUserRegisterUserUrl,
      customerModel,
      {
        headers: header,
      }
    );
  }

  updateUser(userModel) {
    console.log(userModel);

    const header = new HttpHeaders();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.baseAzureUrl + this.postupdateUserRegisterUserUrl,
      userModel,
      {
        headers: header,
      }
    );
  }
  tipCheckout(checkoutModel, emailAddress) {
    console.log(checkoutModel);

    const header = new HttpHeaders();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.baseAzureUrl + this.checkoutPageUrl,
      checkoutModel,
      {
        headers: header,
      }
    );
  }

  resetPasswordAccount(model) {
    console.log('Change password Service');
    let loginModel = {
      Password: model.Password,
      ConfirmPassword: model.ConfirmPassword,
      ActivationCode: model.ActivationCode,
      UserEmail: model.UserEmail,
      ShopName: 'Amart',
    };
    const header = new HttpHeaders();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.baseAzureUrl + this.postResetPasswordUrl,
      loginModel,
      {
        headers: header,
      }
    );
  }

  activateAccount(loginModel) {
    console.log(loginModel);
    console.log('In activate account service');
    const header = new HttpHeaders();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.baseAzureUrl + this.postActivateAccountUrl,
      loginModel,
      {
        headers: header,
      }
    );
  }

  cerateTip(tipModel) {
    console.log('In activate account service');
    const header = new HttpHeaders();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    return this.http.post<any>(this.baseAzureUrl + this.postTipUrl, tipModel, {
      headers: header,
    });
  }
  getUserByUserEmail(userEmail) {
    let header = new HttpHeaders();
    header = header.set('userEmail', userEmail);
    return this.http.get<any>(this.baseAzureUrl + this.getUserByUserEmailUrl, {
      headers: header,
    });
  }
  isUserEmailTaken(emailAddress): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('userEmail', emailAddress);
    return this.http.get<any>(this.baseAzureUrl + this.userEmailExists, {
      headers: header,
    });
  }

  likeTip(rowKey, emailAddress, likeCount): Observable<any> {
    console.log(emailAddress);

    let header = new HttpHeaders();
    header = header.set('userEmail', emailAddress);
    header = header.set('tipId', rowKey);
    header = header.set('likeCount', likeCount + '');
    return this.http.get<any>(this.baseAzureUrl + this.getlikeTipUrl, {
      headers: header,
    });
  }

  getUnseenUserNotificationCount(userEmail) {
    console.log('getUnseenUserNotificationCount');
    let header = new HttpHeaders();
    header = header.set('userEmail', userEmail);
    return this.http.get<any>(
      this.baseAzureUrl + this.getUnseenUserNotificationCountUrl,
      {
        headers: header,
      }
    );
  }
  saveTip(rowKey, emailAddress): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('userEmail', emailAddress);
    header = header.set('tipId', rowKey);
    return this.http.post<any>(this.baseAzureUrl + this.saveTipUrl, null, {
      headers: header,
    });
  }

  isUserNameTaken(userName): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('userName', userName);
    return this.http.get<any>(this.baseAzureUrl + this.userNameTaken, {
      headers: header,
    });
  }

  getTipViewCount(rowKey, emailAddress): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('userEmail', emailAddress);
    header = header.set('tipId', rowKey);
    return this.http.get<any>(this.baseAzureUrl + this.getviewTipUrl, {
      headers: header,
    });
  }

  getPasswordChange(emailAddress): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('userEmail', emailAddress);
    return this.http.get<any>(this.baseAzureUrl + this.getPasswordChangeUrl, {
      headers: header,
    });
  }

  getCategories(pageIndex): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    return this.http.get<any>(this.baseAzureUrl + this.getCategoriesUrl, {
      headers: header,
    });
  }

  getUsers(userEmail, pageIndex): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    header = header.set('userEmail', userEmail + '');
    return this.http.get<any>(this.baseAzureUrl + this.getusersUrl, {
      headers: header,
    });
  }

  getTips(pageIndex, userEmail): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    header = header.set('userEmail', userEmail + '');
    return this.http.get<any>(this.baseAzureUrl + this.getTipsUrl, {
      headers: header,
    });
  }

  getVideoTips(pageIndex, userEmail): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    header = header.set('userEmail', userEmail + '');
    return this.http.get<any>(this.baseAzureUrl + this.getVideoTipsUrl, {
      headers: header,
    });
  }

  getoneminVideoTips(pageIndex): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    return this.http.get<any>(this.baseAzureUrl + this.getOneMinVideosUrl, {
      headers: header,
    });
  }

  getMostViewedTips(pageIndex, userEmail): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    header = header.set('userEmail', userEmail + '');
    return this.http.get<any>(this.baseAzureUrl + this.getMostViewedTipsUrl, {
      headers: header,
    });
  }

  getMostLikedTips(pageIndex, userEmail): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    header = header.set('userEmail', userEmail + '');
    return this.http.get<any>(this.baseAzureUrl + this.getMostLikedTipsUrl, {
      headers: header,
    });
  }

  getMostSharedTips(pageIndex, userEmail): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    header = header.set('userEmail', userEmail + '');
    return this.http.get<any>(this.baseAzureUrl + this.getMostSharedTipsUrl, {
      headers: header,
    });
  }

  getUserTips(pageIndex, userEmail): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    header = header.set('userEmail', userEmail + '');
    return this.http.get<any>(this.baseAzureUrl + this.getUserTipsUrl, {
      headers: header,
    });
  }

  getOtherUserTips(pageIndex, otherUserEmail, userEmail): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    header = header.set('otherUserEmail', otherUserEmail + '');
    header = header.set('userEmail', userEmail + '');
    return this.http.get<any>(this.baseAzureUrl + this.getOtherUserTipsUrl, {
      headers: header,
    });
  }

  getFilteredTips(pageIndex, filterUsersAndCategoriesModel) {
    console.log('filterUsersAndCategoriesStore');
    console.log(filterUsersAndCategoriesModel);
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    header = header.set(
      'selectedUsers',
      filterUsersAndCategoriesModel.selectedUsers + ''
    );
    header = header.set(
      'selectedCategories',
      filterUsersAndCategoriesModel.selectedCategories + ''
    );
    return this.http.get<any>(this.baseAzureUrl + this.getFilteredTipsUrl, {
      headers: header,
    });
  }

  getCategoryTips(pageIndex, categoryId, userEmail): Observable<any> {
    console.log(pageIndex);
    console.log(categoryId);

    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    header = header.set('categoryId', categoryId + '');
    header = header.set('userEmail', userEmail + '');
    return this.http.get<any>(this.baseAzureUrl + this.getCategoryTipsUrl, {
      headers: header,
    });
  }

  getTotalTipsCount(): Observable<any> {
    let header = new HttpHeaders();
    return this.http.get<any>(this.baseAzureUrl + this.getTotalTipsCountUrl, {
      headers: header,
    });
  }

  sendActivationCodeEmail(emailAddress): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('userEmail', emailAddress);
    return this.http.get<any>(
      this.baseAzureUrl + this.sendActivationCOdeEmailURL,
      {
        headers: header,
      }
    );
  }

  postTipComment(commentModel) {
    const header = new HttpHeaders();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    return this.http.post<any>(
      this.baseAzureUrl + this.postTipCommentUrl,
      commentModel,
      {
        headers: header,
      }
    );
  }

  deleteTipComment(commentModel, userEmail) {
    let header = new HttpHeaders();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    header.append('userEmail', userEmail);

    const headers = { Authorization: 'Bearer my-token', userEmail: userEmail };

    return this.http.post<any>(
      this.baseAzureUrl + this.postTipDeleteCommentUrl,
      commentModel,
      {
        headers,
      }
    );
  }

  getTipComments(pageIndex, tipId): Observable<any> {
    let header = new HttpHeaders();
    header = header.set('pageIndex', pageIndex + '');
    header = header.set('tipId', tipId + '');
    return this.http.get<any>(this.baseAzureUrl + this.getTipCommentUrl, {
      headers: header,
    });
  }

  getMyPlan(userName) {
    let header = new HttpHeaders();
    // header = header.set('pageIndex', pageIndex + '');
    header = header.set('userName', userName + '');
    return this.http.get<any>(this.baseAzureUrl + this.getMyPlanURL, {
      headers: header,
    });
  }

  getPricingPlans() {
    let header = new HttpHeaders();
    return this.http.get<any>(this.baseAzureUrl + this.getPricingPlansUrl, {
      headers: header,
    });
  }
}
