<div class="common-banner" (contextmenu)="onRightClick($event)">
  <img src="../../../assets/img/aboutus-banner.png" />
  <!-- <div class="common-text">
    <h2>Membership Plans that helps you to grow parallelly</h2>
  </div> -->
</div>
<section class="common-overlap" (contextmenu)="onRightClick($event)">
  <div class="common-text">
    <h2>One Minute Video</h2>
  </div>
</section>
<section>
<div (contextmenu)="onRightClick($event)" *ngIf="tipDetails" class="container video-parent-scroll">
  <div class="video-wrap">
    <div class="video-div">
      <div class="video-controls">
        <div class="play-ico">
          <i *ngIf="isplaying" (click)="playVid()" class="fa-solid fa-play"></i>
          <i
            *ngIf="!isplaying"
            (click)="pauseVideo()"
            class="fa-solid fa-pause"
          ></i>
        </div>
        <div (click)="muteUnmuteVideo()" class="sound-ico">
          <i *ngIf="!mute" class="fa-solid fa-volume-high"></i>
          <i *ngIf="mute" class="fa-solid fa-volume-mute"></i>
        </div>
      </div>
      <div (click)="videoDivClick()" class="video-play">
        <video
          id="myVideo"
          (ended)="vidEnded($event)"
          (play)="vidplay($event)"
          controlsList="nodownload"
          autoplay="true"
          poster="{{ tipDetails.VideoPosterUrl }}"
          [src]="tipDetails.Url"
          style="min-width: auto; min-height: 100%; object-fit: cover"
        >
          <source
            style="
              cursor: pointer;
              height: 150px !important;
              max-width: 100% !important;
              width: 100% !important;
            "
            *ngIf="tipDetails.Url"
            type="video/mp4"
          />
        </video>

        <div *ngIf="showplaybutton" class="play-mobile">
          <i (click)="videoClick()" class="fa-solid fa-play"></i>
        </div>
      </div>
      <div class="video-text">
        <div class="top-text">
          {{ tipDetails.Title }}
        </div>
        <div class="bottom-text">
          <!-- <div>
            <img
              (click)="goToUserProfile()"
              src="{{ tipDetails.ProfilePic }}"
              style="
                height: 30px;
                width: 30px;
                border-radius: 50%;
                margin-right: 10px;
                cursor: pointer;
                pointer-events: all;
              "
            />
          </div> -->
          <!-- <div class="u-name">
            <h4>{{ tipDetails.UserName }}</h4>
          </div> -->
        </div>
      </div>
    </div>
    <!-- <div class="comments">
      <div class="threedot">
        <i class="fa-solid fa-ellipsis-vertical"></i>
      </div>
      <div class="icon-section">
        <ul>
          <li>
            <a href="#"
              ><span>{{ tipDetails.ViewCount }}</span
              ><i class="fa-solid fa-eye"></i
            ></a>
          </li>
          <li>
            <a href="#">
              <span>{{ tipDetails.LikeCount }}</span>
              <i (click)="likeTip(tip)" class="fa-solid fa-thumbs-up"></i>
            </a>
          </li>
          <li>
            <a href="#"
              ><i (click)="saveTip(tip)" class="fa-solid fa-bookmark"></i
            ></a>
          </li>

          <li>
            <a href="#"><i class="fa-solid fa-share"></i> <span>Share</span></a>
          </li>
        </ul>
      </div>
    </div> -->
  </div>
  <div class="arrow-wrap">
    <div (click)="slideUp()" class="top-arrow">
      <i class="fa fa-angle-up" area-hidden="true"></i>
    </div>
    <div (click)="slideDown()" class="bottom-arrow">
      <i class="fa fa-angle-down" area-hidden="true"></i>
    </div>
  </div>
</div>
</section>
